import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface ModalProps {
  header: string;
  body: string;
  okButton: string;
  cancelButton: string;
  redirectTo: string;
  absRedirect: boolean;
  forceSignOut: boolean;
}

interface NavigationDisplayOptions {
  backwardNavigationDisplay: boolean;
  forwardNavigationDisplay: boolean;
}

interface NavigationModalContextValues {
  modalProps: ModalProps;
  setModalProps: React.Dispatch<React.SetStateAction<ModalProps>>;
  showModal: boolean;
  navigationDisplayOptions: NavigationDisplayOptions;
  setInitialNavigationDisplayOptions: React.Dispatch<
    React.SetStateAction<NavigationDisplayOptions>
  >;
  setNavigationDisplayOptions: React.Dispatch<
    React.SetStateAction<NavigationDisplayOptions>
  >;
  handleCloseModal: () => void;
}

const NavigationModalContext = createContext<NavigationModalContextValues>(
  {} as NavigationModalContextValues,
);
NavigationModalContext.displayName = 'NavigationModalContext';

export const NavigationModalProvider = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const [modalProps, setModalProps] = useState<ModalProps>({} as ModalProps);
  const [showModal, setShowModal] = useState(false);

  const [initialNavigationDisplayOptions, setInitialNavigationDisplayOptions] =
    useState<NavigationDisplayOptions>({
      backwardNavigationDisplay: false,
      forwardNavigationDisplay: false,
    });

  const [navigationDisplayOptions, setNavigationDisplayOptions] =
    useState<NavigationDisplayOptions>(initialNavigationDisplayOptions);

  useEffect(() => {
    setNavigationDisplayOptions(initialNavigationDisplayOptions);
  }, [
    initialNavigationDisplayOptions,
    initialNavigationDisplayOptions.backwardNavigationDisplay,
    initialNavigationDisplayOptions.forwardNavigationDisplay,
  ]);

  useEffect(() => {
    history.block((location, action) => {
      if (
        action === 'POP' &&
        navigationDisplayOptions.backwardNavigationDisplay
      ) {
        handleShowModal();
        return false;
      } else if (
        action === 'PUSH' &&
        navigationDisplayOptions.forwardNavigationDisplay
      ) {
        handleShowModal();
        return false;
      }
    });
  }, [
    history,
    location,
    history.action,
    navigationDisplayOptions.backwardNavigationDisplay,
    navigationDisplayOptions.forwardNavigationDisplay,
  ]);

  const resetNavigationDisplay = useCallback(() => {
    setNavigationDisplayOptions(initialNavigationDisplayOptions);
  }, [initialNavigationDisplayOptions]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    resetNavigationDisplay();
  }, [resetNavigationDisplay]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const value = useMemo(
    () => ({
      modalProps,
      setModalProps,
      showModal,
      handleCloseModal,
      handleShowModal,
      navigationDisplayOptions,
      setNavigationDisplayOptions,
      setInitialNavigationDisplayOptions,
    }),
    [handleCloseModal, modalProps, navigationDisplayOptions, showModal],
  );

  return <NavigationModalContext.Provider value={value} {...props} />;
};

export const useNavigationModalContext = () => {
  const context = useContext(NavigationModalContext);
  if (context === undefined) {
    throw new Error(
      `useNavigationModal must be used within a NavigationModalProvider`,
    );
  }
  return context;
};
