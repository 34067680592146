import { Fragment, useState } from 'react';
import MemberDetailsSlider from './MemberDetailsSlider';
import { convertToAuFllDateFormat } from 'utils/format';
import { useParticipationContext } from 'contexts/participantContext';
import { isRollOutAllowed } from 'utils/accountSetupUtils';
import { ReactComponent as BsLink } from 'bootstrap-icons/icons/link.svg';
import Tippy from '@tippyjs/react';
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle.svg';
import { RollInRolloverTransactionService } from 'services';
import { useHistory, useLocation } from 'react-router-dom';
import { SendRASButton } from './SendRASButton';
import { ReactComponent as ChevronUp } from 'bootstrap-icons/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'bootstrap-icons/icons/chevron-down.svg';
import { RegisterMember } from './RegisterMember';
import { getActionMessage } from '../../utils/labels';
import { isGuideAvailable } from '../../utils/actionUtil';
import { InfoDialog } from 'components/InfoDialog/InfoDialog';

export const MemberDetailsTable = ({
  data,
  messageTransactionId,
  guid,
  setIsLoaded,
  setData,
  setError,
  gotToTransaction,
  fund,
  isActive,
}) => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [isMemberDetailsSliderOpen, setIsMemberDetailsSliderOpen] =
    useState(false);
  const [sendingStatus, setSendingStatus] = useState('');
  const [actionedMemberContextId, setActionedMemberContextId] = useState('');
  const { participant } = useParticipationContext();
  const { state } = useLocation();
  const history = useHistory();
  const [showError, setShowError] = useState(true);
  const [showGuide, setShowGuide] = useState(false);

  const getRolloverAmountForMember = ({ member }) => {
    let rolloverAmount;
    if (!!member?.superannuationRolloverDetails?.sumOfBenefits) {
      rolloverAmount = member?.superannuationRolloverDetails?.sumOfBenefits;
    } else {
      rolloverAmount = member?.transferWholeBalanceIndicator
        ? 'Whole balance'
        : member?.requestedAmount;
    }
    return rolloverAmount;
  };

  const sendSuccessResponse = async (contextId) => {
    setSendingStatus('SENDING');
    setActionedMemberContextId(contextId);
    try {
      await RollInRolloverTransactionService.sendRolloverTransactionSuccessOutcomeResponse(
        {
          memberContextId: contextId,
          messageTransactionId: messageTransactionId,
        },
      );
      setSendingStatus('SENT');
      setIsLoaded(false);
      setSendingStatus('');
    } catch (err) {
      setSendingStatus('FAILED');
      if (err.response) {
        setData(null);
        setError({
          status: err.response.status.toString(),
          message: err.response.data.message || err.response.data,
        });
      } else if (err.request) {
        alert(err.request);
      } else {
        alert('Error', err.message);
      }
    }
  };
  const sendRASResponse = async (member) => {
    history.push({
      pathname: `/smsfs/${guid}/ras-out/${messageTransactionId}`,
      state: {
        ...state,
        member: member,
      },
    });
  };

  const sendRTRResponse = async (member) => {
    history.push({
      pathname: `/smsfs/${guid}/roll-out/${messageTransactionId}`,
      state: { ...state, member: member, receivingFund: data?.receivingFund },
    });
  };

  const registerMember = async (member) => {
    history.push({
      pathname: `/smsfs/${guid}/members/new`,
      state: {
        ...state,
        member: member,
        fund: fund,
        action: data?.requestAction,
      },
    });
  };

  return (
    <>
      <table className="table table--member">
        <thead>
          <tr>
            <th>Name</th>
            <th>DOB</th>
            <th>TFN</th>
            <th className="is-alignRight">Rollover amount</th>
            <th className="col-lg">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data?.memberDetailsList.map((member, index) => {
            return (
              <Fragment key={index}>
                <tr className="table-header">
                  <td>
                    <button
                      data-cy={index}
                      type="button"
                      className="button--link link"
                      onClick={() => {
                        setSelectedMember({
                          ...member,
                          transferringFund: data?.transferringFund,
                          receivingFund: data?.receivingFund,
                        });
                        setIsMemberDetailsSliderOpen(true);
                      }}
                    >
                      {member?.givenName} {member?.familyName}
                    </button>
                  </td>
                  <td>{convertToAuFllDateFormat(member?.dateOfBirth)}</td>
                  <td>{member?.taxFileNumber}</td>
                  <td className="is-alignRight">
                    {getRolloverAmountForMember({ member })}
                  </td>
                  <td className="bs-center text-center">
                    {member?.status == null && (
                      <>
                        {data?.rolloverType === 'ROLL_IN' &&
                          data?.requestAction ===
                            'ROLLOVER_TRANSACTION_REQUEST' && (
                            <>
                              {sendingStatus === 'SENDING' &&
                                actionedMemberContextId ===
                                  member?.memberContextId && (
                                  <span>Sending</span>
                                )}
                              {sendingStatus === 'SENT' &&
                                actionedMemberContextId ===
                                  member?.memberContextId && (
                                  <div className="member-status member-status--complete">
                                    Payment received
                                  </div>
                                )}
                              {sendingStatus === 'FAILED' &&
                                actionedMemberContextId ===
                                  member?.memberContextId && (
                                  <span>Failed</span>
                                )}
                              {sendingStatus === '' &&
                                member?.memberLevelResponseEnabled && (
                                  <button
                                    className="button button--primary button--xs"
                                    disabled={
                                      (sendingStatus === 'SENDING' &&
                                        actionedMemberContextId !==
                                          member?.memberContextId) ||
                                      !isActive()
                                    }
                                    onClick={() => {
                                      sendSuccessResponse(
                                        member?.memberContextId,
                                      );
                                    }}
                                  >
                                    Confirm payment received
                                  </button>
                                )}
                            </>
                          )}
                        {((data?.rolloverType === 'ROLL_OUT' &&
                          data?.requestAction ===
                            'ROLLOVER_TRANSACTION_REQUEST') ||
                          (data?.rolloverType === 'RAS_OUT' &&
                            data?.requestAction ===
                              'RELEASE_AUTHORITY_STATEMENT' &&
                            !(
                              data?.status === 'INITIATION_ERROR' ||
                              data?.status === 'REQUEST_ERROR'
                            ))) && (
                          <div className="member-status member-status--pending d-inline-flex align-items-center">
                            Waiting for response
                            <Tippy
                              arrow={true}
                              placement="top"
                              animation="shift-away"
                              theme="light-border"
                              content={
                                <div className="tooltip-popup">
                                  <p>
                                    The receiving fund is currently waiting for
                                    you to transfer the amount to their bank
                                    account (click "
                                    <strong>View payment details</strong>" on
                                    the right). Once the receiving fund has
                                    confirmed that the payment is in their
                                    account, they will respond "
                                    <strong>Payment received</strong>
                                    ".
                                  </p>
                                </div>
                              }
                            >
                              <span className="tooltip-icon">
                                <InfoCircle />
                              </span>
                            </Tippy>
                          </div>
                        )}
                        {data?.rolloverType === 'ROLL_OUT' &&
                          (data?.requestAction ===
                            'INITIATE_ROLLOVER_REQUEST' ||
                            data?.requestAction ===
                              'ELECTRONIC_PORTABILITY_FORM') && (
                            <>
                              {member.childTransactionId ? (
                                <button
                                  className="button button--link button--icon button--xs "
                                  disabled={
                                    sendingStatus === 'SENDING' &&
                                    actionedMemberContextId !==
                                      member?.memberContextId
                                  }
                                  onClick={() => {
                                    gotToTransaction(member.childTransactionId);
                                  }}
                                >
                                  <BsLink />
                                  &nbsp;View roll out transaction
                                </button>
                              ) : member.registeredMember &&
                                member?.memberLevelResponseEnabled ? (
                                <button
                                  className="button button--primary button--xs"
                                  disabled={
                                    !isRollOutAllowed(participant, guid) ||
                                    (sendingStatus === 'SENDING' &&
                                      actionedMemberContextId !==
                                        member?.memberContextId) ||
                                    !isActive()
                                  }
                                  onClick={() => {
                                    sendRTRResponse(member);
                                  }}
                                >
                                  Send Roll Out Request
                                </button>
                              ) : (
                                member?.memberLevelResponseEnabled && (
                                  <RegisterMember
                                    member={member}
                                    sendingStatus={sendingStatus}
                                    actionedMemberContextId={
                                      actionedMemberContextId
                                    }
                                    registerMember={registerMember}
                                    isActive={isActive()}
                                  />
                                )
                              )}
                            </>
                          )}
                        {data?.rolloverType === 'RAS_OUT' &&
                          data?.requestAction === 'RELEASE_AUTHORITY' &&
                          !(
                            data?.status === 'INITIATION_ERROR' ||
                            data?.status === 'REQUEST_ERROR'
                          ) && (
                            <>
                              {member.registeredMember ? (
                                <SendRASButton
                                  guid={guid}
                                  sendingStatus={sendingStatus}
                                  actionedMemberContextId={
                                    actionedMemberContextId
                                  }
                                  member={member}
                                  isActive={isActive()}
                                  sendRASResponse={sendRASResponse}
                                />
                              ) : (
                                member?.memberLevelResponseEnabled && (
                                  <RegisterMember
                                    member={member}
                                    sendingStatus={sendingStatus}
                                    actionedMemberContextId={
                                      actionedMemberContextId
                                    }
                                    registerMember={registerMember}
                                    isActive={isActive()}
                                  />
                                )
                              )}
                            </>
                          )}
                      </>
                    )}
                    {member?.status === 'SUCCESS' && (
                      <div className="member-status member-status--complete">
                        Payment received
                      </div>
                    )}
                    {(member?.status === 'INITIATION_ERROR' ||
                      member?.status === 'REQUEST_ERROR') && (
                      <div
                        className="member-status member-status--error"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowError(!showError);
                        }}
                      >
                        Error
                        <span
                          style={{
                            paddingLeft: '0.5rem',
                          }}
                        >
                          {member?.validationResponse != null && (
                            <>
                              {showError ? (
                                <ChevronUp
                                  style={{
                                    paddingTop: '0.3rem',
                                    strokeWidth: '1px',
                                    stroke: 'black',
                                  }}
                                />
                              ) : (
                                <ChevronDown
                                  style={{
                                    paddingTop: '0.3rem',
                                    strokeWidth: '1px',
                                    stroke: 'black',
                                  }}
                                />
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                {member?.validationResponse != null && showError && (
                  <tr className="row-error">
                    <td colSpan="5">
                      {member?.validationResponse?.validations.map(
                        (validation, index) => {
                          return (
                            <div className="error--container" key={index}>
                              <div>
                                <span className="error__tag">Error</span>
                              </div>
                              <div>
                                <p className="error__title">
                                  {validation?.message}
                                </p>
                                <p className="error__content">
                                  {validation?.details}
                                </p>
                                {validation?.refundAmount && (
                                  <p className="error__refund">
                                    <strong>Refund amount:</strong>{' '}
                                    {validation?.refundAmount}
                                    <br />
                                    <strong>Refund PRN:</strong>{' '}
                                    {validation?.refundPaymentReferenceNumber}
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        },
                      )}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      {(data?.status === 'INITIATION_ERROR' ||
        data?.status === 'REQUEST_ERROR') &&
        data?.requestAction === 'RELEASE_AUTHORITY_STATEMENT' && (
          <div className="action--container">
            <div>
              <p>
                <strong>Action:</strong> {getActionMessage(data?.requestAction)}{' '}
                {isGuideAvailable(data?.requestAction) && (
                  <a
                    className="button--link"
                    href="#"
                    onClick={() => setShowGuide(true)}
                  >
                    More details
                  </a>
                )}
              </p>
            </div>
            <div>
              <SendRASButton
                guid={guid}
                sendingStatus={sendingStatus}
                actionedMemberContextId={actionedMemberContextId}
                member={data?.memberDetailsList[0]}
                sendRASResponse={sendRASResponse}
                isActive={isActive()}
                withActionLabel={true}
              />
            </div>
          </div>
        )}
      <MemberDetailsSlider
        isOpen={isMemberDetailsSliderOpen}
        smsfGuid={guid}
        messageTransactionId={messageTransactionId}
        data={data}
        conversationId={data?.conversationId}
        member={selectedMember}
        onRequestClose={() => {
          setIsMemberDetailsSliderOpen(false);
        }}
      />
      <InfoDialog
        isOpen={showGuide}
        handleCloseModal={() => setShowGuide(false)}
        header={'Send a new Release Authority Statement (RAS)'}
        body={
          'The Release Authority Statement (RAS) message has failed the ATO’s validations and has been rejected. You need to correct the data and send a new RAS message to the ATO. The ATO will not automatically refund the payment as part of the response message process, but a refund can be requested out of band by contacting the ATO on 13 10 20.</p> <ul class="list"><li>If the corrected message contains a different payment amount, you should make a new payment to the ATO that matches the reported amount in the corrected message using the new Payment Reference Number (PRN).</li><li>If the corrected message contains the same payment amount and a refund was not made, you do not need to make the payment again to the ATO. The message will be sent using the old PRN. </li></ul>'
        }
        okButton={'Close'}
      />
    </>
  );
};
