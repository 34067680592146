import { convertToAuFllDateFormat } from '../../utils/format';
import { Link } from 'react-router-dom';
import { getMemberType } from '../../utils/labels';
import { useFundContext } from 'contexts/fundContext';
const MemberDataRow = ({ row }) => {
  const fund = useFundContext();
  const { guid } = { ...fund };

  return (
    <tr>
      <td>
        <Link
          to={{
            pathname: `/smsfs/${guid}/members/${row.memberGuid}`,
            search: `?memberName=${row.name}`,
          }}
          className="link"
        >
          {row.name}
        </Link>
      </td>
      <td>{row.dateOfBirth}</td>
      <td>{row.taxFileNumber}</td>
      <td>{getMemberType(row.memberType, fund?.trusteeStructure)}</td>
      <td>{convertToAuFllDateFormat(row.updatedAt)}</td>
    </tr>
  );
};

export default MemberDataRow;
