export const isGuideAvailable = (action: string) => {
    return action === 'RELEASE_AUTHORITY_STATEMENT';
}

export const isPaymentDetailsAvailable = (action?: string, status?: string) => {
   return action === 'RELEASE_AUTHORITY_STATEMENT' && status === 'SUCCESS';
}

export const showSuccessIcon = (action: string, status: string) => {
     return action === 'RELEASE_AUTHORITY_STATEMENT_OUTCOME_RESPONSE' && status === 'SUCCESS';
}

export const showErrorIcon = (action: string, status: string) => {
    return (action === 'RELEASE_AUTHORITY_STATEMENT_OUTCOME_RESPONSE' && status === 'REQUEST_ERROR')
        || (action === 'RELEASE_AUTHORITY_ERROR_RESPONSE');
}