import { useState } from 'react';
import { ReactComponent as CreditCards } from '../../assets/images/credit-cards.svg';
import { SubscriptionService } from 'services';
import { useStripe } from '@stripe/react-stripe-js';
import ValidationError from '../../components/ValidationError/ValidationError';
import { mapError } from '../../utils/mapServiceCallErrors';
import { PAYMENT_METHOD } from 'constants/subscription';

interface CardPaymentProps {
  smsfGuid: string;
}

const CardPayment = ({ smsfGuid }: CardPaymentProps) => {
  const { CARD } = PAYMENT_METHOD;
  const stripe = useStripe();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<any>();

  const makePayment = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await SubscriptionService.createCheckoutSession(
        smsfGuid,
        CARD,
      );
      setIsSubmitting(false);
      if (data?.sessionId && stripe) {
        stripe.redirectToCheckout({ sessionId: data?.sessionId });
      }
    } catch (error: any) {
      setIsSubmitting(false);
      setSubmissionError(mapError(error));
    }
  };

  return (
    <div>
      <button
        type="button"
        className="button button--new-page credit-card"
        disabled={isSubmitting}
        onClick={() => makePayment()}
      >
        Card
        <CreditCards />
      </button>
      {submissionError && (
        <ValidationError
          validation={submissionError.validationResponse}
          exception={submissionError.exception}
        />
      )}
    </div>
  );
};

export default CardPayment;
