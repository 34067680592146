import Modal from 'react-modal';
import Spinner from './Spinner';

const SpinnerModal = ({ header, message, isOpen }) => {
  return (
    <Modal
      className="modal modal--center"
      overlayClassName="modal-overlay"
      isOpen={isOpen}
      contentLabel="Loading"
      appElement={document.getElementById('root')}
    >
      <Spinner />
      <h3 className="mt-5">{header ? header : 'Loading...'}</h3>
      <p className="my-4">
        {message ? message : 'Please wait whilst we load the page'}
      </p>
    </Modal>
  );
};

export default SpinnerModal;
