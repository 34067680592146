import BackButton from 'components/BackButton/BackButton';
import NextButton from 'components/NextButton/NextButton';
import { AccountService } from 'services';
import { useTrusteeAccount } from 'contexts/trusteeAccountContext';
import { useHistory } from 'react-router-dom';
import { isAccountSetupStepDone } from 'utils/isAccountSetupStepDone';
import {
  ACCOUNT_SETUP_PATHNAMES,
  SETUP_CONFIRMATION,
} from 'constants/accountSetupPathnames';
import { isPaymentDone } from '../../utils/paymentSetupUtils';
import { FormEvent } from 'react';

import LockIcon from 'pages/Payment/LockIcon';
import { ReponsePaymentMessages } from '../Payment/ReponsePaymentMessages';

const PaymentSetupStep = () => {
  const history = useHistory();
  const { trusteeAccount, setTrusteeAccount } = useTrusteeAccount();
  const { smsfDetails, participantDetails } = trusteeAccount;
  const smsfStatus = smsfDetails?.smsfStatus;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      isAccountSetupStepDone(
        participantDetails?.accountSetupStep,
        SETUP_CONFIRMATION,
      )
    ) {
      const { data } = await AccountService.updateAccountSetupStep(
        SETUP_CONFIRMATION,
      );
      setTrusteeAccount(data);
    }
    history.push(ACCOUNT_SETUP_PATHNAMES.SETUP_CONFIRMATION);
  };

  return (
    <>
      <div className="account-setup__container">
        <h1>Payment setup</h1>
        <form onSubmit={handleSubmit}>
          <p className="longform">
            Get access to the Wrkr SMSF Hub for{' '}
            <strong>$39.95+GST per year</strong>
          </p>

          <div className="account-setup__section">
            <LockIcon />
            <ReponsePaymentMessages />
          </div>

          <div className="button-group">
            <BackButton
              onClick={() =>
                history.push(ACCOUNT_SETUP_PATHNAMES.MEMBER_DETAILS)
              }
            >
              Back
            </BackButton>
            <NextButton type="submit" disabled={!isPaymentDone(smsfStatus)}>
              Finish
            </NextButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default PaymentSetupStep;
