function formatBSB(str) {
  return insertSpace(str, 3);
}

function formatAccountNumber(str) {
  return insertSpace(str, 4);
}

function insertSpace(str, index) {
  return `${str.slice(0, index)} ${str.slice(index)}`;
}

function convertHyphenJoinedToCamelCase(str) {
  return str
    .split('-')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
}

function getParameterByName(fragment, name) {
  let match = RegExp('[#&]' + name + '=([^&]*)').exec(fragment);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export {
  formatBSB,
  formatAccountNumber,
  convertHyphenJoinedToCamelCase,
  getParameterByName,
};
