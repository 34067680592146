export const mapError = (error) => {
  if (error.response) {
    console.log(error.response);
    if (error.response.data?.validationResponse?.hasErrors) {
      return error.response.data;
    } else {
      return {
        exception: {
          status: error.response.data?.status,
          errorCode: error.response.data?.errorCode,
          message: error.response.data?.message,
        },
      };
    }
  }
};

const errorCode = new Map([
  ['CodeMismatchException', 'Code is invalid. Try again'],
  ['EnableSoftwareTokenMFAException', 'Code is invalid. Try again'],
  [
    'UsernameExistsException',
    'An account already exists with this email address. <a href="/login">Sign in</a> to continue.',
  ],
  [
    'InvalidPasswordException',
    'Password must have a mix of letters, numbers and symbols',
  ],
]);

// provide custom error message for AWS cognito & others
export const mapCustomError = (error) => {
  return errorCode.get(error?.code)
    ? errorCode.get(error?.code)
    : error.message;
};
