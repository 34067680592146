import { ErrorMessage, useField } from 'formik';

export const TextField = ({
  label,
  icon,
  className = '',
  isInvalid = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div className="textfield">
      <label htmlFor={field.name}>{label}</label>
      <input
        id={field.name}
        className={`form-control shadow-none ${
          ((meta.touched && meta.error) || isInvalid) && 'is-invalid'
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
      {icon && <div className={`eye ${className}`}>{icon}</div>}
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};
