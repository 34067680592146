// custom validation of phone number when begin with 0 need 10 digit input

import { PASSWORD_VALIDATION_REGEX_MESSAGE } from 'constants/validation';
import cloneDeep from 'lodash/cloneDeep';
import {
  isInFuture,
  isDateFormatValid,
} from './date.utils';
import { validateFundRolloverCertificationLevel } from './validateSuperFund';

// do not use arrow function as 'this' will not bind correctly
export const REQUIRED_ERROR_MESSAGE = 'This field is required';
export const INVALID_PHONE_NUMBER_ERROR_MESSAGE =
  'Australian mobile number must be a valid mobile number, for example 0412345678';
export const PHONE_REGEX = /^0?[1-9][0-9]{8}$/;

export const isValidPhoneNumber = function (message: string) {
  //@ts-ignore
  return this.test('test-name', message, function (value) {
    //@ts-ignore
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: message ?? REQUIRED_ERROR_MESSAGE });
    }

    if (value.charAt(0) === '0' && value.length !== 10) {
      return createError({
        path,
        message: message ?? INVALID_PHONE_NUMBER_ERROR_MESSAGE,
      });
    }

    if (!value.match(PHONE_REGEX)) {
      return createError({
        path,
        message: message ?? INVALID_PHONE_NUMBER_ERROR_MESSAGE,
      });
    }
    return true;
  });
};

export const numberOnly = (event: any) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const getEnabledRules = () => {
  let enableRules: typeof PASSWORD_VALIDATION_REGEX_MESSAGE;
  enableRules = cloneDeep(PASSWORD_VALIDATION_REGEX_MESSAGE);

  let keys = Object.keys(enableRules);
  for (let i = 0; i < keys.length; i++) {
    if (
      enableRules[keys[i] as keyof typeof PASSWORD_VALIDATION_REGEX_MESSAGE]
        .enable === false
    ) {
      delete enableRules[
        keys[i] as keyof typeof PASSWORD_VALIDATION_REGEX_MESSAGE
      ];
    }
  }

  return enableRules;
};

const isNoSpaceRuleEnabled = (keys: string[]) => {
  return keys.includes('noSpace') ? true : false;
};

const updateNoSpaceValidation = (
  requirements: typeof PASSWORD_VALIDATION_REGEX_MESSAGE,
) => {
  return {
    ...requirements,
    ...{
      noSpace: {
        valid: !requirements.noSpace.valid,
        enable: requirements.noSpace.enable,
        message: requirements.noSpace.message,
        regex: requirements.noSpace.regex,
      },
    },
  };
};

export const getPasswordRequirementError = async (
  value: string,
  setRequirements: any,
) => {
  let requirements: typeof PASSWORD_VALIDATION_REGEX_MESSAGE;
  requirements = await cloneDeep(getEnabledRules());
  let keys = Object.keys(requirements);
  let validationStatus = true;

  for (const rule of keys) {
    requirements[
      rule as keyof typeof PASSWORD_VALIDATION_REGEX_MESSAGE
    ].regex.test(value)
      ? (requirements[
          rule as keyof typeof PASSWORD_VALIDATION_REGEX_MESSAGE
        ].valid = true)
      : (requirements[
          rule as keyof typeof PASSWORD_VALIDATION_REGEX_MESSAGE
        ].valid = false);

    if (
      !requirements[rule as keyof typeof PASSWORD_VALIDATION_REGEX_MESSAGE]
        .valid &&
      rule !== 'noSpace'
    ) {
      validationStatus = false;
    } else if (
      requirements[rule as keyof typeof PASSWORD_VALIDATION_REGEX_MESSAGE]
        .valid &&
      rule === 'noSpace'
    ) {
      validationStatus = false;
    }
  }
  isNoSpaceRuleEnabled(keys)
    ? setRequirements(updateNoSpaceValidation(requirements))
    : setRequirements(requirements);

  !value && setRequirements();
  return validationStatus;
};

export const isUSIExpired = function (message: string) {
  //@ts-ignore
  return this.test('test-Expired', message, function (value) {
    //@ts-ignore
    const { path, createError } = this;
    //@ts-ignore
    const { certificationDetails } = this.parent;
    if (
      !isInFuture(value) &&
      !validateFundRolloverCertificationLevel(certificationDetails)
    ) {
      return createError({
        path,
        message:
          message ??
          'This USI has expired and does not support SMSF rollovers using the SuperStream standard. Please select a different product USI.',
      });
    }
    if (!isInFuture(value)) {
      return createError({
        path,
        message:
          message ??
          'This USI has expired. Please select a different product USI.',
      });
    }
    return true;
  });
};

export const isUSISupportV3 = function (message: string) {
  //@ts-ignore
  return this.test('test-USI-v3', message, function (value) {
    //@ts-ignore
    const { path, createError } = this;
    //@ts-ignore
    const { effectiveEndDate } = this.parent;
    if (
      !isInFuture(effectiveEndDate) &&
      !validateFundRolloverCertificationLevel(value)
    ) {
      return createError({
        path,
        message: message ?? ' ',
      });
    }
    if (!validateFundRolloverCertificationLevel(value)) {
      return createError({
        path,
        message:
          message ??
          'This USI does not support SMSF rollovers using the SuperStream standard. Please select a different product USI.',
      });
    }
    return true;
  });
};

export const validateDateFilter = function (message: string) {
  //@ts-ignore
  return this.test('test-futureDate', message, function (value) {
    //@ts-ignore
    const { path, createError } = this;
    if (!value) {
      return createError({
        path,
        message: message ?? 'Date is required.',
      });
    }
    if (isDateFormatValid(value)) {
      return createError({
        path,
        message: message ?? 'The date is invalid.',
      });
    }
    if (isInFuture(value)) {
      return createError({
        path,
        message: message ?? 'The date selected must not be a future date.',
      });
    }
    return true;
  });
};
