import { Link } from 'react-router-dom';
import { convertToAuFllDateFormat } from '../../utils/format';
import Tippy from '@tippyjs/react';
import { ReactComponent as ArrowRollin } from '../../assets/images/icons/icon_arrow-rollin.svg';
import { ReactComponent as ArrowRollout } from '../../assets/images/icons/icon_arrow-rollout.svg';
import { getStatus } from '../../utils/labels';
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle.svg';
import {SMSF_STATUS} from "../../constants/smsf";

const RolloverCellLink = ({
  to,
  children,
  focusable = false,
  disabled = false,
}) => {
  return (
    <Link
      to={disabled ? '#' : to}
      tabIndex={focusable ? undefined : '-1'}
      className={disabled ? 'disabled-link' : ''}
    >
      {children}
    </Link>
  );
};

const getRolloverTypeLabel = ({ rolloverType }) => {
  let rolloverTypeLabel = '';
  switch (rolloverType) {
    case 'ROLL_OUT':
    case 'RAS_OUT':
    case 'EPF':
      rolloverTypeLabel = 'Roll out';
      break;
    case 'ROLL_IN':
      rolloverTypeLabel = 'Roll in';
      break;
    default:
      break;
  }
  return rolloverTypeLabel;
};

const getRolloverDirectionArrow = ({ rolloverType }) => {
  const rolloverTypeLabel = getRolloverTypeLabel({ rolloverType });
  return (
    <Tippy
      arrow={true}
      placement="top"
      animation="shift-away"
      theme="light-border"
      content={
        <div className="tooltip-popup">
          <p>{rolloverTypeLabel}</p>
        </div>
      }
    >
      {rolloverTypeLabel === 'Roll in' ? <ArrowRollin /> : <ArrowRollout />}
    </Tippy>
  );
};

const isDetailViewSupported = (row, smsfStatus) => {
  return smsfStatus !== SMSF_STATUS.cancelled;
};

const RolloverMessageDataRow = ({ row, smsfStatus }) => {
  return (
    <tr key={row.messageTransactionId}>
      <td>
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          <span className="button button--link word-break">
            {row.conversationId}
          </span>
        </RolloverCellLink>
      </td>
      <td>
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          {row.smsfName}
          <span className="subtxt">ABN: {row.smsfAbn}</span>
        </RolloverCellLink>
      </td>
      <td className="col-arrow">
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          {getRolloverDirectionArrow({ rolloverType: row.rolloverType })}
        </RolloverCellLink>
      </td>
      <td>
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          {row.otherFundName}
          <span className="subtxt">
            {row.otherFundUsi
              ? `USI: ${row.otherFundUsi}`
              : `ABN: ${row.otherFundAbn}`}
          </span>
        </RolloverCellLink>
      </td>

      <td>
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          focusable
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          {convertToAuFllDateFormat(row.createdAt)}
        </RolloverCellLink>
      </td>

      <td>
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          {convertToAuFllDateFormat(row.updatedAt)}
        </RolloverCellLink>
      </td>

      <td>
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          {getStatus({
            transactionStatus: row.transactionStatus,
            rolloverType: row.rolloverType,
            action: row.action,
          })}
        </RolloverCellLink>
      </td>
      <td>
        <RolloverCellLink
          to={`/smsfs/${row.smsfGuid}/rollovers/${row.messageTransactionId}`}
          disabled={!isDetailViewSupported(row, smsfStatus)}
        >
          <span className="txt-action d-inline-flex align-items-center">
            {row.userActionRequired}
            {row.userActionRequired === 'Action required' && (
              <Tippy
                arrow={true}
                placement="top"
                animation="shift-away"
                theme="light-border"
                content={
                  <div className="tooltip-popup">
                    <p>Action required by SMSF</p>
                  </div>
                }
              >
                <span className="tooltip-icon">
                  <InfoCircle />
                </span>
              </Tippy>
            )}
          </span>
        </RolloverCellLink>
      </td>
    </tr>
  );
};

export default RolloverMessageDataRow;
