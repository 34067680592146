import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import nanoid from 'nanoid';
import { ReactComponent as PlusSquare } from 'bootstrap-icons/icons/plus-square.svg';
import { ReactComponent as DashSquare } from 'bootstrap-icons/icons/dash-square.svg';

interface AccordionProps {
  expanded: boolean;
  title: string;
  subTitle: string;
  content: ReactNode;
  identifier: string;
}

const Accordion = ({
  expanded = false,
  title,
  subTitle,
  content,
  identifier,
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);
  const id = nanoid(5);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div className="accordion" role="presentation">
      <div role="heading" aria-level={3}>
        <button
          data-cy={identifier}
          className="button button--icon-before accordion__trigger"
          aria-expanded={isExpanded}
          id={`trigger-${id}`}
          aria-controls={`panel-${id}`}
          onClick={(event) => {
            event.preventDefault();
            setIsExpanded(!isExpanded);
          }}
        >
          <span className="d-flex align-items-center">
            {isExpanded ? (
              <DashSquare className="button--icon-before__icon mr-4 flex-shrink-0" />
            ) : (
              <PlusSquare className="button--icon-before__icon mr-4 flex-shrink-0" />
            )}
            <span className="accordion__title">{title}</span>
          </span>
          {subTitle && (
            <span className="accordion__subtitle ml-4">{subTitle}</span>
          )}
        </button>
      </div>
      <div
        className={classNames('accordion__panel', {
          animateIn: isExpanded,
        })}
        id={`panel-${id}`}
        role="region"
        aria-labelledby={`trigger-${id}`}
        aria-hidden={!isExpanded}
      >
        <div className="accordion__inner">{content}</div>
      </div>
    </div>
  );
};

export default Accordion;
