import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Plus } from 'bootstrap-icons/icons/plus.svg';
import Tippy from '@tippyjs/react';
import { useEffect, useMemo, useState } from 'react';
import PaginationBar from 'components/PaginationBar/PaginationBar';
import ViewMore from 'components/ViewMore/ViewMore';
import RolloverMessageDataRow from './RolloverMessageDataRow';
import { DASHBOARD_PAGE_SIZE, DEFAULT_PAGE_SIZE } from 'utils/configs';
import {
  getSmsfStatus,
  isRolloverLinksAllowed,
  showAlertInSideSMSF,
  isRollOutAllowed,
} from 'utils/accountSetupUtils';
import AccountCompleteInfo from 'components/AccountCompleteInfo/AccountCompleteInfo';
import { showGlobalAlerts } from 'utils/globalActionsUtils';
import HubGlobalAlerts from 'components/Alert/HubGlobalAlerts';
import { RolloverSearchService } from 'services/RolloverSearchService/RolloverSearchService';
import { useParticipationContext } from 'contexts/participantContext';

import TableLoader from 'components/Loader/TableLoader';

const Rollovers = ({ isDashboard }) => {
  const { pathname } = useLocation();
  document.title = 'Rollovers';
  const { participant } = useParticipationContext();

  const [newRolloverDropdownExpanded, setNewRolloverDropdownExpanded] =
    useState(false);

  const { guid } = useParams();

  const InitialData = useMemo(() => {
    return {
      totalPages: 0,
      totalElements: 0,
      number: 0,
    };
  }, []);

  const InitialError = useMemo(() => {
    return {
      status: '',
      message: '',
    };
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(InitialData);
  const [error, setError] = useState(InitialError);
  const [paginationData, setPaginationData] = useState({
    totalItems: 0,
    pageNumber: 0,
    pageSize: isDashboard ? DASHBOARD_PAGE_SIZE : DEFAULT_PAGE_SIZE,
  });

  const setPaginationParams = (pageNumber, pageSize) => {
    setPaginationData({
      ...paginationData,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    setIsLoaded(false);
  };

  useEffect(() => {
    const getResults = async () => {
      setError(InitialError);
      setIsLoaded(false);
      try {
        const res = await RolloverSearchService.search({
          pageNumber: paginationData.pageNumber,
          pageSize: paginationData.pageSize,
          smsfGuid: guid,
        });
        setIsLoaded(true);
        const { validationResponse } = res?.data;
        if (validationResponse?.hasErrors) {
          setData(InitialData);
          setError({
            status: validationResponse.validations[0].errorCode,
            message: validationResponse.validations[0].message,
          });
        } else {
          setData(res?.data?.rolloverMessages);
          setPaginationData(res?.data?.paginationData);
          setError(InitialError);
        }
      } catch (err) {
        setIsLoaded(true);
        if (err.response) {
          setData(InitialData);
          if (err.response.data?.validationResponse?.hasErrors) {
            setData(InitialData);
            setError({
              status:
                err.response.data?.validationResponse.validations[0].errorCode,
              message:
                err.response.data?.validationResponse.validations[0].message,
            });
          } else {
            setError({
              status: err.response.status.toString(),
              message:
                err.response.data?.message ||
                err.response.data?.errorCode ||
                err.response.data,
            });
          }
        } else if (err.request) {
          alert(err.request);
        } else {
          alert('Error', err.message);
        }
      }
    };

    if (!isLoaded) {
      getResults();
    }
  }, [paginationData, isLoaded, InitialData, InitialError, guid]);

  const isSuccessfullyLoaded = () => isLoaded && error.status.length === 0;

  return (
    <main className="main">
      <div className="dashboard">
        <div className="Rollovers">
          {isSuccessfullyLoaded() && guid && (
            <div className="banner">
              <Breadcrumbs />
              {showGlobalAlerts(participant) && (
                <HubGlobalAlerts participant={participant} />
              )}
              {showAlertInSideSMSF(participant, guid) && (
                <AccountCompleteInfo
                  status={participant?.smsfStatuses[guid]}
                  smsfGuid={guid}
                />
              )}
              <div className="d-flex align-items-center justify-content-between">
                <h1>Rollovers</h1>

                <div>
                  <Tippy
                    theme="light-border"
                    placement="bottom"
                    animation="shift-away"
                    arrow={false}
                    trigger="click"
                    interactive={true}
                    offset={[0, 5]}
                    onMount={() => setNewRolloverDropdownExpanded(true)}
                    onHide={() => setNewRolloverDropdownExpanded(false)}
                    content={
                      <div style={{ width: '15rem' }}>
                        <ul className="py-2">
                          <li>
                            <Link
                              to={
                                isRolloverLinksAllowed(participant, guid)
                                  ? `${pathname}/roll-in`
                                  : `#`
                              }
                              className={
                                isRolloverLinksAllowed(participant, guid)
                                  ? 'd-block'
                                  : 'disabled-link d-block'
                              }
                            >
                              Roll In
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                isRollOutAllowed(participant, guid)
                                  ? `${pathname}/roll-out`
                                  : `#`
                              }
                              className={
                                isRollOutAllowed(participant, guid)
                                  ? 'd-block'
                                  : 'disabled-link d-block'
                              }
                            >
                              Roll Out
                            </Link>
                          </li>
                        </ul>
                      </div>
                    }
                  >
                    <button
                      className="button button--outline button--icon-before button--sm"
                      to={`${pathname}/new`}
                      aria-expanded={newRolloverDropdownExpanded}
                      disabled={!isRolloverLinksAllowed(participant, guid)}
                    >
                      <Plus className="button--icon-before__icon" /> New
                      Rollover Request
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
          )}

          {!isDashboard && !guid && (
            <div className="banner">
              <div className="d-flex align-items-center justify-content-between">
                <h1>All rollovers</h1>
              </div>
            </div>
          )}

          {!isLoaded ? (
            <TableLoader />
          ) : (
            <div className="content">
              {isLoaded && error.status.length > 0 && (
                <div className="error-section">
                  <h2>{error.status}</h2>
                  <p>{error.message}</p>
                </div>
              )}

              {data?.length === 0 && (
                <div className="error-section">
                  <h2>No messages found</h2>
                  <button
                    type="button"
                    className="button button--primary button--icon-before"
                    onClick={() => {
                      setPaginationParams(0, DEFAULT_PAGE_SIZE);
                    }}
                  >
                    Reload
                  </button>
                </div>
              )}
              {data?.length > 0 && (
                <div className="table-wrapper">
                  <table className="table table-rollovers">
                    <thead>
                      <tr>
                        <th>Conversation ID</th>
                        <th className="col-fund">Participating funds</th>
                        <th className="col-arrow">
                          <span className="is-hidden">In/Out</span>
                        </th>
                        <th className="col-fund">
                          <span className="is-hidden">
                            Other participating funds
                          </span>
                        </th>
                        {/* <th colSpan="3">Participating funds</th> */}

                        <th className="col-date">Date created</th>
                        <th className="col-date">Last updated</th>
                        <th className="col-status">Status</th>
                        <th className="col-action">Action</th>
                        {/* <th className="col-arrow">&nbsp;</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((row, i) => {
                        return (
                          <RolloverMessageDataRow
                            key={row.messageTransactionId}
                            row={row}
                            smsfStatus={getSmsfStatus(
                              participant,
                              guid || row.smsfGuid,
                            )}
                          />
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={8}>
                          {!isDashboard ? (
                            <PaginationBar
                              data={paginationData}
                              setPaginationParams={setPaginationParams}
                              resultType={'rollovers'}
                            />
                          ) : (
                            <ViewMore
                              title={'View all rollovers'}
                              pathname={'/smsfs/rollovers'}
                            />
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Rollovers;
