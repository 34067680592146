import classNames from 'classnames';

const Searchbar = ({
  placeholder = 'Search...',
  inversed = false,
  ...props
}) => {
  return (
    <div
      className={classNames({
        searchbar: true,
        'searchbar--inverse': inversed,
      })}
    >
      <input
        className="search-field"
        type="search"
        id="search"
        name="search"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default Searchbar;
