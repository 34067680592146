import SlidingPane from 'react-sliding-pane';
import { useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Accordion from 'components/Accordion/Accordion';
import { ReactComponent as X } from 'bootstrap-icons/icons/x.svg';
import { convertToAuNumericDateFormat, formatAddress } from 'utils/format';
import { getReleaseAuthorityProductTypeCodeDescription } from 'utils/labels';
import { ReportService } from 'services';

const MemberDetailsSlider = ({
  messageTransactionId,
  smsfGuid,
  isOpen,
  member,
  onRequestClose,
}) => {
  const [activeAccordion, setActiveAccordion] = useState(1);

  const [isDownloading, setIsDownloading] = useState(false);

  const hasRolloverAmountBreakdown = ({ member }) => {
    return !!member?.superannuationRolloverDetails;
  };

  const hasDeathBenefitsAmountBreakdown = ({ member }) => {
    return (
      !!member?.superannuationRolloverDetails
        ?.deathBenefitRecipientAgeCategoryCodeDescription ||
      !!member?.superannuationRolloverDetails?.deathBenefitTaxCodeDescription
    );
  };

  const hasInsuranceAmountBreakdown = ({ member }) => {
    return (
      !!member?.superannuationRolloverDetails?.insuredDeathCoverAmount ||
      !!member?.superannuationRolloverDetails
        ?.insuredTotalPermanentDisablementCoverAmount ||
      !!member?.superannuationRolloverDetails
        ?.insuredIncomeProtectionMonthlyCoverAmount
    );
  };

  const hasKiwiSaverAmountBreakdown = ({ member }) => {
    return (
      !!member?.superannuationRolloverDetails?.kiwiSaverPreservedAmount ||
      !!member?.superannuationRolloverDetails?.kiwiSaverTaxFreeAmount
    );
  };

  const hasSuperannuationRolloverOtherDetails = ({ member }) => {
    return member?.superannuationRolloverOtherDetails?.length > 0;
  };

  const handlePDFDownload = async () => {
    setIsDownloading(true);

    await ReportService.downloadRolloverBenefitsDetails({
      transactionId: messageTransactionId,
      smsfGuid,
      memberContextId: member?.memberContextId,
    });

    setIsDownloading(false);
  };

  return (
    <SlidingPane
      isOpen={isOpen}
      title={`${member?.familyName}${
        member?.givenName != null ? `, ${member?.givenName}` : null
      }`}
      onRequestClose={onRequestClose}
      closeIcon={
        <button
          type="button"
          className="button--link"
          data-cy="member-details-slider-close"
        >
          <X />
        </button>
      }
      className="slide-pane--custom slide-pane--member-details"
    >
      <button
        style={{ margin: '10px', width: '400px' }}
        type="button"
        disabled={isDownloading}
        className="button button--outline"
        onClick={handlePDFDownload}
      >
        {isDownloading ? (
          'Downloading...'
        ) : (
          <span>
            Download PDF &nbsp; <i className="bi bi-download"></i>
          </span>
        )}
      </button>

      <div className="member-details-accordion">
        <Accordion
          identifier="member-details"
          expanded={activeAccordion === 1}
          onClick={(expanded) => setActiveAccordion(expanded ? 1 : null)}
          title={'Member details'}
          content={
            <div>
              <dl>
                <div>
                  <dt>Given name</dt>
                  <dd>{member?.givenName}</dd>
                </div>
                <div>
                  <dt>Other given name</dt>
                  <dd>{member?.otherGivenName}</dd>
                </div>
                <div>
                  <dt>Family name</dt>
                  <dd>{member?.familyName}</dd>
                </div>
                <div>
                  <dt>DOB</dt>
                  <dd>{convertToAuNumericDateFormat(member?.dateOfBirth)}</dd>
                </div>
                <div>
                  <dt>TFN</dt>
                  <dd>{member?.taxFileNumber}</dd>
                </div>
                <div>
                  <dt>Member context id</dt>
                  <dd>{member?.id}</dd>
                </div>
                <div>
                  <dt>Address</dt>
                  <dd>{formatAddress(member?.address)}</dd>
                </div>
                {!!member?.eligibleServicePeriodStartDate && (
                  <div>
                    <dt>Eligible service period start date</dt>
                    <dd>
                      {convertToAuNumericDateFormat(
                        member?.eligibleServicePeriodStartDate,
                      )}
                    </dd>
                  </div>
                )}
                {!!member?.memberIdInTransferringFund && (
                  <div>
                    <dt>Member ID in {member?.transferringFund?.fundName}</dt>
                    <dd>{member?.memberIdInTransferringFund}</dd>
                  </div>
                )}
                {!!member?.memberIdInReceivingFund && (
                  <div>
                    <dt>Member ID in {member?.receivingFund?.fundName}</dt>
                    <dd>{member?.memberIdInReceivingFund}</dd>
                  </div>
                )}
              </dl>
            </div>
          }
        />
        <Accordion
          identifier="rollover-details"
          expanded={activeAccordion === 2}
          onClick={(expanded) => setActiveAccordion(expanded ? 2 : null)}
          title={'Rollover details'}
          content={
            !hasRolloverAmountBreakdown({ member }) ? (
              <dl>
                <div>
                  <dt>Rollover amount</dt>
                  <dd>
                    {member?.transferWholeBalanceIndicator
                      ? 'Whole balance'
                      : member?.requestedAmount}
                  </dd>
                </div>
                {!!member?.releaseAuthorityProductTypeCode && (
                  <div>
                    <dt>Release authority product type code</dt>
                    <dd>
                      {getReleaseAuthorityProductTypeCodeDescription(
                        member?.releaseAuthorityProductTypeCode,
                      )}
                      {' ('}
                      {member?.releaseAuthorityProductTypeCode}
                      {')'}
                    </dd>
                  </div>
                )}
              </dl>
            ) : (
              <>
                <div className="row member-detail__heading p-4">
                  <div className="col-5">Benefits</div>
                </div>
                <dl>
                  <div>
                    <dt>Transfer type</dt>
                    <dd>
                      {member?.transferWholeBalanceIndicator
                        ? 'Whole balance rollover'
                        : 'Partial rollover'}
                    </dd>
                  </div>
                  <div>
                    <dt>Rollover amount</dt>
                    <dd>
                      {member?.superannuationRolloverDetails?.sumOfBenefits}
                    </dd>
                  </div>
                  <div>
                    <dt>Benefits remaining</dt>
                    <dd>
                      {member?.superannuationRolloverDetails
                        ?.remainingBenefitIndicator === true
                        ? 'Yes'
                        : 'No'}
                    </dd>
                  </div>
                  <div>
                    <dt>Preserved</dt>
                    <dd>
                      {
                        member?.superannuationRolloverDetails
                          ?.benefitPreservedAmount
                      }
                    </dd>
                  </div>
                  <div>
                    <dt>Unrestricted</dt>
                    <dd>
                      {
                        member?.superannuationRolloverDetails
                          ?.benefitUnrestrictedAmount
                      }
                    </dd>
                  </div>
                  <div>
                    <dt>Restricted</dt>
                    <dd>
                      {
                        member?.superannuationRolloverDetails
                          ?.benefitRestrictedAmount
                      }
                    </dd>
                  </div>
                </dl>
                {hasDeathBenefitsAmountBreakdown({ member }) && (
                  <>
                    <div className="row member-detail__heading p-4">
                      <div className="col-5">Death benefits</div>
                    </div>
                    <dl>
                      <div>
                        <dt>Recipient age category code</dt>
                        <dd>
                          {
                            member?.superannuationRolloverDetails
                              ?.deathBenefitRecipientAgeCategoryCodeDescription
                          }
                        </dd>
                      </div>
                      <div>
                        <dt>Tax code</dt>
                        <dd>
                          {
                            member?.superannuationRolloverDetails
                              ?.deathBenefitTaxCodeDescription
                          }
                        </dd>
                      </div>
                    </dl>
                  </>
                )}
                <div className="row member-detail__heading p-4">
                  <div className="col-5">Tax</div>
                </div>
                <dl>
                  <div>
                    <dt>Tax free</dt>
                    <dd>{member?.incomeTaxDetails?.taxFreeAmount}</dd>
                  </div>
                  <div>
                    <dt>Taxed</dt>
                    <dd>{member?.incomeTaxDetails?.taxableTaxedAmount}</dd>
                  </div>
                  <div>
                    <dt>Untaxed</dt>
                    <dd>{member?.incomeTaxDetails?.taxableUntaxedAmount}</dd>
                  </div>
                </dl>
                {hasInsuranceAmountBreakdown({ member }) && (
                  <>
                    <div className="row member-detail__heading p-4">
                      <div className="col-5">Insurance</div>
                    </div>
                    <dl>
                      <div>
                        <dt>Death cover</dt>
                        <dd>
                          {
                            member?.superannuationRolloverDetails
                              ?.insuredDeathCoverAmount
                          }
                        </dd>
                      </div>
                      <div>
                        <dt>Total permanent disablement cover</dt>
                        <dd>
                          {
                            member?.superannuationRolloverDetails
                              ?.insuredTotalPermanentDisablementCoverAmount
                          }
                        </dd>
                      </div>
                      <div>
                        <dt>Income protection monthly cover</dt>
                        <dd>
                          {
                            member?.superannuationRolloverDetails
                              ?.insuredIncomeProtectionMonthlyCoverAmount
                          }
                        </dd>
                      </div>
                    </dl>
                  </>
                )}
                {hasKiwiSaverAmountBreakdown({ member }) && (
                  <>
                    <div className="row member-detail__heading p-4">
                      <div className="col-5">Kiwi Saver</div>
                    </div>
                    <dl>
                      <div>
                        <dt>Preserved</dt>
                        <dd>
                          {
                            member?.superannuationRolloverDetails
                              ?.kiwiSaverPreservedAmount
                          }
                        </dd>
                      </div>
                      <div>
                        <dt>Tax free</dt>
                        <dd>
                          {
                            member?.superannuationRolloverDetails
                              ?.kiwiSaverTaxFreeAmount
                          }
                        </dd>
                      </div>
                    </dl>
                  </>
                )}
                {hasSuperannuationRolloverOtherDetails({ member }) && (
                  <>
                    <div className="row member-detail__heading p-4">
                      <div className="col-5">Other details</div>
                    </div>
                    {
                      <dl>
                        {member?.superannuationRolloverOtherDetails?.map(
                          (otherDetail, index) => {
                            return (
                              <div key={index}>
                                <dt>{otherDetail.text}</dt>
                                <dd>{otherDetail.description}</dd>
                              </div>
                            );
                          },
                        )}
                      </dl>
                    }
                  </>
                )}
              </>
            )
          }
        />
      </div>
    </SlidingPane>
  );
};

export default MemberDetailsSlider;
