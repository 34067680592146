import 'bootstrap-icons/font/bootstrap-icons.css';

type DownloadButtonProps = {
  setShowModal: () => void;
  label: string;
  disabled?: boolean;
};

const DownloadButton = ({
  setShowModal,
  label,
  disabled = false,
}: DownloadButtonProps) => {
  return (
    <button className="button button--outline" onClick={setShowModal} disabled={disabled}>
      <span>
        {label} &nbsp; <i className="bi bi-download"></i>
      </span>
    </button>
  );
};

export default DownloadButton;
