import React from 'react';
import { ReactComponent as EyeFill } from '../../assets/images/icons/eye-fill.svg';
import { ReactComponent as EyeSlashFill } from '../../assets/images/icons/eye-slash-fill.svg';

interface EyeProps {
  show: boolean;
  onClick: (show: boolean) => void;
}

const Eye = ({ show, onClick }: EyeProps) => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClick(!show);
      }}
    >
      {show ? <EyeSlashFill /> : <EyeFill />}
    </div>
  );
};

export default Eye;
