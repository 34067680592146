import { ErrorMessage, Field } from 'formik';

const FormCheckBox = ({ id, name, label, ...props }) => {
  return (
    <div className="form-group">
      <div className="checkbox">
        <Field id={id} type="checkbox" name={name} {...props} />
        <label htmlFor={id}>{label}</label>
      </div>
      <ErrorMessage component="div" className="error" name={name} />
    </div>
  );
};

export default FormCheckBox;
