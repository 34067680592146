import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import { useHistory } from 'react-router-dom';
import signOut from 'utils/signOut';

interface DialogProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  header: string;
  body: string;
  okButton: string;
  cancelButton: string;
  absRedirect?: boolean;
  redirectTo: string;
  forceSignOut?: boolean;
}

export const Dialog = ({
  isOpen,
  header,
  body,
  okButton,
  cancelButton,
  handleCloseModal,
  redirectTo,
  absRedirect = false,
  forceSignOut = false,
}: DialogProps) => {
  const history = useHistory();

  return (
    <Modal
      overlayClassName="modal-overlay"
      isOpen={isOpen}
      contentLabel="Loading"
      className="modal"
      //@ts-ignore
      appElement={document.getElementById('root')}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal__header">
        <div className="modal__heading h3">{header}</div>
        <button
          type="button"
          className="d-inline-flex button--transparent button--close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <XLg />
        </button>
      </div>
      <div className="modal__body">
        <p dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <div className="modal__footer button-group">
        <button
          type="button"
          className="button button--primary"
          onClick={async () => {
            if (forceSignOut) {
              await signOut();
            }
            if (!redirectTo) {
              history.goBack();
            } else {
              absRedirect
                ? window.location.replace(redirectTo)
                : history.push(redirectTo);
            }
          }}
        >
          {okButton}
        </button>
        <button
          type="button"
          className="button button--outline"
          onClick={handleCloseModal}
        >
          {cancelButton}
        </button>
      </div>
    </Modal>
  );
};
