import { TrusteeAccountProvider } from 'contexts/trusteeAccountContext';
import { RenewalFailure } from './RenewalFailure';

const RenewalFailureContainer = () => {
  return (
    <TrusteeAccountProvider>
      <RenewalFailure />
    </TrusteeAccountProvider>
  );
};

export default RenewalFailureContainer;
