import { Auth } from 'aws-amplify';

const signOut = async () => {
  try {
    console.log('logout');
    await Auth.signOut();
    sessionStorage.clear();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export default signOut;
