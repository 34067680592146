import classNames from 'classnames';
import {
  ISO_DATE_FORMAT,
  AU_NUMERIC_DATE_FORMAT,
  AU_FULL_DATE_FORMAT,
  AU_FULL_TIME_FORMAT,
  INITIAL_ADDRESS_VALUE,
} from './configs';
import { format, parse } from 'date-fns';

export const getPayEventStatusPillClasses = (status) => {
  let classes = classNames({
    pill: true,
    'pill--new': /new$/gi.test(status),
    'pill--success': /complete$/gi.test(status),
    'pill--warning': /warning/gi.test(status),
    'pill--alert': /(invalid)|(error)/gi.test(status),
  });

  return classes;
};

export const getPayEventStatusTagClasses = (status) => {
  let classes = classNames({
    tag: true,
    'tag--new': /new$/gi.test(status),
    'tag--success': /complete$/gi.test(status),
    'tag--warning': /warning/gi.test(status),
    'tag--alert': /(invalid)|(error)/gi.test(status),
  });

  return classes;
};

export const extractByTagName = (xmlDoc, tag) => {
  return xmlDoc.getElementsByTagName(tag)[0].childNodes[0].nodeValue;
};

export const maskLastThreeCharacters = (str) => {
  return str == null ? str : str.slice(0, -3) + 'XXX';
};

export const convertStringToCurrency = (str) => {
  return str
    ? `${parseFloat(str, 10).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
      })}`
    : '-';
};

export const convertToIsoDateFormat = (date) => {
  return date == null || date === ''
    ? date
    : format(parse(date, AU_NUMERIC_DATE_FORMAT, new Date()), ISO_DATE_FORMAT);
};

export const convertToAuNumericDateFormat = (date) => {
  return date == null || date === '' || date === 0
    ? ''
    : format(new Date(date), AU_NUMERIC_DATE_FORMAT);
};

export const convertToAuFllDateFormat = (date) => {
  return date == null || date === ''
    ? date
    : format(new Date(date), AU_FULL_DATE_FORMAT);
};

export const convertToAuFllTimeFormat = (date) => {
  return date == null || date === ''
    ? date
    : format(new Date(date), AU_FULL_TIME_FORMAT);
};

export const convertArrayToSelectOptions = (options) => {
  return options.map((option) => ({
    value: option,
    label: option,
  }));
};

export const getFileSize = (size) => {
  return size / 1000000 < 1
    ? `${(size / 1000).toFixed(2)} KB`
    : `${(size / 1000000).toFixed(2)} MB`;
};

export const convertAllNullToEmptyStringsInObject = (obj) =>
  JSON.parse(JSON.stringify(obj).replace(/:null/gi, ':""'));

export const formatAddress = (address) => {
  return address != null && address !== INITIAL_ADDRESS_VALUE
    ? [
        address?.line1,
        address?.line2,
        address?.line3,
        address?.line4,
        address?.suburb,
        address?.state,
        address?.postCode,
        address?.countryCode?.toUpperCase(),
      ]
        .filter((part) => part != null && part !== '')
        .join(', ')
    : '';
};

export const isMobile = (telephone) => {
  return telephone?.serviceLineCode === '01';
};
