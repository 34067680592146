const Checkbox = ({ id, checked, setChecked, label }) => {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(event) => setChecked(event.target.checked)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
