import { Switch, Redirect } from 'react-router';
import Sidebar from '../../components/Sidebar/Sidebar';
import RouteWithSubRoutes from '../../RouteWithSubRoutes';
import { FundProvider } from 'contexts/fundContext';

// Containing component for each individual SMSF with its own context
const SMSF = ({ routes }) => {
  return (
    <FundProvider>
      <Sidebar />
      <main className="main">
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Redirect to="/smsfs/:guid/rollovers" />
        </Switch>
      </main>
    </FundProvider>
  );
};

export default SMSF;
