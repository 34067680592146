export const testAmountHasNoMoreThanTwoDecimalPlaces =
  ('amount-has-no-more-than-two-decimal-places',
  (value, { createError }) => {
    let message;
    if (value?.toString().indexOf('.') >= 0) {
      if (value.split('.')[1].length > 2) {
        message =
          'Invalid amount. Only two decimal places are allowed when entering cents.';
      }
    }
    return message
      ? createError({
          message,
        })
      : true;
  });
