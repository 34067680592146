import { ReactComponent as BsPlus } from 'bootstrap-icons/icons/plus.svg';
import { RolloverMemberDetailsList } from 'services/RolloverSearchService/types/RolloverSearchService.types';

interface RegisterMemberProps {
  member: RolloverMemberDetailsList;
  sendingStatus: string;
  actionedMemberContextId: string;
  isActive: boolean;
  registerMember: (member: RolloverMemberDetailsList) => void;
}

export const RegisterMember = ({
  member,
  sendingStatus,
  actionedMemberContextId,
  isActive,
  registerMember,
}: RegisterMemberProps) => {
  return (
    <div>
      <div className="p--sm note">This member does not exist.</div>
      <button
        className="button button--xs button--icon button--primary"
        disabled={
          (sendingStatus === 'SENDING' &&
            actionedMemberContextId !== member?.memberContextId) ||
          !isActive
        }
        onClick={() => {
          registerMember(member);
        }}
      >
        <BsPlus /> Add member to SMSF
      </button>
    </div>
  );
};
