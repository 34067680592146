const FundDetail = ({ fund, isReceivingFund }) => {
  return (
    <div className="fund fund--home">
      <h2 className="subtitle">
        {isReceivingFund ? 'Receiving fund' : 'Transferring fund'}
      </h2>
      <h3>{fund?.fundName}</h3>
      <dl>
        <div>
          <dt>ABN</dt>
          <dd>{fund?.fundAbn}</dd>
        </div>
        {!!fund?.fundEsa && (
          <div>
            <dt>ESA</dt>
            <dd>{fund?.fundEsa}</dd>
          </div>
        )}
        {!!fund?.fundUsi && (
          <div>
            <dt>USI</dt>
            <dd>{fund?.fundUsi}</dd>
          </div>
        )}
      </dl>
    </div>
  );
};

export default FundDetail;
