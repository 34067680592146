import Alert from '../Alert/Alert';
import { Link } from 'react-router-dom';
import { getInfoMessage } from '../../utils/paymentSetupUtils';

const AccountCompleteInfo = ({ status, smsfGuid }) => {
  return (
    <div className="my-4" data-testid="top-alert-inside-smsf">
      <Alert variation="info" title="Access all SMSF Hub functionality">
        <div
          dangerouslySetInnerHTML={{
            __html: getInfoMessage(status),
          }}
        />
        <div className="mt-6 mb-2">
          <p>
            <Link
              to={`/account-setup/complete`}
              className="button button--primary"
            >
              Unlock all functionality
            </Link>
          </p>
        </div>
      </Alert>
    </div>
  );
};

export default AccountCompleteInfo;
