import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import validateABN from 'utils/validateABN';
import { TRUSTEE_STRUCTURES, CORPORATE_TRUSTEE } from 'utils/configs';
import ComplyingCodeMessages from 'utils/complyingCodeMessages';
import FormGroup from 'components/FormGroup/FormGroup';
import InputValidation from 'components/InputValidation/InputValidation';
import BackButton from 'components/BackButton/BackButton';
import NextButton from 'components/NextButton/NextButton';
import { useHistory } from 'react-router-dom';
import { AccountService } from 'services';
import { VALID_NUMBER_REGEX } from 'constants/validation';
import { ACCOUNT_SETUP_PATHNAMES } from 'constants/accountSetupPathnames';
import { SMSF_REGISTRATION_WITH_PAYMENT } from 'constants/smsfRegistrationType';
import { useTrusteeAccount } from 'contexts/trusteeAccountContext';
import { getChangedValues } from 'utils/formUtils';
import Spinner from 'components/Spinner/Spinner';
import useNavigationModal from 'hooks/useNavigationModal';
import { accountSetupBackButtonModal } from 'constants/navigationModals';

const TrusteeStructures = TRUSTEE_STRUCTURES.map((option) => option.value);

const SMSFDetailSchema = Yup.object().shape({
  abn: Yup.string()
    .test('is-valid-abn', (value, { createError }) => {
      let message;
      if (!value || value.length === 0) {
        message = 'ABN is required';
      } else if (!/^\d+$/.test(value)) {
        message = 'ABN must contain only numbers';
      } else if (value.length !== 11) {
        message = 'ABN length must be 11 digits';
      } else if (!validateABN(value)) {
        message = 'Invalid ABN';
      }
      return message
        ? createError({
          message,
        })
        : true;
    })
    .required('ABN is required'),
  trusteeStructure: Yup.string()
    .oneOf(
      TrusteeStructures,
      "Trustee structure must be either 'Individual trustee' or 'Corporate trustee'",
    )
    .required('Trustee structure is required'),
  trusteeAcn: Yup.string().when('trusteeStructure', {
    is: CORPORATE_TRUSTEE,
    then: Yup.string()
      .matches(VALID_NUMBER_REGEX, 'ACN must contain only numbers')
      .max(9, 'ACN must contain 9 digits')
      .min(9, 'ACN must contain 9 digits'),
    otherwise: Yup.string(),
  }),
});

const SMSFDetailStep = ({ toGo, setToGo }) => {
  const history = useHistory();
  const { trusteeAccount, setTrusteeAccount } = useTrusteeAccount();
  const { smsfDetails } = trusteeAccount;
  const [hasChanged, setHasChanged] = useState(false);
  const [isNextPress, setIsNextPress] = useState(false);

  const [accepted, setAccepted] = useState(
    () =>
      trusteeAccount.smsfDetails?.termsAndConditionsType &&
      trusteeAccount.smsfDetails?.termsAndConditionsType.trim() !== '',
  );
  const [abnInfo, setAbnInfo] = useState(null);
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    abn: smsfDetails?.abn ?? '',
    trusteeAcn: smsfDetails?.acn ?? '',
    trusteeStructure: smsfDetails?.trusteeStructure ?? '',
    fundName: smsfDetails?.fundName ?? '',
  };

  const loadTermsAndConditions = async () => {
    setIsLoading(true);
    if (content === null) {
      try {
        const { data } = await AccountService.retrieveTermsAndConditions(
          SMSF_REGISTRATION_WITH_PAYMENT,
        );

        setContent(data?.content);
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadTermsAndConditions();
  }, []);

  useEffect(() => {
    if (smsfDetails?.abn && abnInfo === null) {
      validateSmsfAbn(smsfDetails?.abn);
    }
  }, []);

  const validateSmsfAbn = async (abn) => {
    if (abn === '') {
      setAbnInfo(null);
      return;
    }
    try {
      const { data } = await AccountService.validateSMSFABN(
        abn,
        smsfDetails?.guid,
      );

      if (data.complyingCode === 'Y' || data.complyingCode === 'R') {
        setAbnInfo({
          type: 'success',
          fundName: data.organisationName,
          content: data.organisationName,
        });
      } else {
        setAbnInfo({
          type: 'warning',
          fundName: data.organisationName,
          content: ComplyingCodeMessages[data.complyingCode],
        });
      }
    } catch (error) {
      console.error(error);
      setAbnInfo({
        type: 'alert',
        content:
          error.response?.data?.validationResponse?.validations[0]?.message ||
          'Invalid ABN',
      });
    }
  };

  const handleChange = async (values) => {
    const { hasChanged } = getChangedValues(values, initialValues);
    if (hasChanged) {
      setHasChanged(true);
    }
  };

  const handleSubmit = async (values) => {
    const { hasChanged } = getChangedValues(values, initialValues);

    if (hasChanged) {
      setIsLoading(true);

      const { trusteeAcn, abn, trusteeStructure } = values;

      let smsfRequest = {
        abn: abn,
        trusteeStructure: trusteeStructure,
        fundName: abnInfo.fundName,
        termsAndConditionsType: SMSF_REGISTRATION_WITH_PAYMENT,
      };

      if (trusteeAcn !== null && trusteeAcn.trim() !== '') {
        smsfRequest = { ...smsfRequest, trusteeAcn };
      }

      const { data } = await AccountService.setupTrusteeSmsf({
        smsfRequest,
        smsfGuid: smsfDetails?.guid,
      });
      setTrusteeAccount(data);

      setIsLoading(false);
    }
    history.push(ACCOUNT_SETUP_PATHNAMES.MEMBER_DETAILS);
  };

  useNavigationModal(
    accountSetupBackButtonModal('/account-setup/start'),
    true,
    hasChanged && !isNextPress,
  );

  return (
    <>
      <div className="account-setup__container">
        <h1>SMSF details</h1>
        {isLoading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={SMSFDetailSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ values, errors, setFieldValue, handleBlur, isSubmitting }) => (
              <Form id="new-smsf-form" className="form" onChange={handleChange}>
                <div
                  className="account-setup__section"
                  role="group"
                  aria-labelledby="smsf-details"
                >
                  <FormGroup
                    id="abn"
                    name="abn"
                    label="SMSF ABN"
                    onBlur={(e) => {
                      handleBlur(e);
                      errors.abn != null
                        ? setAbnInfo(null)
                        : validateSmsfAbn(values.abn);
                    }}
                    onChange={(event) => {
                      setAbnInfo(null);
                      setFieldValue(
                        'abn',
                        event.target.value.replace(/(\s|-)/g, ''),
                      );
                    }}
                    appendix={
                      abnInfo != null ? (
                        <InputValidation type={abnInfo.type}>
                          {abnInfo.content}
                        </InputValidation>
                      ) : null
                    }
                  />

                  <FormGroup
                    id="trusteeStructure"
                    name="trusteeStructure"
                    label="Trustee structure"
                    as="select"
                  >
                    <option>Please select...</option>
                    {TRUSTEE_STRUCTURES.map(({ value, label }) => (
                      <option
                        name={label}
                        data-testid={value}
                        key={value}
                        value={value}
                      >
                        {label}
                      </option>
                    ))}
                  </FormGroup>

                  {values.trusteeStructure === CORPORATE_TRUSTEE && (
                    <FormGroup
                      id="acn"
                      name="trusteeAcn"
                      label="ACN"
                      optional={true}
                      onChange={(event) => {
                        setFieldValue(
                          'trusteeAcn',
                          event.target.value.replace(/(\s|-)/g, ''),
                        );
                      }}
                    />
                  )}
                </div>

                <h2>Terms and conditions</h2>

                <div className="account-setup__section">
                  <div
                    className="content-container terms-and-conditions-content mb-6"
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />
                  <div className="checkbox well">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      checked={!!accepted}
                      onChange={(event) => setAccepted(event.target.checked)}
                    />
                    <label htmlFor="terms" className="mb-2">
                      I acknowledge that I have read and accept the Terms and
                      Conditions.
                    </label>
                  </div>
                </div>

                <div className="button-group">
                  <BackButton
                    onClick={() => {
                      setToGo(ACCOUNT_SETUP_PATHNAMES.START);
                      history.push(ACCOUNT_SETUP_PATHNAMES.START);
                    }}
                  >
                    Back
                  </BackButton>
                  <NextButton
                    type="submit"
                    disabled={!accepted || abnInfo?.type === 'alert'}
                    onClick={() => {
                      setIsNextPress(true);
                    }}
                  >
                    Next
                  </NextButton>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default SMSFDetailStep;
