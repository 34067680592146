import { ReactNode } from 'react';

interface DisabledFieldProps {
  children: ReactNode;
  disabled: boolean;
}
const DisabledFields = ({ children, disabled }: DisabledFieldProps) => {
  if (disabled) {
    return (
      <>
        <fieldset disabled>{children}</fieldset>
      </>
    );
  } else {
    return (
      <>
        <fieldset>{children}</fieldset>
      </>
    );
  }
};

export default DisabledFields;
