import Modal from 'react-modal';
import { useState } from 'react';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import { ReactComponent as BsLink } from 'bootstrap-icons/icons/link.svg';
import { ReactComponent as ClockHistory } from 'bootstrap-icons/icons/clock-history.svg';
import HistorySlider from './HistorySlider';

export const SideBarContent = ({ data, gotToTransaction }) => {
  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] =
    useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  return (
    <>
      <HistorySlider
        isOpen={isHistoryOpen}
        onRequestClose={() => {
          setIsHistoryOpen(false);
        }}
        messageHistory={data?.history}
        messageTransactionId={data?.messageTransactionId}
      />
      {(data?.requestAction === 'ROLLOVER_TRANSACTION_REQUEST' ||
        data?.requestAction === 'RELEASE_AUTHORITY_STATEMENT') && (
        <>
          <div className="payment-container">
            <div className="subtitle">Amount to transfer</div>
            <h2>{data?.totalAmount}</h2>
            <p>PRN: {data?.paymentReferenceNumber}</p>
            <button
              type="button"
              className="button button--outline button--sm"
              onClick={() => {
                setIsPaymentDetailsModalOpen(true);
              }}
            >
              View payment details
            </button>
            <Modal
              isOpen={isPaymentDetailsModalOpen}
              onRequestClose={() => setIsPaymentDetailsModalOpen(false)}
              className="modal modal--lg"
              overlayClassName="modal-overlay"
              contentLabel="Payment details"
              aria={{
                labelledby: 'payment-details-modal',
              }}
              appElement={document.getElementById('root')}
            >
              <div className="modal__header">
                <div className="modal__heading h3" id="payment-details-modal">
                  Payment Details
                </div>
                <button
                  type="button"
                  className="d-inline-flex button--transparent button--close"
                  aria-label="Close"
                  onClick={() => setIsPaymentDetailsModalOpen(false)}
                >
                  <XLg />
                </button>
              </div>
              <div className="modal__body">
                <div className="receipt">
                  <div className="receipt__highlight">
                    <p className="h3 mb-2">Total amount due</p>
                    <p className="h2 mb-0">
                      {data?.totalAmount !== '$0.00'
                        ? data?.totalAmount
                        : 'Whole balance'}
                    </p>
                  </div>
                  <dl>
                    <div>
                      <dt>BSB</dt>
                      <dd>
                        {data?.financialInstitutionAccount?.bankBranchNumber}
                      </dd>
                    </div>
                    <div>
                      <dt>Account no.</dt>
                      <dd>
                        {data?.financialInstitutionAccount?.accountNumber}
                      </dd>
                    </div>
                    <div>
                      <dt>Account name</dt>
                      <dd>{data?.financialInstitutionAccount?.accountName}</dd>
                    </div>
                    <div>
                      <dt>Payment Ref No (PRN)</dt>
                      <dd>{data?.paymentReferenceNumber}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </Modal>
          </div>
          {/* <hr /> */}
        </>
      )}
      <ul>
        {data?.memberDetailsList?.length === 1 &&
          !!data?.memberDetailsList[0]?.initiatorMessageTransactionId && (
            <li>
              <button
                className="button button--link"
                onClick={() => {
                  gotToTransaction(
                    data?.memberDetailsList[0].initiatorMessageTransactionId,
                  );
                }}
              >
                <BsLink />
                &nbsp; View initiation transaction
              </button>
            </li>
          )}
          {data?.rolloverType === 'RAS_OUT' && (
              <li>
                  <a onClick={() => setIsHistoryOpen(true)} className="link">
                      <ClockHistory />
                      History
                  </a>
              </li>
          )}
      </ul>
    </>
  );
};
