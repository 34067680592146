export type ReportDetails = {
    key: string;
    header: string;
    description: string;
    buttonCaption: string;
    fileType: string;
    filters: string[];
}

export const START_DATE_FILTER = 'startDate';
export const END_DATE_FILTER = 'endDate';
export const MEMBER_FILTER = 'member';
export const SMSF_CONTRIBUTION_REPORT_KEY = 'smsfContributionReport';
export const MEMBER_CONTRIBUTION_REPORT_KEY = 'memberContributionReport';
export const CONTRIBUTION_CSV_SUMMARY_REPORT_KEY = 'contributionCsvSummaryReport';

export const SMSF_CONTRIBUTION_REPORT: ReportDetails = {
    key: SMSF_CONTRIBUTION_REPORT_KEY,
    header: 'SMSF Contribution Report',
    description: 'Contribution summary for your SMSF',
    buttonCaption: 'Download report',
    fileType: 'PDF',
    filters: [START_DATE_FILTER, END_DATE_FILTER]
}

export const MEMBER_CONTRIBUTION_REPORT: ReportDetails = {
    key: MEMBER_CONTRIBUTION_REPORT_KEY,
    header: 'Member Contribution Report',
    description: 'Contribution summary for your members',
    buttonCaption: 'Download report',
    fileType: 'PDF',
    filters: [MEMBER_FILTER, START_DATE_FILTER, END_DATE_FILTER]
}

export const CONTRIBUTION_CSV_SUMMARY_REPORT: ReportDetails = {
    key: CONTRIBUTION_CSV_SUMMARY_REPORT_KEY,
    header: 'Contribution Summary Report',
    description: 'Contribution summary for all your smsfs',
    buttonCaption: 'Download CSV',
    fileType: 'CSV',
    filters: [START_DATE_FILTER, END_DATE_FILTER]
}

export const REPORT_MENU_REPORT_LIST = [
    SMSF_CONTRIBUTION_REPORT,
    MEMBER_CONTRIBUTION_REPORT
]