import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { TextField } from 'components/TextField/TextField';
import * as Yup from 'yup';
import Flags from 'country-flag-icons/react/3x2';
import { AWSService } from 'services/AWSService/AWSService';
import {
  isValidPhoneNumber,
  numberOnly,
  getPasswordRequirementError,
} from 'utils/customValidation';
import { mapCustomError } from 'utils/mapServiceCallErrors';
import {
  VERIFY_EMAIL,
  SIGN_IN,
  CURRENT_PROCESS,
  SIGN_UP,
} from 'constants/registration';
import { VALID_CHARACTERS_REGEX } from 'constants/validation';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { useAuthContext } from 'contexts/authContext';
import PasswordRequirements from 'components/PasswordRequirements/PasswordRequirements';
import useNavigationModal from 'hooks/useNavigationModal';
import { defaultBackButtonModal } from 'constants/navigationModals';

export const SignUp = () => {
  const [errorPassword, setErrorPassword] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [errorMobileNumber, setErrorMobileNumber] = useState();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [requirements, setRequirements] = useState();
  const [clickContinue, setClickContinue] = useState(false);
  const { setUser, setTempPwd } = useRegistration();
  const { setAuthState } = useAuthContext();
  useNavigationModal(defaultBackButtonModal);
  Yup.addMethod(Yup.mixed, 'isValidPhoneNumber', isValidPhoneNumber);

  const validate = Yup.object({
    firstName: Yup.string()
      .matches(
        VALID_CHARACTERS_REGEX,
        ' Given name must only contain alphanumerical and special characters excluding (!)',
      )
      .max(40, 'Maximum length allowed for given name is 40 characters')
      .required('Given name is required'),
    lastName: Yup.string()
      .matches(
        VALID_CHARACTERS_REGEX,
        'Last name must only contain alphanumerical and special characters excluding (!)',
      )
      .max(40, 'Maximum length allowed for last name is 40 characters')

      .required('Last name is required'),
    email: Yup.string()
      .max(128, 'Email must be less than 128 characters')
      .email('Email must be in the correct format, for example name@domain.com')

      .required('Email is required'),
    mobileNumber: Yup.mixed().isValidPhoneNumber(),

    password: Yup.mixed().test('is-valid', '  ', function async(value) {
      return getPasswordRequirementError(value, setRequirements);
    }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  useEffect(() => {
    history.push(SIGN_UP.PATH);
  }, []);

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />

        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            if (errorEmail || errorPassword) {
              return;
            }

            try {
              setIsLoading(true);
              const userGlobal = await AWSService.signUp({
                email: values.email,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                mobileNumber: values.mobileNumber,
              });
              setAuthState(CURRENT_PROCESS.REGISTRATION);
              setUser({ ...userGlobal.user, pwd: values.password });
              setTempPwd(values.password);
              history.push(VERIFY_EMAIL.PATH);
            } catch (e) {
              if (e.code === 'InvalidPasswordException') {
                setErrorPassword(mapCustomError(e));
              } else if (e.code === 'UsernameExistsException') {
                setErrorEmail(mapCustomError(e));
              } else if (e.code === 'InvalidParameterException') {
                setErrorMobileNumber(mapCustomError(e));
              } else {
                setErrorEmail(mapCustomError(e));
              }
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {({ values, errors, setTouched, setFieldValue }) => (
            <LoginContainer>
              <div>
                <h1 className="mb-2">Create your Wrkr account</h1>
                <p className="p--sm mb-8">
                  <em>All fields are required</em>
                </p>
                <Form>
                  <div className="form-fields-inline">
                    <div className="form-group">
                      <TextField
                        label="First name"
                        name="firstName"
                        type="text"
                        aria-label="firstName"
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        label="Last name"
                        name="lastName"
                        type="text"
                        aria-label="lastName"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      aria-label="email"
                      isInvalid={errorEmail}
                      onClick={() => {
                        if (errorEmail) {
                          setErrorEmail(null);
                        }
                      }}
                    />
                    {errorEmail && (
                      <p
                        className="error"
                        dangerouslySetInnerHTML={{ __html: errorEmail }}
                      ></p>
                    )}
                  </div>
                  <label htmlFor="mobileNumber" className="label">
                    Australian mobile number
                  </label>
                  <p className="p--sm mb-0">
                    A verification code will be sent to this number to verify
                    your account later.
                  </p>

                  <div className="form-group form-flag">
                    <TextField
                      name="mobileNumber"
                      aria-label="mobileNumber"
                      type="text"
                      inputMode="numeric"
                      isInvalid={errorMobileNumber}
                      onKeyPress={numberOnly}
                      onClick={() => {
                        if (errorMobileNumber) {
                          setErrorMobileNumber(null);
                        }
                      }}
                    />
                    {errorMobileNumber && (
                      <p className="error">{errorMobileNumber}</p>
                    )}
                    <div className="flag">
                      <Flags.AU />
                      <span>+61</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="label">
                      Password
                    </label>
                    <PasswordField
                      name="password"
                      role="password"
                      aria-label="password"
                      isInvalid={errorPassword}
                      onClick={() => {
                        if (errorPassword) {
                          setErrorPassword(null);
                        }
                      }}
                      maxLength="256"
                    />
                    <PasswordRequirements
                      requirements={requirements}
                      clickContinue={clickContinue}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword" className="label">
                      Confirm Password
                    </label>

                    <PasswordField
                      name="confirmPassword"
                      role="password"
                      aria-label="confirmPassword"
                      maxLength="256"
                    />
                  </div>

                  <button
                    className="button button--lg button--primary button--full-width"
                    type="submit"
                    onClick={() => setClickContinue(true)}
                  >
                    Continue
                  </button>
                  <p className="mt-4 p--sm">
                    By continuing you agree to Wrkr's{' '}
                    <a
                      className="link"
                      href="https://wrkr.com.au/terms-of-service/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                      className="link"
                      href="https://wrkr.com.au/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p>
                  <br></br>
                  <div>
                    <span>Have an account? </span>
                    <a
                      className="link"
                      onClick={() => history.push(SIGN_IN.PATH)}
                    >
                      Sign in
                    </a>
                  </div>
                </Form>
              </div>
            </LoginContainer>
          )}
        </Formik>
      </div>
    </div>
  );
};
