import { useEffect, useState } from 'react';
import {
  TWO_FACTOR_SET_UP_COMPLETE,
  SMS_CODE_CONFIRM,
  CURRENT_PROCESS,
  SIGN_IN,
} from '../../constants/registration';

import { AWSService } from 'services';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';
import { RegisterService } from '../../services/RegisterService/RegisterService';
import { useAuthContext } from 'contexts/authContext';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { OTP } from 'components/OTP/OTP';
import { ErrorCodeMessage } from 'components/ErrorCodeMessage/ErrorCodeMessage';
import { handleCodeSessionTimeOut } from 'utils/handleError';

export const AuthenticatorLogin = () => {
  const [otp, setOtp] = useState();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [invalidCode, setInvalidCode] = useState('');
  const [showAlternative, setShowAlternative] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { user, setUser, tempPwd } = useRegistration();
  const { setAuthState, redirectTo } = useAuthContext();

  const handleChange = (otp) => {
    setSubmitEnabled(otp.length === 6);
    setOtp(otp);
  };

  const selectedSMS = async () => {
    try {
      setIsLoading(true);
      if (user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        await RegisterService.resetPreferredMFA(user.username);
        let newUser = await AWSService.signIn(user.username, tempPwd);
        setUser(newUser);
      }
      await AWSService.setPreferredMFA('SMS');
      let newUser = await AWSService.signIn(user.username, tempPwd);
      setUser(newUser);
      history.push(SMS_CODE_CONFIRM.PATH);
    } catch (error) {
      console.log('error setting SMS as MFA: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setInvalidCode('');
      const loggedInUser = await AWSService.confirmSignIn(
        user,
        otp,
        user.challengeName,
      );
      setUser(loggedInUser);
      const isHubUser = await AWSService.isHubUserCreated();
      if (!isHubUser) {
        history.push(TWO_FACTOR_SET_UP_COMPLETE.PATH);
      } else {
        setAuthState(CURRENT_PROCESS.LOGGED_IN);
        window.location.replace(redirectTo ? redirectTo : '/');
      }
    } catch (error) {
      handleCodeSessionTimeOut(error, setInvalidCode);
    }
  };

  useEffect(() => {
    if (!user) {
      history.push(SIGN_IN.PATH);
    }
  }, []);

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <LoginContainer>
          <form>
            <div>
              {/* <ArrowBack /> */}
              <h1>Enter code generated by app</h1>

              <p>Enter the code generated by the Google Authenticator app.</p>
              <div className="pin-group mb-4">
                <OTP
                  otp={otp}
                  handleChange={handleChange}
                  invalidCode={invalidCode}
                />
              </div>
              <ErrorCodeMessage
                invalidCode={invalidCode}
                errClassName={'error d-block pb-4'}
              />
              <button
                className="button button--primary button--lg button--full-width"
                disabled={!submitEnabled}
                onClick={onSubmit}
              >
                Continue
              </button>

              <div className="mt-4 mb-4">
                <a
                  onClick={() => {
                    setShowAlternative(!showAlternative);
                  }}
                  className="collapse-group"
                  data-testid="accordion-resend"
                >
                  Can't access the app?{' '}
                  {showAlternative ? (
                    <CaretUpFill className="button--icon-after__icon" />
                  ) : (
                    <CaretDownFill className="button--icon-after__icon" />
                  )}
                </a>
              </div>
            </div>
          </form>
          {showAlternative && (
            <div>
              <div className="mt-4">
                <p className="p--sm">
                  If you don't have access to the app, you can get a code sent
                  via SMS. It will be sent to the mobile number registered in
                  your Wrkr account.
                </p>
              </div>
              <button
                className="button button--outline button--xs"
                onClick={() => {
                  selectedSMS();
                }}
                data-testid="resend-button"
              >
                Send code via SMS
              </button>
            </div>
          )}
        </LoginContainer>
      </div>
    </div>
  );
};
