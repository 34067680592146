import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { SMSFService, TermsAndConditionsService } from 'services';
import { convertToAuNumericDateFormat } from 'utils/format';
import {useParticipationContext} from "../../contexts/participantContext";

const TermsAndConditions = () => {
    const { participant } = useParticipationContext();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [content, setContent] = useState("");
    const [updatedDate, setUpdatedDate] = useState("");

    const retrieveTermsAndConditions = async () => {
        const { data } = await TermsAndConditionsService.retrieveTermsAndConditions(
            participant?.termsAndConditionsType
        );
        setContent(data.content);
        setUpdatedDate(data.updatedAt);
        setModalIsOpen(true);
    }

    useEffect(() => {
        if (participant?.newVersionOfTermsAndConditionsAvailable) {
            retrieveTermsAndConditions();
        }
    }, [participant?.newVersionOfTermsAndConditionsAvailable]);

    const acceptUpdatedTC = () => {
        if (participant?.smsfStatuses) {
            Object.keys(participant?.smsfStatuses).forEach(async (key) => {
                await SMSFService.acceptTC(key, participant?.termsAndConditionsType);
            })
        }

        setModalIsOpen(false);
    }

    return (
        <>
        <Modal
            className="modal terms-and-conditions-modal"
            overlayClassName="modal-overlay"
            isOpen={modalIsOpen}
            contentLabel="Update terms and conditions"
            //@ts-ignore
            appElement={document.getElementById('root')}
            aria={{
                labelledby: 'update-terms-and-conditions-modal',
            }}
        >
            <div className="modal__header">
                <div className="modal__heading h3" id="add-rollover-error-modal">
                    Updated terms and conditions
                </div>
            </div>
            <div className="modal__body">
                <p>
                    Our terms and condition has been updated on : {convertToAuNumericDateFormat(updatedDate)}. To continue using SMSFHub, you must accept the updated terms and conditions.
                </p>

                <div
                    className="content-container terms-and-conditions-content mb-6"
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
                <div className="checkbox well">
                    <input
                        id="acceptTermsAndConditions"
                        name="acceptTermsAndConditions"
                        type="checkbox"
                        checked={accepted}
                        onChange={(event) => setAccepted(event.target.checked)}
                    />
                    <label htmlFor="acceptTermsAndConditions">
                        I acknowledge that I have read and accept the Terms and Conditions.
                    </label>
                </div>
            </div>

            <div className="modal__footer button-group">
                <button
                    type="button"
                    className="button button--primary"
                    disabled={!accepted}
                    onClick={acceptUpdatedTC}
                >
                    Continue
                </button>
            </div>
        </Modal>
        </>
    )
}

export default TermsAndConditions;