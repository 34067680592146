import HttpClient from 'utils/HttpClient';
import { AxiosResponse } from 'axios';
import {
  ContributionSummaryReportRequest,
  DownloadFileProps,
  MemberDetails,
  RolloverMemberBenefitReportRequest
} from "./types/ReportService.types";

const downloadFile = ({ response }: DownloadFileProps) => {
  if (response?.data?.size === 0) {
    return false;
  } else {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const urlObject = window.URL || window.webkitURL || window;
    const a = document.createElement('a');

    a.download = response.headers['content-disposition']
        ?.split('filename=')[1]
        ?.replace(/['"]+/g, '');
    a.href = urlObject.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    return true;
  }
};

export class ReportService {
  static async downloadRolloverBenefitsDetails(
      request: RolloverMemberBenefitReportRequest,
  ) {
    const response = await HttpClient.post(
        `/smsf-hub/report/rollover/member-benefits-details`,
        request,
        { responseType: 'blob' },
    );

    downloadFile({response});
  }

  static async downloadContributionCSVSummary(
    request: ContributionSummaryReportRequest,
  ) : Promise<Boolean> {
    const response = await HttpClient.post(
      `/smsf-hub/report/contribution/csv-summary`,
      request,
      { responseType: 'blob' },
    );
    return downloadFile({response});
  }

  static async downloadMemberContributionSummaryReport(
    request: ContributionSummaryReportRequest,
  ) : Promise<Boolean> {
    const response = await HttpClient.post(
      `/smsf-hub/report/contribution/member-contribution-summary`,
      request,
      { responseType: 'blob' },
    );

    return downloadFile({response});
  }

  static async downloadSMSFContributionSummaryReport(
    request: ContributionSummaryReportRequest,
  ) : Promise<Boolean> {
    const response = await HttpClient.post(
      `/smsf-hub/report/contribution/smsf-contribution-summary`,
      request,
      { responseType: 'blob' },
    );

    return downloadFile({response});
  }

  static async retrieveMemberList(
    smsfGuid: string,
  ): Promise<AxiosResponse<MemberDetails[]>> {
    return await HttpClient.get(
      `/smsf-hub/report/${smsfGuid}/contribution/member-list`,
    );
  }
}
