import { useEffect } from 'react';
import signOut from '../../utils/signOut';
import { Link } from 'react-router-dom';
import Header from 'components/Header/Header';

const Cleanup = () => {
  useEffect(() => {
    signOut();
  }, []);
  return (
    <>
      <Header />
      <div className="error-section">
        <div className="h3">Logged out and cleared the cache.</div>
        <Link
          to={{
            pathname: `/register`,
          }}
          className="button button--outline button--icon-before"
        >
          Back to Registration
        </Link>
      </div>
    </>
  );
};

export default Cleanup;
