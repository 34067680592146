import { ReactComponent as ArrowRight } from 'bootstrap-icons/icons/arrow-right.svg';

const NextButton = ({ children, ...props }) => {
  return (
    <button
      role="navigation"
      aria-label="next"
      className="button button--next"
      {...props}
    >
      <span>{children}</span>
      <ArrowRight />
    </button>
  );
};

export default NextButton;
