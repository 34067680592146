import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SMS_CODE_CONFIRM, MESSAGE } from '../../constants/registration';
import { LoginContainer } from './LoginContainer';
import { AWSService } from 'services';
import { mapCustomError } from 'utils/mapServiceCallErrors';
import { useRegistration } from 'contexts/registrationContext';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { VALID_PASSWORD_REGEX } from '../../constants/validation';
import { useHistory } from 'react-router-dom';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';

export const NewPassword = () => {
  const [errorMsg, setError] = useState();
  const { user, setTempPwd } = useRegistration();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const validate = Yup.object({
    password: Yup.string()
      .min(8, MESSAGE.PASSWORD_HINT)
      .max(256, 'Password must be less than 256 characters')
      .matches(VALID_PASSWORD_REGEX, MESSAGE.PASSWORD_HINT)
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      .required('Confirm new password'),
  });

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            setError(null);
            setIsLoading(true);
            setTempPwd(values.password);
            try {
              const userWithNewPasswd = await AWSService.completeNewPassword(
                user,
                values.password,
              );
              console.log('user-with-newpass', userWithNewPasswd);
              history.push(SMS_CODE_CONFIRM.PATH);
            } catch (e) {
              setError(mapCustomError(e));
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {(formik) => (
            <LoginContainer>
              <div>
                <Form>
                  <h1>Create new password</h1>
                  <div className="form-group">
                    <label className="label">Password</label>
                    <p className="p--sm mb-0">{MESSAGE.PASSWORD_HINT}</p>
                    <PasswordField name="password" />
                  </div>
                  {errorMsg && <p className="error">{errorMsg}</p>}

                  <div className="form-group">
                    <PasswordField
                      label="Confirm password"
                      name="confirmPassword"
                      className="eye-login"
                    />
                  </div>

                  <button
                    className="button button--primary button--lg button--full-width mb-4"
                    type="submit"
                  >
                    Set new password
                  </button>
                  <br />
                  <br />
                  <div></div>
                </Form>
              </div>
            </LoginContainer>
          )}
        </Formik>
      </div>
    </div>
  );
};
