import { ReactComponent as Check2 } from 'bootstrap-icons/icons/check2.svg';
import { useHistory } from 'react-router-dom';
import { isAccountSetupStepDone } from 'utils/isAccountSetupStepDone';
import { AccountService } from 'services';
import {
  ACCOUNT_SETUP_PATHNAMES,
  COMPLETED,
} from 'constants/accountSetupPathnames';
import { useParticipationContext } from 'contexts/participantContext';
import { PARTICIPANT_TYPE } from 'constants/participant';

const AccountSetupConfirmation = () => {
  const history = useHistory();
  const { participant, getParticipant } = useParticipationContext();

  const continueHub = async () => {
    if (isAccountSetupStepDone(participant.accountSetupStep, COMPLETED)) {
      await AccountService.updateAccountSetupStep(COMPLETED);
      getParticipant();
    }
    if (participant?.participantType === PARTICIPANT_TYPE.intermediary) {
      history.push('/smsfs');
    } else {
      history.push(ACCOUNT_SETUP_PATHNAMES.COMPLETED);
    }
  };

  return (
    <>
      <main className="main">
        <div className="Dashboard main__no-sidebar">
          <div className="account-setup__container">
            <div className="account-setup__section text-center">
              <div className="tick">
                <Check2 />
              </div>
              <h1 className="h1--inpanel mb-8">Account setup is complete</h1>

              <hr />
              <p className="mb-8">
                You will receive a welcome email from Wrkr SMSF Hub with a
                helpful guide to ensure you receive and process your
                contributions and rollovers.
              </p>

              <div className="well">
                <p className="mb-2">
                  Don't forget to notify your employer with your new ESA{' '}
                </p>
                <p className="h2 mb-2">
                  <span>Your ESA:</span>{' '}
                  <span className="heading-label">wrkrSMSF</span>
                </p>
              </div>
            </div>

            <div className="mb-8 text-center">
              <button
                className="button button--primary button--lg"
                onClick={continueHub}
              >
                Continue to Wrkr SMSF Hub
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AccountSetupConfirmation;
