import { useEffect } from 'react';
import signOut from 'utils/signOut';

const Logout = () => {
  useEffect(() => {
    signOut();
    window.location.replace('/');
  }, []);
  return null;
};

export default Logout;
