import { FormRadioButton, FormRadioGroup } from '../FormRadio/FormRadio';

const AddressType = () => {
  return (
    <>
      <h2 className="subhead1">Address</h2>

      <FormRadioGroup
        id="address-type"
        name="address.addressType"
        inline={true}
      >
        <div className="inline-items">
          <FormRadioButton
            id="residential-address"
            name="address.addressType"
            label="Residential"
            value="RESIDENTIAL"
          />
          <FormRadioButton
            id="postal-address"
            name="address.addressType"
            label="Postal"
            value="POSTAL"
          />
        </div>
      </FormRadioGroup>
    </>
  );
};

export default AddressType;
