import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../components/FormGroup/FormGroup';
import {
  DEATH_BENEFIT_RECIPIENT_AGE_CATEGORY_CODE,
  DEATH_BENEFIT_TAX_CODE,
} from '../../utils/configs';
import HttpClient from '../../utils/HttpClient';
import {
  FormRadioButton,
  FormRadioGroup,
} from '../../components/FormRadio/FormRadio';
import React, { useCallback, useEffect, useState } from 'react';
import { FormSelect } from '../../components/FormSelect/FormSelect';
import pick from 'lodash/pick';
import Checkbox from '../../components/Checkbox/Checkbox';
import DayInput from '../../components/DayInput/DayInput';
import { convertToIsoDateFormat } from '../../utils/format';
import ValidationError from '../../components/ValidationError/ValidationError';
import { calculateTotal } from '../../utils/calculateTotalAmount';
import MemberDetails from '../../components/MemberDetails/MemberDetails';
import Alert from '../../components/Alert/Alert';
import { getReleaseAuthorityProductTypeCodeDescription } from '../../utils/labels';
import { mapError } from '../../utils/mapServiceCallErrors';
import { testAmountHasNoMoreThanTwoDecimalPlaces } from '../../utils/validateRolloverAmount';
import { VALID_AMOUNT_REGEX } from '../../constants/validation';

const RASDataSchema = Yup.object().shape({
  memberGuid: Yup.string().required('Member is required'),

  eligibleServicePeriodStartDate: Yup.string().required(
    'Eligible service period start date is required',
  ),

  superannuationRollover: Yup.object().shape({
    transferWholeBalanceIndicator: Yup.string()
      .oneOf(['true', 'false'])
      .required('Amount to transfer is required'),
    benefitPreservedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Benefit Preserved Amount')
      .required('Benefit Preserved Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    benefitUnrestrictedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Benefit Unrestricted Amount')
      .required('Benefit Unrestricted Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    benefitRestrictedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Benefit Restricted Amount')
      .required('Benefit Restricted Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    insuredDeathCoverAmount: Yup.string().when('insuranceSelected', {
      is: true,
      then: Yup.string()
        .matches(VALID_AMOUNT_REGEX, 'Invalid Death Cover Insurance Amount')
        .required('Death Cover Insurance Amount is required')
        .test(testAmountHasNoMoreThanTwoDecimalPlaces),
      otherwise: Yup.string(),
    }),
    insuredTotalPermanentDisablementCoverAmount: Yup.string().when(
      'insuranceSelected',
      {
        is: true,
        then: Yup.string()
          .matches(
            VALID_AMOUNT_REGEX,
            'Invalid Permanent Disable Insurance Amount',
          )
          .required('Permanent Disable Insurance Amount is required')
          .test(testAmountHasNoMoreThanTwoDecimalPlaces),
        otherwise: Yup.string(),
      },
    ),
    insuredIncomeProtectionMonthlyCoverAmount: Yup.string().when(
      'insuranceSelected',
      {
        is: true,
        then: Yup.string()
          .matches(
            VALID_AMOUNT_REGEX,
            'Invalid Income Protection Monthly Cover Amount',
          )
          .required('Income Protection Monthly Cover Amount is required')
          .test(testAmountHasNoMoreThanTwoDecimalPlaces),
        otherwise: Yup.string(),
      },
    ),
    kiwiSaverPreservedAmount: Yup.string().when('kiwiSaverSelected', {
      is: true,
      then: Yup.string()
        .matches(VALID_AMOUNT_REGEX, 'Invalid KiwiSaver Preserved Amount')
        .required('KiwiSaver Preserved Amount is required')
        .test(testAmountHasNoMoreThanTwoDecimalPlaces),
      otherwise: Yup.string(),
    }),
    kiwiSaverTaxFreeAmount: Yup.string().when('kiwiSaverSelected', {
      is: true,
      then: Yup.string()
        .matches(VALID_AMOUNT_REGEX, 'Invalid KiwiSaver Tax Free Amount')
        .required('KiwiSaver Tax Free Amount is required')
        .test(testAmountHasNoMoreThanTwoDecimalPlaces),
      otherwise: Yup.string(),
    }),
    deathBenefitRecipientAgeCategoryCode: Yup.string().when(
      'deathBenefitSelected',
      {
        is: true,
        then: Yup.string()
          .oneOf(
            DEATH_BENEFIT_RECIPIENT_AGE_CATEGORY_CODE.map((it) => it.value),
            'Death Benefit Recipient Age Category Code is required',
          )
          .required('Death Benefit Recipient Age Category Code is required'),
        otherwise: Yup.string(),
      },
    ),
    deathBenefitTaxCode: Yup.string().when('deathBenefitSelected', {
      is: true,
      then: Yup.string()
        .oneOf(
          DEATH_BENEFIT_TAX_CODE.map((it) => it.value),
          'Death Benefit Tax Code is required',
        )
        .required('Death Benefit Tax Code is required'),
      otherwise: Yup.string(),
    }),
  }),

  incomeTax: Yup.object().shape({
    taxableTaxedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Taxed Amount')
      .required('Taxed Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    taxableUntaxedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Untaxed Amount')
      .required('Untaxed Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    taxFreeAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Tax Free Amount')
      .required('Tax Free Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
  }),

  totalAmount: Yup.string().test(
    'is-greater-than-zero',
    (value, { createError }) => {
      let message;
      if (value?.length > 0) {
        if (
          !VALID_AMOUNT_REGEX.test(value) ||
          Number.isNaN(parseFloat(value))
        ) {
          message = 'Invalid Total amount';
        } else if (!parseFloat(value) > 0) {
          message =
            'The total amount (ie. the sum of benefits) must be greater than $0';
        }
      }
      return message
        ? createError({
            message,
          })
        : true;
    },
  ),
});

const MemberSelectOption = ({ member }) => {
  const { lastName, givenName, taxFileNumber } = member;
  return (
    <div>
      {givenName} {lastName}{' '}
      <span className="ml-2 is-light">(TFN: {taxFileNumber})</span>
    </div>
  );
};

const ReleaseAuthorityStatementRequest = () => {
  document.title = 'New Release Authority Statement Request';
  const history = useHistory();
  const { guid, messageTransactionId } = useParams();
  const [members, setMembers] = useState([]);
  const [atoDetails, setAtoDetails] = useState(null);
  const { state } = useLocation();
  const [selectedMember, setSelectedMember] = useState(null);

  const getRASReferenceData = useCallback(async () => {
    try {
      const res = await HttpClient.get('/smsf-hub/rollover/reference-data', {
        params: {
          smsfGuid: guid,
          taxFileNumber: !!state?.member?.tfn ? state.member.tfn : null,
          dateOfBirth: !!state?.member?.dateOfBirth
            ? state.member.dateOfBirth
            : null,
          firstName: !!state?.member?.givenName ? state.member.givenName : null,
          lastName: !!state?.member?.familyName
            ? state.member.familyName
            : null,
        },
      });
      const { data } = res;
      if (!!state?.member && data.smsfMembers.length === 1) {
        setSelectedMember(data.smsfMembers[0]);
      } else {
        setMembers(
          data.smsfMembers.map((member) => ({
            value: member.memberGuid,
            label: <MemberSelectOption member={member} />,
          })),
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [guid]);

  const getATOFundDetails = useCallback(async () => {
    try {
      const res = await HttpClient.get(
        `/smsf-hub/superfund/search/retrieve-ato-details`,
      );
      const { data } = res;
      setAtoDetails({
        fundAbn: data.fundProducts[0].abn,
        fundName: data.fundProducts[0].productName,
        fundUsi: data.fundProducts[0].usi,
        bankBranchNumber: data.fundProducts[0].bankBranchNumber,
        accountNumber: data.fundProducts[0].accountNumber,
        accountName: data.fundProducts[0].accountName,
      });
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    getRASReferenceData();
  }, [getRASReferenceData]);

  useEffect(() => {
    if (atoDetails == null) {
      getATOFundDetails();
    }
  }, [atoDetails, getATOFundDetails]);

  useEffect(() => {}, [selectedMember]);

  const [submissionError, setSubmissionError] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    const {
      memberGuid,
      memberIdInReceivingFund,
      incomeTax,
      superannuationRollover,
      totalAmount,
      eligibleServicePeriodStartDate,
    } = values;

    try {
      const response = await HttpClient.post(
        '/smsf-hub/roll-out/release-authority/release-authority-statement',
        {
          initiatorMessageTransactionId: messageTransactionId,
          memberDetails: {
            initiatorMemberDetails: !!state?.member
              ? pick(state.member, [
                  'familyName',
                  'givenName',
                  'otherGivenName',
                  'dateOfBirth',
                  'tfn',
                  'address',
                  'gender',
                ])
              : null,
            memberGuid,
            superannuationFund: {
              memberIdInReceivingFund,
              eligibleServicePeriodStartDate: eligibleServicePeriodStartDate
                ? convertToIsoDateFormat(eligibleServicePeriodStartDate)
                : null,
            },
            incomeTax,
            superannuationRollover: {
              ...superannuationRollover,
              insuredDeathCoverAmount: superannuationRollover?.insuranceSelected
                ? superannuationRollover.insuredDeathCoverAmount
                : null,
              insuredTotalPermanentDisablementCoverAmount:
                superannuationRollover?.insuranceSelected
                  ? superannuationRollover.insuredTotalPermanentDisablementCoverAmount
                  : null,
              insuredIncomeProtectionMonthlyCoverAmount:
                superannuationRollover?.insuranceSelected
                  ? superannuationRollover.insuredIncomeProtectionMonthlyCoverAmount
                  : null,
              kiwiSaverPreservedAmount:
                superannuationRollover?.kiwiSaverSelected
                  ? superannuationRollover.kiwiSaverPreservedAmount
                  : null,
              kiwiSaverTaxFreeAmount: superannuationRollover?.kiwiSaverSelected
                ? superannuationRollover.kiwiSaverTaxFreeAmount
                : null,
              deathBenefitRecipientAgeCategoryCode:
                superannuationRollover?.deathBenefitSelected
                  ? superannuationRollover.deathBenefitRecipientAgeCategoryCode
                  : null,
              deathBenefitTaxCode: superannuationRollover?.deathBenefitSelected
                ? superannuationRollover.deathBenefitTaxCode
                : null,
            },
          },
          receiverDetails: pick(atoDetails, ['fundAbn', 'fundName', 'fundUsi']),
          senderDetails: {
            smsfGuid: guid,
          },
          payment: {
            financialInstitutionAccount: pick(atoDetails, [
              'bankBranchNumber',
              'accountNumber',
              'accountName',
            ]),
            paymentAmount: totalAmount,
          },
        },
      );
      const { data } = response;
      setSubmitting(false);
      history.push(
        `/smsfs/${guid}/rollovers/transaction-details/${data.messageTransactionId}?action=RELEASE_AUTHORITY_STATEMENT`,
      );
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      setSubmissionError(mapError(error));
    }
  };

  return (
    <div className="NewRollover">
      <div className="banner">
        <Breadcrumbs />
        <h1>New Release Authority Statement Request</h1>
      </div>

      <div className="content">
        <div className="new-rollover-request-form">
          <Formik
            enableReinitialize
            initialValues={{
              abn: '',
              memberGuid: !!selectedMember ? selectedMember.memberGuid : '',
              totalAmount: '',
              memberIdInReceivingFund: !!state?.member?.memberIdInReceivingFund
                ? state.member.memberIdInReceivingFund
                : '',
              eligibleServicePeriodStartDate: '',
              incomeTax: {
                taxableTaxedAmount: '0',
                taxableUntaxedAmount: '0',
                taxFreeAmount: '0',
              },
              superannuationRollover: {
                deathBenefitSelected: false,
                insuranceSelected: false,
                kiwiSaverSelected: false,
                benefitPreservedAmount: '0',
                benefitUnrestrictedAmount: '0',
                benefitRestrictedAmount: '0',
                insuredDeathCoverAmount: '0',
                insuredTotalPermanentDisablementCoverAmount: '0',
                insuredIncomeProtectionMonthlyCoverAmount: '0',
                transferWholeBalanceIndicator: '',
                remainingBenefitIndicator: false,
                kiwiSaverPreservedAmount: '0',
                kiwiSaverTaxFreeAmount: '0',
                deathBenefitRecipientAgeCategoryCode: '',
                deathBenefitTaxCode: '',
              },
            }}
            validationSchema={RASDataSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting, setFieldTouched }) => (
              <Form id="new-member-form" className="form">
                <div
                  className="panel"
                  role="group"
                  aria-labelledby="member-details"
                >
                  <h2 id="member-details">Member details</h2>
                  <MemberDetails
                    guid={guid}
                    member={state?.member}
                    members={members}
                  />
                  {selectedMember == null && members?.length > 0 && (
                    <FormGroup name="memberGuid" label="Select member">
                      {({ form, meta, name, value, ...props }) => (
                        <FormSelect
                          id="member"
                          options={members}
                          {...props}
                          name={name}
                          value={members.find(
                            (member) => member.value === value,
                          )}
                          onChange={(option) =>
                            form.setFieldValue(name, option.value)
                          }
                        />
                      )}
                    </FormGroup>
                  )}
                  <FormGroup
                    id="eligibleServicePeriodStartDate"
                    name="eligibleServicePeriodStartDate"
                    label="Eligible service period start date"
                    placeholder="dd/mm/yyyy"
                    as={DayInput}
                    hasMaxYear={true}
                  />
                </div>
                <div
                  className="panel"
                  role="group"
                  aria-labelledby="receiving-fund-details"
                  aria-describedby="receiving-fund-details-description"
                >
                  <h2 id="receiving-fund-details">Receiving fund details</h2>
                  <p id="receiving-fund-details-description">
                    Details of the fund that will be receiving payment
                  </p>
                  <div className="form-group">
                    <Alert variation="details" title={atoDetails?.fundName}>
                      <dl>
                        <div>
                          <dt>USI</dt> <dd>{atoDetails?.fundUsi}</dd>
                        </div>
                        <div>
                          <dt>ABN</dt>
                          <dd> {atoDetails?.fundAbn}</dd>
                        </div>
                        <div>
                          <dt>BSB</dt>
                          <dd> {atoDetails?.bankBranchNumber}</dd>
                        </div>
                        <div>
                          <dt>Account no</dt>{' '}
                          <dd>{atoDetails?.accountNumber}</dd>
                        </div>
                        <div>
                          <dt>Account name</dt>
                          <dd> {atoDetails?.accountName}</dd>
                        </div>
                      </dl>
                    </Alert>
                    <FormGroup
                      name="memberIdInReceivingFund"
                      label="Receiving Member ID"
                    />
                  </div>
                </div>
                <div
                  className="panel"
                  role="group"
                  aria-labelledby="rollout-amount-details"
                >
                  <h2 id="payment-details">Rollout amounts</h2>
                  {(!!state?.member?.requestedAmount ||
                    !!state?.member?.releaseAuthorityProductTypeCode) && (
                    <Alert variation="details" title="Rollout details">
                      <dl>
                        <div>
                          <dt>Requested amount</dt>
                          <dd> {state.member.requestedAmount}</dd>
                        </div>
                        <div>
                          <dt>Release authority product type code </dt>
                          <dd>
                            {getReleaseAuthorityProductTypeCodeDescription(
                              state?.member?.releaseAuthorityProductTypeCode,
                            )}
                            {' ('}
                            {state?.member?.releaseAuthorityProductTypeCode}
                            {')'}
                          </dd>
                        </div>
                      </dl>
                    </Alert>
                  )}
                  <h3>Benefits</h3>
                  <h4>Amount to transfer</h4>
                  <FormRadioGroup
                    id="amount-to-transfer"
                    name="superannuationRollover.transferWholeBalanceIndicator"
                    inline
                  >
                    <FormRadioButton
                      id="whole-balance"
                      name="superannuationRollover.transferWholeBalanceIndicator"
                      label="Whole balance"
                      value="true"
                    />
                    <FormRadioButton
                      id="partial-amount"
                      name="superannuationRollover.transferWholeBalanceIndicator"
                      label="Partial amount"
                      value="false"
                    />
                  </FormRadioGroup>

                  <h4>Please specify the amounts below</h4>

                  <Checkbox
                    id="remainingBenefitIndicator"
                    checked={
                      values?.superannuationRollover?.remainingBenefitIndicator
                    }
                    setChecked={() =>
                      setFieldValue(
                        'superannuationRollover.remainingBenefitIndicator',
                        !values?.superannuationRollover
                          ?.remainingBenefitIndicator,
                      )
                    }
                    label="Remaining benefit indicator"
                  />

                  <FormGroup
                    name="superannuationRollover.benefitPreservedAmount"
                    label="Preserved"
                    className="input--currency"
                    autoComplete="off"
                    onBlur={() => {
                      setFieldTouched(
                        'superannuationRollover.benefitPreservedAmount',
                        true,
                      );
                      setFieldValue('totalAmount', calculateTotal(values));
                    }}
                  />

                  <FormGroup
                    name="superannuationRollover.benefitUnrestrictedAmount"
                    label="Unrestricted"
                    className="input--currency"
                    autoComplete="off"
                    onBlur={() => {
                      setFieldTouched(
                        'superannuationRollover.benefitUnrestrictedAmount',
                        true,
                      );
                      setFieldValue('totalAmount', calculateTotal(values));
                    }}
                  />
                  <FormGroup
                    name="superannuationRollover.benefitRestrictedAmount"
                    label="Restricted"
                    className="input--currency"
                    autoComplete="off"
                    onBlur={() => {
                      setFieldTouched(
                        'superannuationRollover.benefitRestrictedAmount',
                        true,
                      );
                      setFieldValue('totalAmount', calculateTotal(values));
                    }}
                  />
                  <Checkbox
                    id="deathBenefitSelected"
                    checked={
                      values?.superannuationRollover?.deathBenefitSelected
                    }
                    setChecked={() =>
                      setFieldValue(
                        'superannuationRollover.deathBenefitSelected',
                        !values?.superannuationRollover?.deathBenefitSelected,
                      )
                    }
                    label="Death benefit"
                  />
                  {values.superannuationRollover.deathBenefitSelected && (
                    <>
                      <FormGroup
                        name="superannuationRollover.deathBenefitRecipientAgeCategoryCode"
                        label="Death Benefit Recipient Age Category Code"
                        as="select"
                      >
                        <option>Please select...</option>
                        {DEATH_BENEFIT_RECIPIENT_AGE_CATEGORY_CODE.map(
                          ({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ),
                        )}
                      </FormGroup>
                      <FormGroup
                        name="superannuationRollover.deathBenefitTaxCode"
                        label="Death Benefit Tax Code"
                        as="select"
                      >
                        <option>Please select...</option>
                        {DEATH_BENEFIT_TAX_CODE.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </FormGroup>
                    </>
                  )}
                  <h3>Tax</h3>
                  <FormGroup
                    name="incomeTax.taxFreeAmount"
                    label="Tax free component"
                    className="input--currency"
                    autoComplete="off"
                  />
                  <FormGroup
                    name="incomeTax.taxableTaxedAmount"
                    label="Taxed element"
                    className="input--currency"
                    autoComplete="off"
                  />
                  <FormGroup
                    name="incomeTax.taxableUntaxedAmount"
                    label="Untaxed element"
                    className="input--currency"
                    autoComplete="off"
                  />
                  <h3>Other</h3>
                  <Checkbox
                    id="insuranceSelected"
                    checked={values?.superannuationRollover?.insuranceSelected}
                    setChecked={() =>
                      setFieldValue(
                        'superannuationRollover.insuranceSelected',
                        !values?.superannuationRollover?.insuranceSelected,
                      )
                    }
                    label="Insurance"
                  />
                  {values.superannuationRollover.insuranceSelected && (
                    <>
                      <FormGroup
                        name="superannuationRollover.insuredDeathCoverAmount"
                        label="Death"
                        className="input--currency"
                        autoComplete="off"
                      />
                      <FormGroup
                        name="superannuationRollover.insuredTotalPermanentDisablementCoverAmount"
                        label="Permanent"
                        className="input--currency"
                        autoComplete="off"
                      />
                      <FormGroup
                        name="superannuationRollover.insuredIncomeProtectionMonthlyCoverAmount"
                        label="Income"
                        className="input--currency"
                        autoComplete="off"
                      />
                    </>
                  )}

                  <Checkbox
                    id="kiwiSaverSelected"
                    checked={values?.superannuationRollover?.kiwiSaverSelected}
                    setChecked={() =>
                      setFieldValue(
                        'superannuationRollover.kiwiSaverSelected',
                        !values?.superannuationRollover?.kiwiSaverSelected,
                      )
                    }
                    label="KiwiSaver"
                  />
                  {values?.superannuationRollover?.kiwiSaverSelected && (
                    <>
                      <FormGroup
                        name="superannuationRollover.kiwiSaverPreservedAmount"
                        label="Preserved"
                        className="input--currency"
                        autoComplete="off"
                      />
                      <FormGroup
                        name="superannuationRollover.kiwiSaverTaxFreeAmount"
                        label="Tax Free"
                        className="input--currency"
                        autoComplete="off"
                      />
                    </>
                  )}
                  <br />
                  <div className="panel__footer">
                    <h3>Total amount</h3>
                    <FormGroup
                      name="totalAmount"
                      className="input--currency"
                      disabled
                    />
                  </div>
                </div>

                {submissionError && (
                  <ValidationError
                    validation={submissionError.validationResponse}
                    exception={submissionError.exception}
                  />
                )}

                <div className="button-group mt-6">
                  <button
                    type="submit"
                    className="button button--primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <Link
                    to={`/smsfs/${guid}/rollovers/${messageTransactionId}`}
                    className="button button--transparent button--lg"
                  >
                    Cancel
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ReleaseAuthorityStatementRequest;
