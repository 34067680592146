import { ReactComponent as Logo } from 'assets/images/logo_wrkr-smsf.svg';
import { useHistory } from 'react-router-dom';

const GeneralError = ({ error }: { error: { message: string } }) => {
  const history = useHistory();
  const handleRedirect = () => {
    history.push('/');
  };

  return (
    <main className="main">
      <div className="Dashboard main__no-sidebar">
        <div className="account-setup__section text-center">
          <div
            className="page__background"
            style={{ width: '100vw', height: '100vh' }}
          >
            <div className="mb-8 mt-8">
              <Logo />
            </div>
            <h1 className="title mt-8">Oops</h1>
            <h1>Something went wrong</h1>
            <p className="mb-8  p--lg">{error.message}</p>
            <button
              role="navigation"
              className="button button--lg button--primary mt-8"
              onClick={handleRedirect}
            >
              Return to Wrkr SMSF Hub
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default GeneralError;
