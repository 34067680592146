import { useState } from 'react';
import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import { PAYMENT_METHOD } from 'constants/subscription';
import { useStripe } from '@stripe/react-stripe-js';
import { SubscriptionService } from 'services';

interface DirectDebitPaymentProps {
  smsfGuid: string;
}

const DirectDebitPayment = ({ smsfGuid }: DirectDebitPaymentProps) => {
  const { DIRECT_DEBIT } = PAYMENT_METHOD;
  const stripe = useStripe();
  const [, setIsSubmitting] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const showModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const continueDirectDebit = async () => {
    setModalIsOpen(false);

    setIsSubmitting(true);
    try {
      const { data } = await SubscriptionService.createCheckoutSession(
        smsfGuid,
        DIRECT_DEBIT,
      );
      setIsSubmitting(false);
      if (data?.sessionId && stripe) {
        stripe.redirectToCheckout({ sessionId: data?.sessionId });
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };
  return (
    <div>
      <button
        type="button"
        className="button button--new-page credit-card"
        onClick={showModal}
      >
        Direct debit
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Direct debit warning"
        //@ts-ignore
        appElement={document.getElementById('root')}
        aria={{
          labelledby: 'direct-debit-warning-modal',
        }}
      >
        <div className="modal__header">
          <div className="modal__heading h3" id="add-rollover-error-modal">
            Direct debit processing time
          </div>
          <button
            type="button"
            className="d-inline-flex button--transparent button--close"
            aria-label="Close"
            onClick={closeModal}
          >
            <XLg />
          </button>
        </div>
        <div className="modal__body">
          <p>
            If you choose direct debit, please note that it may take{' '}
            <strong>up to 3 business days</strong> to process your direct debit
            payment.
          </p>
          <p>
            Once processing is successful, you will be notified via email
            immediately with access to the SMSF Hub.
          </p>
        </div>
        <div className="modal__footer button-group">
          <button
            type="button"
            className="button button--primary"
            onClick={continueDirectDebit}
          >
            Continue to direct debit
          </button>
          <button
            type="button"
            className="button button--outline"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DirectDebitPayment;
