const OrganisationDetail = ({ organisation, isPayee }) => {
  return (
    <div className="fund fund--home">
      <h2 className="subtitle">{isPayee ? 'Payee' : 'Payer'}</h2>
      <h3>{organisation?.orgName}</h3>
      <dl>
        <div>
          <dt>ABN</dt>
          <dd>{organisation?.abn}</dd>
        </div>
        {!!organisation?.esa && (
          <div>
            <dt>ESA</dt>
            <dd>{organisation?.esa}</dd>
          </div>
        )}
      </dl>
    </div>
  );
};

export default OrganisationDetail;
