import { AxiosResponse } from 'axios';
import HttpClient from '../../utils/HttpClient';
import {
  SMSFDetailResponse,
  SMSFRequest,
  SMSFResponse,
  SMSFStatus,
  SMSFMember,
  SMSFMemberResponse,
} from './types/SMSFService.types';
import { TermsAndConditionsType, ValidationResponse } from 'services/AccountService/types/AccountService.types';
export class SMSFService {
  static async createSMSFFund(
    data: SMSFRequest,
  ): Promise<AxiosResponse<SMSFResponse>> {
    return HttpClient.post(`/smsf-hub/smsf/register`, data);
  }

  static async updateStatus(
    guid: string,
    status: SMSFStatus,
  ): Promise<AxiosResponse<SMSFDetailResponse>> {
    return HttpClient.post(
      `/smsf-hub/smsf/${guid}/update-status/${status}`,
      {},
    );
  }

  static async validateRegisteredSMSFMemberTFN(
    guid: string,
    memberGuid: string,
    tfn: string,
  ): Promise<AxiosResponse<ValidationResponse>> {
    return HttpClient.get(
      `/smsf-hub/smsf/${guid}/member/${memberGuid}/tfn/${tfn}/validate`,
    );
  }

  static async validateSMSFMemberTFN(
    guid: string,
    tfn: string,
  ): Promise<AxiosResponse<ValidationResponse>> {
    return HttpClient.get(`/smsf-hub/smsf/${guid}/tfn/${tfn}/validate`);
  }

  static async addSMSFMember(
    guid: string,
    data: SMSFMember,
  ): Promise<AxiosResponse<SMSFMemberResponse>> {
    return HttpClient.post(`/smsf-hub/smsf/${guid}/member`, data);
  }

  static async updateSMSFMember(
    guid: string,
    memberGuid: string,
    data: SMSFMember,
  ): Promise<AxiosResponse<SMSFMemberResponse>> {
    return HttpClient.post(`/smsf-hub/smsf/${guid}/member/${memberGuid}`, data);
  }

  static async getSMSFMember(
    guid: string,
    memberGuid: string,
  ): Promise<AxiosResponse<SMSFMemberResponse>> {
    return HttpClient.get(`/smsf-hub/smsf/${guid}/member/${memberGuid}`);
  }

  static async acceptTC(
    guid: string,
    tcType: TermsAndConditionsType,
  ):Promise<AxiosResponse<boolean>>{
    return HttpClient.put(`/smsf-hub/smsf/${guid}/accept-terms-and-conditions/${tcType}`);
  }
}
