import { SignUp } from 'pages/Registration/SignUp';
import { VerifyEmail } from 'pages/Registration/VerifyEmail';
import { TwoFactorSetup } from 'pages/Registration/TwoFactorSetup';
import { SmsCodeConfirm } from 'pages/Registration/SmsCodeConfirm';
import { TwoFactorSetupComplete } from 'pages/Registration/TwoFactorSetupComplete';
import { SignIn } from 'pages/Registration/SignIn';
import { AuthenticatorSetup } from 'pages/Registration/AuthenticatorSetup';
import { AuthenticatorLogin } from 'pages/Registration/AuthenticatorLogin';
import { EmailResetPassword } from 'pages/Registration/EmailResetPassword';
import { InfoBox } from 'pages/Registration/InfoBox';
import { ResetPassword } from 'pages/Registration/ResetPassword';
import { NewPassword } from 'pages/Registration/NewPassword';
import Forbidden from 'pages/Forbidden/Forbidden';
import Cleanup from 'pages/Cleanup/Cleanup';

const PublicRoutes = [
  {
    path: '/register',
    breadcrumb: null,
    component: SignUp,
    exact: true,
  },
  {
    path: '/login',
    breadcrumb: null,
    component: SignIn,
    exact: true,
  },
  {
    path: '/email-reset-passwd',
    breadcrumb: null,
    component: EmailResetPassword,
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/new-passwd',
    component: NewPassword,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/verify-email',
    component: VerifyEmail,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/two-factor-setup',
    component: TwoFactorSetup,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/auth-app-setup',
    component: AuthenticatorSetup,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/auth-app-login',
    component: AuthenticatorLogin,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/sms-code-confirm',
    component: SmsCodeConfirm,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/info',
    component: InfoBox,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/two-factor-setup-complete',
    component: TwoFactorSetupComplete,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/cleanup',
    component: Cleanup,
    breadcrumb: 'null',
    exact: true,
  },
  {
    path: '/forbidden',
    component: Forbidden,
    breadcrumb: 'null',
    exact: true,
  },

  {
    path: '/*',
    breadcrumb: '',
    component: SignIn,
    exact: true,
  },
];

export default PublicRoutes;
