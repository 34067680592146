export const defaultBackButtonModal = {
  header: 'Leave the page ?',
  body: 'If you leave this page, any details you enter on this screen will not be saved.',
  cancelButton: 'Cancel',
  okButton: 'Leave page',
  redirectTo: '/login',
  absRedirect: true,
  forceSignOut: true,
};

export const registrationFirstPageBackButtonModal = {
  header: 'Exit registration ?',
  body: 'If you choose to exit registration, any details you have entered on this screen will not be saved.',
  cancelButton: 'Cancel',
  okButton: 'Exit registration',
  redirectTo: 'https://wrkr.com.au/smsf-compliance/',
  absRedirect: true,
  forceSignOut: true,
};

export const accountSetupFirstPageBackButtonModal = {
  header: 'Exit account setup ?',
  body: 'You can <b>log back in </b>at any point to resume your account setup.',
  cancelButton: 'Cancel',
  okButton: 'Exit account setup',
  redirectTo: '/login',
  absRedirect: false,
  forceSignOut: true,
};

export const accountSetupBackButtonModal = (redirectTo: string) => ({
  header: 'Leave the page ?',
  body: 'If you leave this page, any details you enter on this screen will not be saved.',
  cancelButton: 'Cancel',
  okButton: 'Leave page',
  redirectTo,
  absRedirect: true,
  forceSignOut: false,
});

export const RegistrationBackButtonModal = {
  header: 'Leave the page ?',
  body: 'If you leave this page, any details you enter on this screen will not be saved.',
  cancelButton: 'Cancel',
  okButton: 'Leave page',
  redirectTo: '/login',
  forceSignOut: false,
};
