import { isAccountSetupStepDone } from 'utils/isAccountSetupStepDone';
import { useContext, createContext, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { AccountService } from 'services';
import { useSessionStorage } from 'hooks/useSessionStorage';
import {
  ACCOUNT_SETUP_PATHNAMES,
  ACCOUNT_SETUP_PATHNAMES_TO_STEP,
} from 'constants/accountSetupPathnames';
import { STATUS } from 'constants/global';
import GeneralError from '../pages/GeneralError/GeneralError';
import {
  isPaymentDone,
  isSubscriptionRenewalPending,
} from '../utils/paymentSetupUtils';

const TrusteeAccountContext = createContext();
TrusteeAccountContext.displayName = 'TrusteeAccountContext';

export const TrusteeAccountProvider = (props) => {
  const location = useLocation();
  const { RESOLVED, PENDING, IDLE, REJECTED } = STATUS;
  const history = useHistory();
  const [trusteeAccount, setTrusteeAccount] = useState({});
  const [error, setError] = useState(null);
  const [isAccountSetupInitialLoad, setIsAccountSetupInitialLoad] =
    useSessionStorage('is-account-setup-initial-load', true);
  const [status, setStatus] = useState(RESOLVED);
  const latestAccountSetup =
    trusteeAccount?.participantDetails?.accountSetupStep;
  const smsfStatus = trusteeAccount?.smsfDetails?.smsfStatus;

  const isLoading = status === IDLE || status === PENDING;
  const isResolved = status === RESOLVED;
  const [isRenewal, setIsRenewal] = useSessionStorage('is-renewal', false);

  const getTrusteeAccount = async () => {
    setStatus(PENDING);
    try {
      const { data } = await AccountService.retrieveTrusteeAccount();
      setTrusteeAccount(data);
      setStatus(RESOLVED);
    } catch (e) {
      setError(error);
      setStatus(REJECTED);
    }
  };

  // Check initial load access , to push user to latest page
  useEffect(() => {
    if (isSubscriptionRenewalPending(smsfStatus) || isRenewal) {
      setIsRenewal(true);

      if (!location.pathname.includes('/payment-renewal')) {
        if (isPaymentDone(smsfStatus)) {
          setIsRenewal(false);
        }
        history.push('/payment-renewal');
      } else {
        if (isPaymentDone(smsfStatus)) {
          setIsRenewal(false);
          window.location.replace('/payment-renewal');
        }
      }
    } else if (
      isAccountSetupInitialLoad &&
      trusteeAccount &&
      latestAccountSetup
    ) {
      setIsAccountSetupInitialLoad(false);
      history.push(ACCOUNT_SETUP_PATHNAMES[latestAccountSetup]);
    } else if (
      matchPath('/account-setup', {
        path: history.location.pathname,
      }) ||
      (!isAccountSetupInitialLoad &&
        isAccountSetupStepDone(
          latestAccountSetup,
          ACCOUNT_SETUP_PATHNAMES_TO_STEP[history.location.pathname],
        ))
    ) {
      history.push(ACCOUNT_SETUP_PATHNAMES[latestAccountSetup]);
    }
  }, [
    history,
    isAccountSetupInitialLoad,
    latestAccountSetup,
    setIsAccountSetupInitialLoad,
    trusteeAccount,
  ]);

  // get trustee account on page load, we may want to change this behavior later
  useEffect(() => {
    getTrusteeAccount();
  }, []);

  const value = useMemo(
    () => ({
      trusteeAccount,
      setTrusteeAccount,
      isAccountSetupInitialLoad,
      setIsAccountSetupInitialLoad,
      latestAccountSetup,
    }),
    [
      isAccountSetupInitialLoad,
      latestAccountSetup,
      setIsAccountSetupInitialLoad,
      trusteeAccount,
    ],
  );

  if (isLoading) {
    return null;
  }

  if (error) {
    return <GeneralError error={error} />;
  }

  if (isResolved) {
    return <TrusteeAccountContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
};

export const useTrusteeAccount = () => {
  const context = useContext(TrusteeAccountContext);
  if (context === undefined) {
    throw new Error(
      `useTrusteeAccount must be used within a TrusteeAccountProvider`,
    );
  }
  return context;
};
