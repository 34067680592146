import { useCallback, useState } from 'react';
import { isATOIntDone, isPaymentDone } from '../../utils/paymentSetupUtils';
import ValidationError from '../../components/ValidationError/ValidationError';
import SpinnerModal from '../../components/SpinnerModal/SpinnerModal';
import { mapError } from '../../utils/mapServiceCallErrors';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Cross } from '../../assets/images/icons/icon_cross.svg';
import { SMSFService } from '../../services';
import { SMSF_STATUS } from '../../constants/smsf';
import { useParticipationContext } from 'contexts/participantContext';

const AccountSetupCompleteStep = () => {
  const { participant, getParticipant } = useParticipationContext();
  const SMSFStatus = Object.values(participant?.smsfStatuses)[0];
  const smsfGuid = Object.keys(participant?.smsfStatuses)[0];
  const [atoComplete, setAtoComplete] = useState(isATOIntDone(SMSFStatus));
  const [isProcessing, setIsProcessing] = useState(false);
  const [submissionError, setSubmissionError] = useState(undefined);
  const history = useHistory();

  const updateStatus = useCallback(
    async (newStatus) => {
      setIsProcessing(true);
      try {
        if (newStatus !== SMSFStatus) {
          await SMSFService.updateStatus(smsfGuid, newStatus);
          await getParticipant();
        }
        setIsProcessing(false);
        setSubmissionError(undefined);
        history.push(`/smsfs/${smsfGuid}/contributions`);
      } catch (error) {
        setIsProcessing(false);
        console.log(error);
        setSubmissionError(mapError(error));
      }
    },
    [smsfGuid],
  );

  if (SMSFStatus !== SMSF_STATUS.atoIntPending) {
    history.push(`/smsfs/${smsfGuid}/contributions`);
  }

  return (
    <main className="main">
      <SpinnerModal isOpen={isProcessing} />
      <div className="dashboard main__no-sidebar">
        <div className="d-flex justify-content-end mx-4 my-4">
          <button
            className="button button--icon-lg"
            onClick={() => {
              history.push(`/smsfs/${smsfGuid}/contributions`);
            }}
          >
            <Cross />
          </button>
        </div>
        <div className="account-setup__container">
          <div className="text-left">
            <h1>Update the ATO</h1>
            <p className="longform">
              In order to initiate requests and ensure rollovers can be
              processed through the Wrkr SMSF Hub, you will need to complete the
              following.
            </p>
          </div>
          <div className="panel">
            <div className="list-item">
              <div className="list-item__number">1</div>
              <div>
                <h3>Update the ATO with your new SMSF details</h3>

                <p>
                  You can call the ATO on <strong>13 10 20</strong>, or update
                  online at{' '}
                  <a
                    className="link"
                    href="https://www.abr.gov.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    abr.gov.au
                  </a>
                  , or through a registered agent. You will need to provide the
                  following:
                </p>
                <div className="well">
                  <ul className="list">
                    <li>Your SMSF ABN</li>
                    <li>Your SMSF bank details to receive the rollover</li>
                    <li>
                      Your ESA: <strong>wrkrSMSF</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <hr /> */}
            <div className="list-item">
              <div className="list-item__number">2</div>
              <div>
                <h3>Update your Software ID (SSID) in Access Manager</h3>
                <p>
                  <strong>
                    It is a requirement of the ATO that your Software ID is
                    registered with your entity to allow rollovers to be
                    processed.
                  </strong>
                </p>
                <p>
                  You can call the ATO on <strong>1300 85 22 32</strong>, or
                  update directly in the{' '}
                  <a
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://am.ato.gov.au/amsupport/pages/welcome.htm"
                  >
                    Access Manager
                  </a>
                  , or through a registered agent. You will need to provide the
                  following:
                </p>
                <div className="well">
                  <ul className="list">
                    <li>
                      Proof of record ownership (ie TFN, DOB, address, recent
                      ATO notice)
                    </li>
                    <li>Your SMSF ABN</li>
                    <li>
                      Wrkr ABN: <strong>50611202414</strong>
                    </li>
                    <li>
                      Your Software ID (SSID):{' '}
                      <strong>{participant?.ssid}</strong>
                    </li>
                  </ul>

                  <p className="mt-6">
                    <i>
                      Important: The Software ID must not be shared with any
                      unauthorised persons and entities.
                    </i>
                  </p>
                </div>
              </div>
            </div>
            <hr className="mb-8" />
            <h3>Declaration</h3>
            <p>
              Once you have completed the steps above, tick the declaration
              below.
            </p>
            <div className="well">
              <div className="checkbox">
                <input
                  id="atoComplete"
                  name="atoComplete"
                  type="checkbox"
                  disabled={isATOIntDone(SMSFStatus)}
                  checked={atoComplete}
                  onChange={(event) => setAtoComplete(event.target.checked)}
                />
                <label htmlFor="atoComplete">
                  I have updated the ATO with my new SMSF details and SSID.
                </label>
              </div>
            </div>
          </div>
          {submissionError && (
            <ValidationError
              validation={submissionError.validationResponse}
              exception={submissionError.exception}
            />
          )}
          <div className="update-ato-footer">
            <button
              className="button button--primary button--lg button--done"
              disabled={
                isProcessing || !atoComplete || !isPaymentDone(SMSFStatus)
              }
              onClick={() => {
                updateStatus(SMSF_STATUS.active);
              }}
              data-testid={'done-button'}
            >
              Done
            </button>
            <button
              className="button button-tertiary"
              style={{ marginLeft: '2rem' }}
              onClick={() => {
                history.push(`/smsfs/${smsfGuid}/contributions`);
              }}
            >
              I will do this later
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AccountSetupCompleteStep;
