import { Link } from 'react-router-dom';
import { convertToAuFllDateFormat } from '../../utils/format';
import { TRUSTEE_STRUCTURE } from '../../utils/configs';
import pick from 'lodash/pick';

const SMSFDataRow = ({ row }) => {
  return (
    <tr key={row.guid}>
      <td>
        <Link
          to={{
            pathname: `/smsfs/${row.guid}/rollovers`,
            state: {
              fund: pick(row, ['guid', 'fundName', 'trusteeStructure']),
            },
          }}
          className="link"
          data-cy={row.abn}
        >
          {row.fundName}
        </Link>
      </td>
      <td>{row.abn}</td>
      <td>{TRUSTEE_STRUCTURE[row.trusteeStructure]}</td>
      <td>{convertToAuFllDateFormat(row.updatedAt)}</td>
    </tr>
  );
};

export default SMSFDataRow;
