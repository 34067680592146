import React from 'react';

export const getPageHeader = (rolloverType, action) => {
  if (rolloverType === 'ROLL_IN' && action === 'INITIATE_ROLLOVER_REQUEST') {
    return 'Roll In Initiation';
  } else if (
    rolloverType === 'ROLL_IN' &&
    action === 'ROLLOVER_TRANSACTION_REQUEST'
  ) {
    return 'Roll In Transaction';
  } else if (
    rolloverType === 'ROLL_OUT' &&
    action === 'INITIATE_ROLLOVER_REQUEST'
  ) {
    return 'Roll Out Initiation';
  } else if (
    rolloverType === 'ROLL_OUT' &&
    action === 'ROLLOVER_TRANSACTION_REQUEST'
  ) {
    return 'Roll Out Transaction';
  } else if (rolloverType === 'RAS_OUT' && action === 'RELEASE_AUTHORITY') {
    return 'Release Authority Transaction';
  } else if (
    rolloverType === 'RAS_OUT' &&
    action === 'RELEASE_AUTHORITY_STATEMENT'
  ) {
    return 'Release Authority Transaction';
  } else if (
    rolloverType === 'ROLL_OUT' &&
    action === 'ELECTRONIC_PORTABILITY_FORM'
  ) {
    return 'EPF Transaction';
  } else {
    return 'UNKNOWN';
  }
};

export const getType = (type) => {
  if (type === 'ROLL_IN') {
    return 'Roll In';
  } else if (type === 'ROLL_OUT') {
    return 'Roll Out';
  } else if (type === 'RAS_OUT') {
    return 'Release Authority';
  } else if (type === 'CTR_IN') {
    return 'Contribution';
  } else {
    return 'UNKNOWN';
  }
};

export const getTransactionType = (action) => {
  if (action === 'INITIATE_ROLLOVER_REQUEST') {
    return 'Initiation';
  } else if (action === 'ROLLOVER_TRANSACTION_REQUEST') {
    return 'Transaction';
  } else if (action === 'RELEASE_AUTHORITY') {
    return 'Transaction';
  } else if (action === 'RELEASE_AUTHORITY_STATEMENT') {
    return 'Transaction';
  } else if (action === 'ELECTRONIC_PORTABILITY_FORM') {
    return 'Transaction';
  } else {
    return 'UNKNOWN';
  }
};

export const getStatus = ({ transactionStatus, rolloverType, action }) => {
  if (
    action === 'CONTRIBUTION_TRANSACTION_REQUEST' &&
    transactionStatus === 'REQUESTED'
  ) {
    return <span className="pill pill--success">Received</span>;
  } else if (
    action === 'RELEASE_AUTHORITY' &&
    transactionStatus === 'INITIATED'
  ) {
    return <span className="pill pill--initiate">RA</span>;
  } else if (
    action === 'RELEASE_AUTHORITY' &&
    transactionStatus === 'INITIATION_ERROR'
  ) {
    return <span className="pill pill--error">RA error</span>;
  } else if (
    action === 'RELEASE_AUTHORITY' &&
    transactionStatus === 'REQUESTED'
  ) {
    return <span className="pill pill--request">RA statement</span>;
  } else if (
    action === 'RELEASE_AUTHORITY' &&
    transactionStatus === 'SUCCESS'
  ) {
    return <span className="pill pill--success">RA complete</span>;
  } else if (
      action === 'RELEASE_AUTHORITY' &&
      transactionStatus === 'REQUEST_ERROR'
  ) {
    return <span className="pill pill--error">RAS rejected</span>;
  } else if (action === 'ELECTRONIC_PORTABILITY_FORM') {
    return <span className="pill pill--initiate">EPF</span>;
  } else if (transactionStatus === 'INITIATED') {
    return (
      <span className="pill pill--initiate">
        {rolloverType === 'ROLL_OUT'
          ? 'Roll out initiation'
          : 'Roll in initiation'}
      </span>
    );
  } else if (transactionStatus === 'INITIATION_ERROR') {
    return <span className="pill pill--error">Initiation rejected</span>;
  } else if (transactionStatus === 'REQUESTED') {
    return (
      <span className="pill pill--request">
        {rolloverType === 'ROLL_OUT' ? 'Roll out request' : 'Roll in request'}
      </span>
    );
  } else if (transactionStatus === 'SUCCESS') {
    return (
      <span className="pill pill--success">
        {rolloverType === 'ROLL_OUT' ? 'Roll out complete' : 'Roll in complete'}
      </span>
    );
  } else if (transactionStatus === 'REQUEST_ERROR') {
    return (
      <span className="pill pill--error">
        {rolloverType === 'ROLL_OUT' ? 'Roll out rejected' : 'Roll in rejected'}
      </span>
    );
  }
};

export const getReleaseAuthorityProductTypeCodeDescription = (
  productTypeCode,
) => {
  switch (productTypeCode) {
    case 'FHSSS':
      return 'First home super saver (FHSS) scheme';
    case 'ECC':
      return 'Excess Concessional Contributions';
    case 'ENCC':
      return 'Excess Non-Concessional Contributions';
    case 'ENCCT':
      return 'Excess Non-Concessional Contributions Tax';
    case 'Div293':
      return 'Division 293 due and payable';
    case 'DivDef':
      return 'Division 293 deferred debt';
    default:
      return 'Unknown product type code';
  }
};

export const getMemberType = (memberType, trusteeStructure) => {
  if (trusteeStructure === 'INDIVIDUAL_TRUSTEE') {
    switch (memberType) {
      case 'MEMBER':
        return 'Member';
      case 'CONTACT':
        return 'Trustee';
      case 'MEMBER_AND_CONTACT':
        return 'Member and trustee';
      default:
        return 'Unknown member type';
    }
  } else {
    switch (memberType) {
      case 'MEMBER':
        return 'Member';
      case 'CONTACT':
        return 'Director';
      case 'MEMBER_AND_CONTACT':
        return 'Member and director';
      default:
        return 'Unknown member type';
    }
  }
};

export const getMemberLabel = (trusteeStructure) => {
  switch (trusteeStructure) {
    case 'INDIVIDUAL_TRUSTEE':
      return 'Trustee';
    case 'CORPORATE_TRUSTEE':
      return 'Director';
    default:
      return 'Contact';
  }
};

export const getListOfMembersLabel = (trusteeStructure) => {
  switch (trusteeStructure) {
    case 'INDIVIDUAL_TRUSTEE':
      return 'Trustees';
    case 'CORPORATE_TRUSTEE':
      return 'Directors';
    default:
      return 'Contacts';
  }
};

export const getHistoryLabel = (action) => {
  switch (action) {
    case 'RELEASE_AUTHORITY':
      return 'Release Authority (RA)';
    case 'RELEASE_AUTHORITY_STATEMENT_OUTCOME_RESPONSE':
      return 'Release Authority Statement Outcome Response (RASOR)';
    case 'RELEASE_AUTHORITY_STATEMENT':
      return 'Release Authority Statement (RAS)';
    case 'RELEASE_AUTHORITY_ERROR_RESPONSE':
      return 'Release Authority Error Response (RAER)';
    default:
      return 'Unknown';
  }
};

export const getActionMessage = (action) => {
  switch (action) {
    case 'RELEASE_AUTHORITY_STATEMENT':
      return 'Send a new Release Authority Statement (RAS) with errors fixed.';
    default:
      return 'Unknown';
  }
}
