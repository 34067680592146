import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import ProtectedRoutes from 'routes/protected';

const Breadcrumbs = ({ spliter = ' / ' }) => {
  const breadcrumbs = useBreadcrumbs(ProtectedRoutes);
  const [isRasRollOut] = useState(
    breadcrumbs[2]?.match.url
      .split(spliter.trim())
      .includes('ras-out' || 'roll-out'),
  );

  const getPathToMod = () => {
    if (isRasRollOut) {
      const splitPath = breadcrumbs[2].match.url.split(spliter.trim());
      if (splitPath.includes('ras-out')) {
        return 'ras-out';
      } else if (splitPath.includes('roll-out')) {
        return 'roll-out';
      } else {
        return 'not-found';
      }
    }
  };

  return (
    <div className="breadcrumbs">
      {breadcrumbs.map(({ location, match, breadcrumb }, index) => {
        if (!(index === 0 && breadcrumb.key === '/account-setup')) {
          return (
            <span key={match.url}>
              {/* Use the current location object (with search and state) when it is the last breadcrumb so the query params and state will not be lost */}
              <NavLink
                className={
                  index === breadcrumbs.length - 1 ? 'last-breadcrumb' : ''
                }
                to={
                  index === breadcrumbs.length - 1
                    ? location
                    : isRasRollOut
                    ? breadcrumbs[index + 1].match.url.replace(
                        getPathToMod(),
                        'rollovers',
                      )
                    : match.url
                }
              >
                {breadcrumb}
              </NavLink>
              {index < breadcrumbs.length - 1 && spliter}
            </span>
          );
        } else return null;
      })}
    </div>
  );
};

export default Breadcrumbs;
