import Header from 'components/Header/Header';

const Forbidden = () => {
  return (
    <>
      <Header />
      <div className="error-section">
        <h1>Access denied.</h1>
        <div className="h3">You do no have the right access to the system.</div>
      </div>
    </>
  );
};

export default Forbidden;
