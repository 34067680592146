import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormGroup from 'components/FormGroup/FormGroup';
import { ESA_ALIASES } from 'utils/configs';
import HttpClient from 'utils/HttpClient';
import {
  FormRadioButton,
  FormRadioGroup,
} from 'components/FormRadio/FormRadio';
import { useCallback, useEffect, useState } from 'react';
import { FormSelect, AsyncFormSelect } from 'components/FormSelect/FormSelect';

import pick from 'lodash/pick';
import ValidationError from 'components/ValidationError/ValidationError';
import MemberDetails from 'components/MemberDetails/MemberDetails';
import Spinner from 'components/Spinner/Spinner';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { mapError } from 'utils/mapServiceCallErrors';
import { testAmountHasNoMoreThanTwoDecimalPlaces } from 'utils/validateRolloverAmount';
import Tippy from '@tippyjs/react';
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle.svg';
import {
  VALID_ACCOUNT_NAME,
  VALID_ACCOUNT_NUMBER,
  VALID_ACOUNT_NAME_LENGTH,
  VALID_BSB_NUMBER,
} from 'constants/validation';
import debounce from 'lodash.debounce';
import { isUSIExpired, isUSISupportV3 } from 'utils/customValidation';
import DisabledFields from 'components/DisabledFields/DisabledFields';
import { useFundContext } from 'contexts/fundContext';
import { SMSF_STATUS } from '../../constants/smsf';

Yup.addMethod(Yup.mixed, 'isUSIExpired', isUSIExpired);
Yup.addMethod(Yup.mixed, 'isUSISupportV3', isUSISupportV3);

const IRRDataSchema = Yup.object().shape({
  memberGuid: Yup.string().required('Member is required'),
  transferringFund: Yup.object().shape({
    fundType: Yup.string()
      .oneOf(['APRA', 'SMSF'])
      .required('Transferring fund is required'),
    fundEsa: Yup.string().when('fundType', {
      is: 'SMSF',
      then: Yup.string()
        .oneOf(ESA_ALIASES, 'ESA is required')
        .required('ESA is required'),
      otherwise: Yup.string(),
    }),
    effectiveEndDate: Yup.mixed().isUSIExpired(),
    certificationDetails: Yup.mixed().isUSISupportV3(),
  }),
  memberIdInTransferringFund: Yup.string().when('transferringFund.fundType', {
    is: 'APRA',
    then: Yup.string().required('Transferring Member ID is required'),
    otherwise: Yup.string(),
  }),
  financialInstitutionAccount: Yup.object().shape({
    bankBranchNumber: Yup.string()
      .matches(VALID_BSB_NUMBER, 'Please enter a valid 6-digit BSB number')
      .required('BSB is required'),
    accountNumber: Yup.string()
      .matches(VALID_ACCOUNT_NUMBER, 'Please enter a valid account number')
      .required('Account number is required'),
    accountName: Yup.string()
      .matches(
        VALID_ACCOUNT_NAME,
        "Please enter an account name which contain only these characters ([0-9a-zA-Z .(){}'|-/#*&])*",
      )
      .required('Account name is required')
      .max(VALID_ACOUNT_NAME_LENGTH),
  }),
  transferWholeBalanceIndicator: Yup.string()
    .oneOf(['true', 'false'])
    .required('Amount is required'),
  requestedAmount: Yup.string().when('transferWholeBalanceIndicator', {
    is: 'false',
    then: Yup.string()
      .required('Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    otherwise: Yup.string(),
  }),
});

const MemberSelectOption = ({ member }) => {
  const { lastName, givenName, taxFileNumber } = member;
  return (
    <div>
      {givenName} {lastName}{' '}
      <span className="ml-2 is-light">(TFN: {taxFileNumber})</span>
    </div>
  );
};

const RollInRequest = () => {
  document.title = 'New Roll In Request';
  const history = useHistory();
  const { guid } = useParams();
  const [members, setMembers] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);

  const fund = useFundContext();
  const smsfStatus = fund?.smsfStatus;

  const initialValues = {
    abn: '',
    memberGuid: '',
    transferringFund: {
      fundType: '',
      fundAbn: '',
      fundEsa: '',
      fundName: '',
      fundUsi: '',
      effectiveEndDate: null,
      certificationDetails: null,
    },
    memberIdInTransferringFund: '',
    transferWholeBalanceIndicator: '',
    requestedAmount: '',
    financialInstitutionAccount: {
      bankBranchNumber: '',
      accountNumber: '',
      accountName: '',
    },
  };

  const getIRRReferenceData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await HttpClient.get('/smsf-hub/rollover/reference-data', {
        params: { smsfGuid: guid },
      });
      const { data } = res;
      setMembers(
        data.smsfMembers.map((member) => ({
          value: member.memberGuid,
          label: <MemberSelectOption member={member} />,
        })),
      );
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  }, [guid]);

  useEffect(() => {
    getIRRReferenceData();
  }, [getIRRReferenceData]);

  const searchByQuery = async (query) => {
    try {
      const { data } = await HttpClient.get(
        `/smsf-hub/superfund/search/search?query=${query}`,
      );

      if (data.fundProducts) {
        return data.fundProducts.map((product) => {
          if (product.fundType === 'SMSF') {
            return {
              value: {
                fundType: product.fundType,
                fundAbn: product.abn,
                fundName: product.fundName,
                productName: null,
                fundUsi: null,
                fundEsa: null,
                bankBranchNumber: null,
                accountNumber: null,
                accountName: null,
                effectiveEndDate: product.effectiveEndDate,
                certificationDetails: product.certificationDetails,
              },
              label: (
                <div>
                  {product.fundName}
                  <span className="ml-2 is-light">(ABN: {product.abn})</span>
                </div>
              ),
            };
          } else {
            return {
              value: {
                fundType: product.fundType,
                fundAbn: product.abn,
                fundName: product.productName,
                fundUsi: product.usi,
                productName: product.productName,
                bankBranchNumber: product.bankBranchNumber,
                accountNumber: product.accountNumber,
                accountName: product.accountName,
                effectiveEndDate: product.effectiveEndDate,
                certificationDetails: product.certificationDetails,
              },
              label: (
                <div>
                  {product.productName}
                  <span className="ml-2 is-light">(USI: {product.usi} )</span>
                </div>
              ),
            };
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchFundByQuery = debounce((query, callback) => {
    searchByQuery(query).then((resp) => callback(resp));
  }, 3000);

  const [submissionError, setSubmissionError] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsProcessing(true);
    const {
      memberGuid,
      transferringFund,
      memberIdInTransferringFund,
      transferWholeBalanceIndicator,
      requestedAmount,
      financialInstitutionAccount,
    } = values;

    try {
      const response = await HttpClient.post(
        '/smsf-hub/roll-in/initiation/initiate-rollover-request',
        {
          memberDetails: {
            financialInstitutionAccount,
            memberGuid,
            memberIdInTransferringFund:
              values.transferringFund?.fundType === 'SMSF'
                ? 'UNKNOWN'
                : memberIdInTransferringFund,
            requestedAmount:
              transferWholeBalanceIndicator === 'true' ? null : requestedAmount,
            transferWholeBalanceIndicator:
              transferWholeBalanceIndicator === 'true',
          },
          receiverDetails: pick(transferringFund, [
            'fundAbn',
            'fundEsa',
            'fundName',
            'fundUsi',
          ]),
          senderDetails: {
            smsfGuid: guid,
          },
        },
      );
      const { data } = response;
      setSubmitting(false);
      setIsProcessing(false);
      history.push(
        `/smsfs/${guid}/rollovers/transaction-details/${data.messageTransactionId}?action=INITIATE_ROLLOVER_REQUEST`,
      );
    } catch (error) {
      setIsProcessing(false);
      setSubmitting(false);
      console.log(error);
      setSubmissionError(mapError(error));
    }
  };

  return (
    <div className="NewRollover">
      <div className="banner">
        <Breadcrumbs />
        <h1>New Roll In Request</h1>
      </div>
      <SpinnerModal
        isOpen={isProcessing}
        message="Please wait whilst we process your request"
      />
      {isLoaded ? (
        <div className="content">
          <div className="new-rollover-request-form">
            <Formik
              initialValues={initialValues}
              validationSchema={IRRDataSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting, setFieldTouched }) => (
                <Form id="new-member-form" className="form">
                  <DisabledFields disabled={smsfStatus !== SMSF_STATUS.active}>
                    <div
                      className="panel"
                      role="group"
                      aria-labelledby="member-details"
                    >
                      <h2 id="member-details">Member details</h2>
                      <MemberDetails guid={guid} members={members} />
                      {members?.length > 0 && (
                        <FormGroup name="memberGuid" label="Select member">
                          {({ form, meta, name, value, ...props }) => (
                            <FormSelect
                              id="member"
                              options={members}
                              {...props}
                              name={name}
                              value={members.find(
                                (member) => member.value === value,
                              )}
                              onChange={(option) =>
                                form.setFieldValue(name, option.value)
                              }
                            />
                          )}
                        </FormGroup>
                      )}
                    </div>
                    {members?.length > 0 ? (
                      <>
                        <div
                          className="panel"
                          role="group"
                          aria-labelledby="transferring-fund-details"
                          aria-describedby="transferring-fund-details-description"
                        >
                          <h2 id="transferring-fund-details">
                            Transferring fund details
                          </h2>
                          <p id="transferring-fund-details-description">
                            Provide details of the fund that will be making
                            payment.
                          </p>
                          <div className="form-group">
                            <label htmlFor="transferring-fund">
                              Transferring fund
                            </label>
                            <AsyncFormSelect
                              id="transferring-fund"
                              placeholder="Search by ABN, USI or fund name"
                              cacheOptions
                              noOptionsMessage={({ inputValue }) =>
                                !inputValue
                                  ? 'Start typing...'
                                  : 'No results found'
                              }
                              loadingMessage={() => 'Loading...'}
                              loadOptions={searchFundByQuery}
                              value={values.transferringFund.value}
                              onChange={(selected) => {
                                setFieldTouched('transferringFund.fundType');
                                setFieldTouched(
                                  'transferringFund.effectiveEndDate',
                                );
                                setFieldTouched(
                                  'transferringFund.certificationDetails',
                                );
                                setFieldValue(
                                  'transferringFund',
                                  selected.value,
                                );
                              }}
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="transferringFund.fundType"
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="transferringFund.effectiveEndDate"
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="transferringFund.certificationDetails"
                            />

                            {values.transferringFund.fundType !== '' ? (
                              <div className="fund-option">
                                <div className="subtitle">
                                  {values.transferringFund.fundType}
                                </div>
                                <div className="fund-name">
                                  {values.transferringFund.fundName}
                                </div>
                                {values.transferringFund.productName != null ? (
                                  <div className="fund-no">
                                    Product name:{' '}
                                    {values.transferringFund.productName}
                                  </div>
                                ) : null}
                                {values.transferringFund.fundUsi != null ? (
                                  <div className="fund-no">
                                    USI: {values.transferringFund.fundUsi}
                                  </div>
                                ) : null}
                                <div className="fund-no">
                                  ABN: {values.transferringFund.fundAbn}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          {values.transferringFund?.fundType === 'SMSF' && (
                            <FormGroup
                              name="transferringFund.fundEsa"
                              label="ESA"
                              as="select"
                            >
                              <option>Please select...</option>
                              {ESA_ALIASES.map((alias) => (
                                <option key={alias} value={alias}>
                                  {alias}
                                </option>
                              ))}
                            </FormGroup>
                          )}

                          {values.transferringFund?.fundType === 'APRA' && (
                            <FormGroup
                              name="memberIdInTransferringFund"
                              label="Transferring Member ID"
                            />
                          )}
                        </div>

                        <div
                          className="panel"
                          role="group"
                          aria-labelledby="transferring-fund-details"
                        >
                          <h2 id="payment-details">Payment details</h2>
                          <h3>Roll in amount</h3>
                          <FormRadioGroup
                            id="amount-to-transfer"
                            name="transferWholeBalanceIndicator"
                            // label="Amount to rollover"
                            inline
                          >
                            <FormRadioButton
                              id="whole-balance"
                              name="transferWholeBalanceIndicator"
                              // label="Whole balance"
                              value="true"
                              label={
                                <span className="d-inline-flex align-items-top">
                                  <span>Full rollover</span>

                                  <Tippy
                                    arrow={true}
                                    placement="top"
                                    animation="shift-away"
                                    theme="light-border"
                                    content={
                                      <div className="tooltip-popup">
                                        <p>
                                          The entire balance of the member's
                                          contributions will be rolled over from
                                          the transferring fund to this SMSF.
                                        </p>
                                      </div>
                                    }
                                  >
                                    <span className="tooltip-icon">
                                      <InfoCircle />
                                    </span>
                                  </Tippy>
                                </span>
                              }
                            />
                            <FormRadioButton
                              id="partial-amount"
                              name="transferWholeBalanceIndicator"
                              // label="Partial amount"
                              value="false"
                              label={
                                <span className="d-inline-flex align-items-top">
                                  <span>Partial rollover</span>

                                  <Tippy
                                    arrow={true}
                                    placement="top"
                                    animation="shift-away"
                                    theme="light-border"
                                    content={
                                      <div className="tooltip-popup">
                                        <p>
                                          A partial balance of the member's
                                          contributions will be rolled over from
                                          the transferring fund to this SMSF.
                                        </p>
                                      </div>
                                    }
                                  >
                                    <span className="tooltip-icon">
                                      <InfoCircle />
                                    </span>
                                  </Tippy>
                                </span>
                              }
                            />
                          </FormRadioGroup>

                          {values.transferWholeBalanceIndicator === 'false' && (
                            <FormGroup
                              name="requestedAmount"
                              label="Amount"
                              type="number"
                              className="input--currency"
                              autoComplete="off"
                            />
                          )}

                          <hr />
                          <h3>SMSF account details</h3>
                          <p className="p--sm">
                            Payment will be made to this SMSF bank account.
                            Payments can't be made to a personal account or
                            third party bank account.
                          </p>
                          <FormGroup
                            name="financialInstitutionAccount.bankBranchNumber"
                            label="BSB"
                          />
                          <FormGroup
                            name="financialInstitutionAccount.accountNumber"
                            label="Account number"
                          />
                          <FormGroup
                            name="financialInstitutionAccount.accountName"
                            label="Account name"
                          />
                        </div>

                        {submissionError && (
                          <ValidationError
                            validation={submissionError.validationResponse}
                            exception={submissionError.exception}
                          />
                        )}

                        <div className="button-group mt-6">
                          <button
                            type="submit"
                            className="button button--primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                          <Link
                            to={`/smsfs/${guid}/rollovers`}
                            className="button button--transparent button--lg"
                          >
                            Cancel
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="button-group mt-6">
                        <Link
                          to={`/smsfs/${guid}/rollovers`}
                          className="button button--transparent button--lg"
                        >
                          Cancel
                        </Link>
                      </div>
                    )}
                  </DisabledFields>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default RollInRequest;
