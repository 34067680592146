import { useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/logo_wrkr-smsf.svg';
import { ReactComponent as BoxArrowRight } from 'bootstrap-icons/icons/box-arrow-right.svg';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg';
import Tippy from '@tippyjs/react';
import signOut from 'utils/signOut';
import { Auth } from 'aws-amplify';
import { PARTICIPANT_TYPE } from 'constants/participant';
import { useParticipationContext } from 'contexts/participantContext';
import { useAuthContext } from 'contexts/authContext';
import { CURRENT_PROCESS } from 'constants/registration';
import Spinner from 'components/Spinner/Spinner';

const UserDropdown = () => {
  const [username, setUsername] = useState(null);

  const { participant } = useParticipationContext();

  useEffect(() => {
    let controller = new AbortController();
    const getUsername = async () => {
      try {
        const { attributes } = await Auth.currentAuthenticatedUser();
        setUsername(attributes?.name);
        controller = null;
      } catch (error) {
        console.log('error getting user information: ', error);
      }
    };
    getUsername();
    return () => {
      controller?.abort();
    };
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="user-dropdown">
      <Tippy
        theme="light-border"
        placement="bottom-end"
        animation="shift-away"
        arrow={false}
        trigger="click"
        interactive={true}
        offset={[0, 0]}
        onMount={() => setIsExpanded(true)}
        onHide={() => setIsExpanded(false)}
        content={
          <div>
            <ul className="py-2">
              {!!participant && (
                <li>
                  <span className="static-menu-item">
                    Software ID (SSID): {participant?.ssid}
                  </span>
                </li>
              )}
              <hr />
              <li>
                <button
                  type="button"
                  className="button button--unstyled button--icon-before"
                  onClick={async () => {
                    await signOut();
                    window.location.replace('/login');
                  }}
                  data-cy="sign-out"
                >
                  <BoxArrowRight className="button--icon-before__icon" />
                  Sign out
                </button>
              </li>
            </ul>
          </div>
        }
      >
        <button
          className="button button--unstyled button--icon-after"
          aria-expanded={isExpanded}
          data-cy="my-account"
        >
          {username ? `Hi, ${username}` : 'My Account'}
          <CaretDownFill className="button--icon-after__icon" />
        </button>
      </Tippy>
    </div>
  );
};

// Header for signed-in users
const Header = () => {
  const { participant } = useParticipationContext();
  const { authState } = useAuthContext();

  const location = useLocation().pathname.substring(0, 31); //  /account-setup/trustee/complete

  if (location === '/account-setup/trustee/complete') {
    return null;
  }

  if (authState === CURRENT_PROCESS.SIGNIN) {
    return <Spinner />;
  }

  return (
    <>
      <header className="header">
        <div className="header__inner">
          <div className="header__left">
            <div className="header__logo">
              <Link
                to={
                  participant?.participantType === PARTICIPANT_TYPE.intermediary
                    ? '/dashboard'
                    : '/'
                }
                aria-label="Homepage"
                data-cy="home"
              >
                <Logo />
              </Link>
            </div>
            {participant?.participantType === PARTICIPANT_TYPE.intermediary ? (
              <nav className="header__nav">
                <ul className="d-flex">
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      exact
                      to="/dashboard"
                      isActive={(match, location) => {
                        return !location.pathname.includes('smsfs');
                      }}
                      data-cy="dashboard"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      exact
                      to="/smsfs"
                      isActive={(match, location) => {
                        return location.pathname.includes('smsfs');
                      }}
                      data-cy="smsfs"
                    >
                      SMSFs
                    </NavLink>
                  </li>
                </ul>
              </nav>
            ) : (
              <></>
            )}
          </div>
          <div className="header__right">
            <UserDropdown />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
