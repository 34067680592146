const TableLoader = ({ row = 10 }) => (
  <div data-testid="table-loader" className="loader__placeholder">
    <div className="table-wrapper">
      <table className="table" aria-hidden="true">
        <thead>
          <tr>
            <th>
              <span className="placeholder"></span>
            </th>
            <th>
              <span className="placeholder"></span>
            </th>
            <th>
              <span className="placeholder"></span>
            </th>
            <th>
              <span className="placeholder"></span>
            </th>
            <th>
              <span className="placeholder"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
            <td>
              <span className="placeholder"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default TableLoader;
