import { Field, ErrorMessage } from 'formik';

const FormGroup = ({
  id,
  label,
  helpText,
  helpButton,
  name,
  type = 'text',
  optional = false,
  currencyInput = false,
  appendix,
  children = null,
  as = null,
  ...props
}) => {
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={id}>
          {label}
          {optional ? ' (optional)' : null}
          {helpButton && helpButton}
        </label>
      )}

      {helpText && <div className="subtxt">{helpText}</div>}

      {/* When "as" prop is not provided, render children as a callback function (a.k.a. render prop) */}
      {/* Otherwise, render them as an array of elements (e.g. <option> in the case of <Field as="select">) */}
      {children != null && as == null ? (
        <Field name={name}>
          {({ field, form, meta }) =>
            children({ ...field, form, meta, ...props, id })
          }
        </Field>
      ) : (
        <Field
          id={id}
          name={name}
          type={type}
          as={as}
          children={children}
          {...props}
        />
      )}

      <ErrorMessage component="div" className="error" name={name} />

      {appendix != null && appendix}
    </div>
  );
};

export default FormGroup;
