interface ErrorCodeMessageProps {
  invalidCode: string;
  errClassName: string;
}

export const ErrorCodeMessage = ({
  invalidCode,
  errClassName,
}: ErrorCodeMessageProps) => {
  return { invalidCode } && <span className={errClassName}>{invalidCode}</span>;
};
