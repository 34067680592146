import { ACCOUNT_SETUP_ORDER } from 'constants/accountSetupPathnames';

export const isAccountSetupStepDone = (
  currentStep,
  participantAccountSetupStep,
) => {
  return (
    currentStep &&
    ACCOUNT_SETUP_ORDER[currentStep] <
      ACCOUNT_SETUP_ORDER[participantAccountSetupStep]
  );
};
