import { AxiosResponse } from 'axios';
import HttpClient from '../../utils/HttpClient';
import { SendRolloverTransactionOutcomeResponse } from './types/RollInRolloverTransactionService.types';

export class RollInRolloverTransactionService {
  static async sendRolloverTransactionSuccessOutcomeResponse(data: {
    memberContextId: string;
    messageTransactionId: string;
  }): Promise<AxiosResponse<SendRolloverTransactionOutcomeResponse>> {
    return HttpClient.post(
      `/smsf-hub/roll-in/rollover-transaction/success-outcome-response`,
      data,
    );
  }
}
