import { createContext, useContext } from 'react';
import { useEffect, useState } from 'react';
import { AWSService } from 'services';
import { CURRENT_PROCESS } from 'constants/registration';
import { useSessionStorage } from 'hooks/useSessionStorage';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

export const AuthProvider = (props) => {
  const [authUser, setAuthUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const [authState, setAuthState] = useSessionStorage(
    'current_state',
    CURRENT_PROCESS.REGISTRATION,
  );

  const getCurrentAuthenticatedUser = async () => {
    try {
      setIsLoading(true);
      const user = await AWSService.currentAuthenticatedUser({
        bypassCache: true,
      });
      setAuthUser(user);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentAuthenticatedUser();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const isHubUser = await AWSService.isHubUserCreated();
        authUser?.signInUserSession &&
        authState === CURRENT_PROCESS.LOGGED_IN &&
        isHubUser
          ? setIsAuthorised(true)
          : setIsAuthorised(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [authUser, authState]);

  const value = {
    authUser,
    isLoading,
    isAuthorised,
    authState,
    setAuthState,
    setRedirectTo,
    redirectTo,
  };

  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuthContext must be used within a AuthProvider`);
  }
  return context;
};
