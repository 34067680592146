import AccountSetup from 'pages/AccountSetup/AccountSetup';
import SMSFs from 'pages/SMSFs/SMSFs';
import Settings from 'pages/Settings/Settings';
import Rollovers from 'pages/Rollovers/Rollovers';
import RollInRequest from 'pages/RollInRequest/RollInRequest';
import TransactionDetails from 'pages/TransactionDetails/TransactionDetails';
import Members from 'pages/Members/Members';
import NewSMSF from 'pages/NewSMSF/NewSMSF';
import RolloverDetail from 'pages/RolloverDetail/RolloverDetail';
import SMSF from 'pages/SMSF/SMSF';
import SMSFBreadCrumb from 'pages/SMSF/SMSFBreadCrumb';
import MemberDetails from 'pages/MemberDetails/MemberDetails';
import RollOutRequest from 'pages/RollOutRequest/RollOutRequest';
import ReleaseAuthorityStatementRequest from 'pages/ReleaseAuthorityStatementRequest/ReleaseAuthorityStatementRequest';
import { getListOfMembersLabel } from 'utils/labels';
import AccountSetupConfirmation from 'pages/AccountSetup/AccountSetupConfirmation';
import AccountSetupCompleteStep from 'pages/AccountSetup/AccountSetupCompleteStep';
import Logout from 'components/Logout/Logout';
import ContributionDetail from 'pages/ContributionDetail/ContributionDetail';
import NotFound from 'pages/NotFound/NotFound';
import RenewalFailureContainer from 'pages/Payment/RenewalFailureContainer';
import Reports from 'pages/Reports/Reports';
import DashboardContainer from 'pages/Dashboard/DashboardContainer';

const getQuery = (location) => {
  const { search } = location;
  return new URLSearchParams(search);
};

const ProtectedRoutes = [
  {
    path: '/logout',
    breadcrumb: null,
    component: Logout,
    exact: true,
  },
  {
    path: '/',
    breadcrumb: null,
    component: DashboardContainer,
    exact: true,
  },
  {
    path: '/dashboard',
    breadcrumb: null,
    component: DashboardContainer,
    exact: true,
  },

  {
    path: '/account-setup/start',
    component: AccountSetup,
    breadcrumb: 'Start',
  },
  {
    path: '/account-setup/smsf-details',
    component: AccountSetup,
    breadcrumb: 'SMSF details',
  },
  {
    path: '/account-setup/setup-confirmation',
    component: AccountSetupConfirmation,
    breadcrumb: 'Setup confirmation',
  },
  {
    path: '/account-setup/member-details',
    component: AccountSetup,
    breadcrumb: 'Member details',
  },
  {
    path: '/account-setup/payment-setup',
    component: AccountSetup,
    breadcrumb: 'Payment setup',
  },
  {
    path: '/account-setup/complete',
    component: AccountSetupCompleteStep,
    breadcrumb: 'Complete Account Setup',
  },
  {
    path: '/account-setup',
    component: AccountSetup,
    breadcrumb: '',
  },
  {
    path: '/smsfs',
    breadcrumb: 'SMSFs',
    component: SMSFs,
    exact: true,
  },
  {
    path: '/smsfs/rollovers',
    breadcrumb: 'Rollovers',
    component: Rollovers,
    exact: true,
  },
  {
    path: '/smsfs/contributions',
    breadcrumb: 'Contributions',
    component: DashboardContainer,
    exact: true,
  },
  {
    path: '/smsfs/new',
    breadcrumb: 'Add New SMSF',
    component: NewSMSF,
    exact: true,
  },
  {
    path: '/smsfs/:guid/',
    component: SMSF,
    breadcrumb: SMSFBreadCrumb,
    routes: [
      {
        path: '/smsfs/:guid/contributions',
        breadcrumb: 'Contributions',
        component: DashboardContainer,
        exact: true,
      },
      {
        path: '/smsfs/:guid/contributions/:messageTransactionId',
        breadcrumb: ({ match }) =>
          `Transaction ID: ${match.params.messageTransactionId}`,
        component: ContributionDetail,
        exact: true,
      },
      {
        path: '/smsfs/:guid/rollovers',
        breadcrumb: 'Rollovers',
        component: Rollovers,
        exact: true,
      },
      {
        path: '/smsfs/:guid/rollovers/roll-in',
        breadcrumb: 'Roll In Request',
        component: RollInRequest,
        exact: true,
      },
      {
        path: '/smsfs/:guid/rollovers/roll-out',
        breadcrumb: 'Roll Out Request',
        component: RollOutRequest,
        exact: true,
      },
      {
        path: '/smsfs/:guid/roll-out/:messageTransactionId',
        breadcrumb: ({ match }) =>
          `Transaction ID: ${match.params.messageTransactionId}`,
        component: RollOutRequest,
        exact: true,
      },
      {
        path: '/smsfs/:guid/ras-out/:messageTransactionId',
        breadcrumb: ({ match }) =>
          `Transaction ID: ${match.params.messageTransactionId}`,
        component: ReleaseAuthorityStatementRequest,
        exact: true,
      },
      {
        path: '/smsfs/:guid/rollovers/transaction-details/:messageTransactionId',
        component: TransactionDetails,
        breadcrumb: null,
        exact: true,
      },
      {
        path: '/smsfs/:guid/rollovers/:messageTransactionId',
        breadcrumb: ({ match }) =>
          `Transaction ID: ${match.params.messageTransactionId}`,
        component: RolloverDetail,
        exact: true,
      },
      {
        path: '/smsfs/:guid/members',
        breadcrumb: ({ location }) => {
          const query = getQuery(location);
          return (
            <span>
              Members & {getListOfMembersLabel(query.get('trusteeStructure'))}
            </span>
          );
        },
        component: Members,
        exact: true,
      },
      {
        path: '/smsfs/:guid/members/new',
        breadcrumb: 'Add New Member or Trustee',
        component: MemberDetails,
        exact: true,
      },
      {
        path: '/smsfs/:guid/members/:memberGuid',
        breadcrumb: ({ location }) => {
          const query = getQuery(location);
          return <span>{query.get('memberName')}</span>;
        },
        component: MemberDetails,
        exact: true,
      },
      {
        path: '/smsfs/:guid/settings',
        breadcrumb: 'Settings',
        component: Settings,
        exact: true,
      },
      {
        path: '/smsfs/:guid/reports',
        breadcrumb: 'Reports',
        component: Reports,
        exact: true,
      },
      {
        path: '/smsfs/:guid/*',
        breadcrumb: '',
        component: NotFound,
        exact: true,
      },
    ],
  },
  {
    path: '/payment-renewal',
    component: RenewalFailureContainer,
    breadcrumb: '',
  },
  {
    path: '/*',
    breadcrumb: '',
    component: NotFound,
    exact: true,
  },
];

export default ProtectedRoutes;
