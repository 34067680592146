import { AccountService } from 'services';
import {
  ACCOUNT_SETUP_PATHNAMES,
  MEMBER_DETAILS,
  PAYMENT_SETUP,
} from 'constants/accountSetupPathnames';
import BackButton from 'components/BackButton/BackButton';
import NextButton from 'components/NextButton/NextButton';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES,
  AUSTRALIAN_STATES_AND_TERRITORIES,
  COUNTRY_CODES,
} from 'utils/configs';
import { Form, Formik } from 'formik';
import FormGroup from 'components/FormGroup/FormGroup';
import validateTFN from 'utils/validateTFN';
import DayInput from 'components/DayInput/DayInput';
import classNames from 'classnames';

import { convertToIsoDateFormat, formatAddress } from 'utils/format';
import AddressFormGroup from 'components/AddressFormGroup/AddressFormGroup';
import { POSTCODE_REGEX } from 'constants/validation';
import { MEMBER_AND_CONTACT, CONTACT } from 'constants/participant';
import { useTrusteeAccount } from 'contexts/trusteeAccountContext';
import * as Yup from 'yup';
import Spinner from 'components/Spinner/Spinner';
import { getChangedValues } from 'utils/formUtils';
import { convertToAuNumericDateFormat } from 'utils/format';
import useNavigationModal from 'hooks/useNavigationModal';
import { accountSetupBackButtonModal } from 'constants/navigationModals';

const SexCodes = PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES.map((code) => code.value);
const States = AUSTRALIAN_STATES_AND_TERRITORIES.map((state) => state.value);

const MemberDetailsSchema = Yup.object().shape({
  isMember: Yup.string().required('smsf member is required'),
  dateOfBirth: Yup.string().when('isMember', {
    is: MEMBER_AND_CONTACT,
    then: Yup.string()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date')
      .nullable()
      .required('Date of birth is required'),
    otherwise: Yup.string(),
  }),
  gender: Yup.string().when('isMember', {
    is: MEMBER_AND_CONTACT,
    then: Yup.string().oneOf(SexCodes).required('Gender is required'),
    otherwise: Yup.string(),
  }),
  taxFileNumber: Yup.string().when('isMember', {
    is: MEMBER_AND_CONTACT,
    then: Yup.string().test('is-valid-tfn', (value, { createError }) => {
      let message;
      if (!value || value.length === 0) {
        message = 'Tax file number is required';
      } else if (!/^\d+$/.test(value)) {
        message = 'Tax file number must contain only numbers';
      } else if (value.length !== 8 && value.length !== 9) {
        message = 'Tax file number length must be 8 or 9 digits';
      } else if (!validateTFN(value)) {
        message = 'Invalid tax file number';
      }

      return message
        ? createError({
            message,
          })
        : MEMBER_AND_CONTACT;
    }),
    otherwise: Yup.string(),
  }),
  address: Yup.mixed().when('isMember', {
    is: MEMBER_AND_CONTACT,
    then: Yup.object().shape({
      line1: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Address is required'),
      suburb: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Suburb is required'),
      state: Yup.string()
        .oneOf(States, 'Please choose an option')
        .required('State / Territory is required'),
      postCode: Yup.string()
        .matches(
          POSTCODE_REGEX,
          'Please enter a valid 4-digit Australian post code',
        )
        .required('This field is required'),
      countryCode: Yup.string()
        .oneOf(COUNTRY_CODES.map((code) => code.value))
        .required('Country is required'),
      addressType: Yup.string().required('addressType is required'),
    }),
    otherwise: Yup.mixed(),
  }),
});

const MemberDetailStep = ({ toGo, setToGo }) => {
  const { trusteeAccount, setTrusteeAccount } = useTrusteeAccount();
  const { smsfDetails, memberDetails, participantDetails } = trusteeAccount;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isNextPress, setIsNextPress] = useState(false);

  const history = useHistory();

  const handleChange = async (values) => {
    const { hasChanged } = getChangedValues(values, initialValues);
    if (hasChanged) {
      setHasChanged(true);
    }
  };

  const handleSubmit = async (values) => {
    const { hasChanged } = getChangedValues(values, initialValues);
    if (hasChanged) {
      setIsLoading(true);
      const smsfMemberRequest = {
        memberGuid: memberDetails.memberGuid,
        memberType: values.isMember,
        smsfGuid: smsfDetails.guid,
        dateOfBirth:
          values.isMember === CONTACT
            ? null
            : convertToIsoDateFormat(values.dateOfBirth),
        gender: values.isMember === CONTACT ? null : values.gender,
        taxFileNumber:
          values.isMember === CONTACT ? null : values.taxFileNumber,

        address:
          values.isMember === CONTACT
            ? null
            : {
                ...values.address,
                addressType: values.address.addressType,
              },
      };

      const { data } = await AccountService.setupTrusteeMember(
        smsfMemberRequest,
      );
      setTrusteeAccount(data);

      setIsLoading(false);
    } else if (participantDetails.accountSetupStep === MEMBER_DETAILS) {
      const { data } = await AccountService.updateAccountSetupStep(
        PAYMENT_SETUP,
      );
      setTrusteeAccount(data);
      setIsLoading(false);
    }

    history.push(ACCOUNT_SETUP_PATHNAMES.PAYMENT_SETUP);
  };

  const initialValues = {
    dateOfBirth:
      memberDetails?.dateOfBirth !== '-'
        ? convertToAuNumericDateFormat(memberDetails?.dateOfBirth)
        : '',
    gender: memberDetails?.gender ?? '',
    taxFileNumber:
      memberDetails?.unmaskedTaxFileNumber !== '-'
        ? memberDetails?.unmaskedTaxFileNumber
        : '',
    address: memberDetails?.address ?? { addressType: 'RESIDENTIAL' },
    isMember:
      participantDetails?.accountSetupStep === MEMBER_DETAILS
        ? null
        : memberDetails?.memberType ?? CONTACT,
  };

  useNavigationModal(
    accountSetupBackButtonModal('/account-setup/smsf-details'),
    true,
    hasChanged && !isNextPress,
  );

  return (
    <div className="account-setup__container">
      <h1>Your member details</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={MemberDetailsSchema}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values, setFieldValue }) => (
            <Form id="member_details" className="form" onChange={handleChange}>
              <div className="account-setup__section">
                <h2 className="subhead1">
                  Are you also a member of this SMSF?
                </h2>

                <div className="form-group">
                  <div className="button-switch">
                    <button
                      type="button"
                      className={classNames('button', {
                        'is-selected': values.isMember === MEMBER_AND_CONTACT,
                      })}
                      onClick={() => {
                        setFieldValue('isMember', MEMBER_AND_CONTACT);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={classNames('button', {
                        'is-selected': values.isMember === CONTACT,
                      })}
                      onClick={() => {
                        setFieldValue('isMember', CONTACT);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>

                {values.isMember === MEMBER_AND_CONTACT && (
                  <>
                    <h2 className="subhead1 mt-8">Your details</h2>

                    <FormGroup
                      id="dateOfBirth"
                      name="dateOfBirth"
                      label="Date of birth"
                      placeholder="dd/mm/yyyy"
                      as={DayInput}
                      hasMaxYear={true}
                    />
                    <FormGroup
                      id="gender"
                      name="gender"
                      label="Gender"
                      as="select"
                    >
                      <option>Please select...</option>
                      {PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES.map(
                        ({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ),
                      )}
                    </FormGroup>
                    <FormGroup
                      id="taxFileNumber"
                      name="taxFileNumber"
                      label="Tax file number"
                      onChange={(event) => {
                        setFieldValue(
                          'taxFileNumber',
                          event.target.value.replace(/(\s|-)/g, ''),
                        );
                      }}
                    />

                    <AddressFormGroup
                      name="address"
                      label="Address"
                      initialValue={formatAddress(values.address)}
                      addressType={{
                        enable: true,
                        initialValue: initialValues.address.addressType,
                      }}
                      isSubmit={isSubmit}
                    />
                  </>
                )}

                {values.isMember === CONTACT && (
                  <div role="alert" data-testid="click-next-alert">
                    <div className="well">
                      <p className="mb-0 text-center">
                        Click <b>"Next"</b> to continue.
                      </p>
                    </div>
                  </div>
                )}

                <hr />
                <p>
                  <strong>Note:</strong> You will be able to add other members
                  later in the SMSF Hub, once you have finished your account
                  setup.
                </p>
              </div>

              <div className="button-group">
                <BackButton
                  onClick={() => {
                    setToGo(ACCOUNT_SETUP_PATHNAMES.SMSF_DETAILS);
                    history.push(ACCOUNT_SETUP_PATHNAMES.SMSF_DETAILS);
                  }}
                >
                  Back
                </BackButton>
                <NextButton
                  disabled={values.isMember === null}
                  type="submit"
                  onClick={() => {
                    setIsNextPress(true);
                    setIsSubmit(true);
                  }}
                >
                  Next
                </NextButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default MemberDetailStep;
