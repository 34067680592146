import Alert from './Alert';
import { Link } from 'react-router-dom';
import { getGlobalAlerts } from '../../utils/globalActionsUtils';
import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';

const HubGlobalAlerts = ({ participant }) => {
  const [openDetailsModal, setOpenDetailsModal] = useState({
    open: false,
    selectedDetails: null,
  });
  useEffect(() => {}, [openDetailsModal]);

  return (
    <div className="my-4">
      {getGlobalAlerts(participant).map((alert) => {
        return (
          <>
            <Alert variation="notice" title={alert.alertTitle}>
              <div
                dangerouslySetInnerHTML={{
                  __html: alert.alertText,
                }}
              />
              {alert.alertDetailedText && (
                <div className="mt-6 mb-2">
                  <p>
                    <Link
                      to="#"
                      onClick={() =>
                        setOpenDetailsModal({
                          open: true,
                          selectedDetails: alert,
                        })
                      }
                      className="button--link"
                    >
                      Read more
                    </Link>
                  </p>
                </div>
              )}
            </Alert>
            <br />
          </>
        );
      })}
      <Modal
        isOpen={openDetailsModal.open}
        onRequestClose={() =>
          setOpenDetailsModal({
            open: false,
            selectedDetails: null,
          })
        }
        className="modal modal--lg"
        overlayClassName="modal-overlay"
        contentLabel={openDetailsModal.selectedDetails?.alertTitle}
        aria={{
          labelledby: 'global-alert-details-modal',
        }}
      >
        <div className="modal__header">
          <div className="modal__heading h3" id="global-alert-details-modal">
            {openDetailsModal.selectedDetails?.alertTitle}
          </div>
          <button
            type="button"
            className="d-inline-flex button--transparent button--close"
            aria-label="Close"
            onClick={() => {
              setOpenDetailsModal({
                open: false,
                selectedDetails: null,
              });
            }}
          >
            <XLg />
          </button>
        </div>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: openDetailsModal.selectedDetails?.alertDetailedText,
          }}
        />
      </Modal>
    </div>
  );
};

export default HubGlobalAlerts;
