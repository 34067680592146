import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import DownloadButton from 'components/DownloadButton/DownloadButton';
import { REPORT_MENU_REPORT_LIST } from '../../constants/reports';
import { ReportFilterModal } from 'components/ReportFilterModal/ReportFilterModal';

const Reports = () => {
  const { guid } = useParams<{ guid: string }>();
  const [showModal, setShowModal] = useState(false);
  const [currentReport, setCurrentReport] = useState({
    key: '',
    header: '',
    description: '',
    buttonCaption: '',
    fileType: '',
    filters: [''],
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ReportFilterModal
        isOpen={showModal}
        reportDetails={currentReport}
        handleCloseModal={handleCloseModal}
        smsfGuid={guid}
      />
      <div className="rollovers-detail">
        <div className="banner banner--fixed">
          <Breadcrumbs />
          <div>
            <h1>Reports</h1>
          </div>
        </div>
        <div className="reports__container">
          {REPORT_MENU_REPORT_LIST.map((report, index) => {
            return (
              <div className="reports" id={report.key + index} key={index}>
                <div>
                  <h3>
                    <b>{report.header}</b>
                  </h3>
                  <p>{report.description}</p>
                </div>
                <div>
                  <DownloadButton
                    setShowModal={() => {
                      setShowModal(true);
                      setCurrentReport(report);
                    }}
                    label={report.buttonCaption}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Reports;
