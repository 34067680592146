import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from 'bootstrap-icons/icons/arrow-right.svg';

const NextLink = ({ children, ...props }) => {
  return (
    <Link className="d-inline-flex align-items-center" {...props}>
      <span>{children}</span>
      <ArrowRight className="ml-2" />
    </Link>
  );
};

export default NextLink;
