import SlidingPane from 'react-sliding-pane';
import { useState } from 'react';
import Accordion from '../../components/Accordion/Accordion';
import { ReactComponent as X } from 'bootstrap-icons/icons/x.svg';
import { convertToAuNumericDateFormat, isMobile } from '../../utils/format';

const MemberDetailsSlider = ({ isOpen, member, onRequestClose }) => {
  const [activeAccordion, setActiveAccordion] = useState(1);

  const hasContributionOtherDetails = ({ member }) => {
    return member?.contributionOtherDetails?.length > 0;
  };
  console.log('member -', member);

  return (
    <SlidingPane
      isOpen={isOpen}
      title={`${member?.personName?.givenName} ${member?.personName?.familyName}`}
      onRequestClose={onRequestClose}
      closeIcon={
        <button
          type="button"
          className="button--link"
          data-cy="member-details-slider-close"
        >
          <X />
        </button>
      }
      className="slide-pane--custom slide-pane--member-details"
    >
      <div className="member-details-accordion">
        <Accordion
          identifier="employer-details"
          expanded={activeAccordion === 1}
          onClick={(expanded) => setActiveAccordion(expanded ? 1 : null)}
          title={'Employer details'}
          content={
            <div>
              <dl>
                <div>
                  <dt>Name</dt>
                  <dd>{member?.employer?.organisationName?.nameText}</dd>
                </div>
                <div>
                  <dt>ABN</dt>
                  <dd>{member?.employer?.identifier?.id}</dd>
                </div>
                <div>
                  <dt>Superannuation fund generated employer identifier</dt>
                  <dd>
                    {member?.employer?.superannuationFundGeneratedEmployerId}
                  </dd>
                </div>
              </dl>
            </div>
          }
        />
        <Accordion
          identifier="member-details"
          expanded={activeAccordion === 2}
          onClick={(expanded) => setActiveAccordion(expanded ? 2 : null)}
          title={'Member details'}
          content={
            <div>
              <dl>
                <div>
                  <dt>TFN</dt>
                  <dd>{member?.taxFileNumber}</dd>
                </div>
                <div>
                  <dt>Member context id</dt>
                  <dd>{member?.id}</dd>
                </div>
                <div>
                  <dt>Title</dt>
                  <dd>{member?.personName?.title}</dd>
                </div>
                <div>
                  <dt>Name suffix</dt>
                  <dd>{member?.personName?.nameSuffix}</dd>
                </div>
                <div>
                  <dt>Last name</dt>
                  <dd>{member?.personName?.familyName}</dd>
                </div>
                <div>
                  <dt>Given name</dt>
                  <dd>{member?.personName?.givenName}</dd>
                </div>
                <div>
                  <dt>Other given name</dt>
                  <dd>{member?.personName?.otherGivenName}</dd>
                </div>
                <div>
                  <dt>Gender</dt>
                  <dd>{member?.personDemographics?.sexCode}</dd>
                </div>
                <div>
                  <dt>Date of birth</dt>
                  <dd>
                    {convertToAuNumericDateFormat(
                      member?.personDemographics?.birthDate,
                    )}
                  </dd>
                </div>
                <div>
                  <dt>Address usage code</dt>
                  <dd>{member?.address?.usageCode}</dd>
                </div>
                <div>
                  <dt>Address line 1</dt>
                  <dd>{member?.address?.line1}</dd>
                </div>
                <div>
                  <dt>Address line 2</dt>
                  <dd>{member?.address?.line2}</dd>
                </div>
                <div>
                  <dt>Address line 3</dt>
                  <dd>{member?.address?.line3}</dd>
                </div>
                <div>
                  <dt>Address line 4</dt>
                  <dd>{member?.address?.line4}</dd>
                </div>
                <div>
                  <dt>Locality</dt>
                  <dd>{member?.address?.localityName}</dd>
                </div>
                <div>
                  <dt>Postcode</dt>
                  <dd>{member?.address?.postCode}</dd>
                </div>
                <div>
                  <dt>State</dt>
                  <dd>{member?.address?.stateOrTerritoryCode}</dd>
                </div>
                <div>
                  <dt>Country code</dt>
                  <dd>{member?.address?.countryCode}</dd>
                </div>
                <div>
                  <dt>Email</dt>
                  <dd>{member?.electronicMail?.addressText}</dd>
                </div>
                <div>
                  <dt>Landline phone number</dt>
                  <dd>
                    {isMobile(member?.telephone)
                      ? ''
                      : member?.telephone?.minimalNumber}
                  </dd>
                </div>
                <div>
                  <dt>Mobile phone number</dt>
                  <dd>
                    {isMobile(member?.telephone)
                      ? member?.telephone?.minimalNumber
                      : ''}
                  </dd>
                </div>
                <div>
                  <dt>Member client identifier</dt>
                  <dd>{member?.memberClientId}</dd>
                </div>
                <div>
                  <dt>Payroll number identifier</dt>
                  <dd>{member?.employmentPayrollNumber}</dd>
                </div>
                <div>
                  <dt>Employment end date</dt>
                  <dd>
                    {convertToAuNumericDateFormat(member?.employmentEndDate)}
                  </dd>
                </div>
                <div>
                  <dt>Employment end reason</dt>
                  <dd>{member?.employmentEndReasonText}</dd>
                </div>
              </dl>
            </div>
          }
        />
        <Accordion
          identifier="contribution-details"
          expanded={activeAccordion === 3}
          onClick={(expanded) => setActiveAccordion(expanded ? 3 : null)}
          title={'Contribution details'}
          content={
            <dl>
              <div>
                <dt>Pay period start date</dt>
                <dd>
                  {convertToAuNumericDateFormat(member?.period?.startDate)}
                </dd>
              </div>
              <div>
                <dt>Pay period end date</dt>
                <dd>{convertToAuNumericDateFormat(member?.period?.endDate)}</dd>
              </div>
              <div>
                <dt>Superannuation guarantee amount</dt>
                <dd>{member?.employerContributionGuaranteeAmountValue}</dd>
              </div>
              <div>
                <dt>Award or productivity amount</dt>
                <dd>
                  {member?.employerContributionsAwardOrProductivityAmountValue}
                </dd>
              </div>
              <div>
                <dt>Personal contributions amount</dt>
                <dd>{member?.personalContributionsAmountValue}</dd>
              </div>
              <div>
                <dt>Salary sacrificed amount</dt>
                <dd>
                  {member?.employerContributionsSalarySacrificedAmountValue}
                </dd>
              </div>
              <div>
                <dt>Voluntary amount</dt>
                <dd>{member?.employerContributionsVoluntaryAmountValue}</dd>
              </div>
              <div>
                <dt>Spouse contributions amount</dt>
                <dd>{member?.spouseContributionsAmountValue}</dd>
              </div>
              <div>
                <dt>Child contributions amount</dt>
                <dd>{member?.childContributionsAmountValue}</dd>
              </div>
              <div>
                <dt>Other third party contributions amount</dt>
                <dd>{member?.otherThirdPartyContributionsAmountValue}</dd>
              </div>
              {hasContributionOtherDetails({ member }) && (
                <>
                  <div className="row member-detail__heading p-4">
                    <div className="col-5">Other details</div>
                  </div>
                  {
                    <dl>
                      {member?.contributionOtherDetails?.map(
                        (otherDetail, index) => {
                          return (
                            <div key={index}>
                              <dt>{otherDetail.description}</dt>
                              <dd>{otherDetail.amount}</dd>
                            </div>
                          );
                        },
                      )}
                    </dl>
                  }
                </>
              )}
            </dl>
          }
        />
      </div>
    </SlidingPane>
  );
};

export default MemberDetailsSlider;
