import { ReactComponent as Logo } from '../../assets/images/logo_wrkr.svg';

// Header for not authorised users
const Header = () => {
  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__left">
          <div className="header__logo-small">
            <Logo />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
