import { AxiosResponse } from 'axios';
import HttpClient from '../../utils/HttpClient';
import {
  CheckoutSession,
  PaymentMethodType,
} from './types/SubscriptionService.types';

export class SubscriptionService {
  static async createCheckoutSession(
    smsfGuid: string,
    paymentMethod: PaymentMethodType,
  ): Promise<AxiosResponse<CheckoutSession>> {
    return HttpClient.post(
      `/smsf-hub/subscription/smsf/${smsfGuid}/checkout-session/${paymentMethod}`,
      {},
    );
  }
}
