const PARTICIPANT_TYPE = {
  intermediary: 'INTERMEDIARY',
  trustee: 'TRUSTEE',
};

const PARTICIPANT_STATUS = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  pendingAccountSetup: 'PENDING_ACCOUNT_SETUP',
};

const MEMBER_AND_CONTACT = 'MEMBER_AND_CONTACT';
const MEMBER = 'MEMBER';
const CONTACT = 'CONTACT';

export {
  PARTICIPANT_TYPE,
  PARTICIPANT_STATUS,
  MEMBER_AND_CONTACT,
  MEMBER,
  CONTACT,
};
