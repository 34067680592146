import Contributions from 'pages/Contributions/Contributions';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Dashboard from './Dashboard';

const DashboardContainer = () => {
  const [disableDownload, setDisableDownload] = useState(false);
  const { guid }: any = useParams();
  const location = useLocation();

  return (
    <>
      {location.pathname === '/dashboard' && (
        <Dashboard
          disableDownload={disableDownload}
          setDisableDownload={setDisableDownload}
        />
      )}
      {(location.pathname === '/smsfs/contributions' || guid) && (
        <Contributions
          isDashboard={false}
          setDisableDownload={setDisableDownload}
        />
      )}
    </>
  );
};
export default DashboardContainer;
