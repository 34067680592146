export const EXISTING_REGISTER_LINK = {
  TYPE: 'SIGN_UP',
  PATH: '/account-setup/trustee',
};
export const SIGN_UP = { TYPE: 'SIGN_UP', PATH: '/register' };
export const VERIFY_EMAIL = { TYPE: 'VERIFY_EMAIL', PATH: '/verify-email' };
export const SIGN_IN = { TYPE: 'SIGN_IN', PATH: '/login' };
export const RESET_PASSWORD = {
  TYPE: 'RESET_PASSWORD',
  PATH: '/reset-password',
};
export const EMAIL_RESET_PASSWORD = {
  TYPE: 'EMAIL_RESET_PASSWORD',
  PATH: '/email-reset-passwd',
};
export const NEW_PASSWORD = {
  TYPE: 'NEW_PASSWORD',
  PATH: '/new-passwd',
};
export const TWO_FACTOR_SET_UP = {
  TYPE: 'TWO_FACTOR_SET_UP',
  PATH: '/two-factor-setup',
};
export const AUTHENTICATOR_SET_UP = {
  TYPE: 'AUTHENTICATOR_SET_UP',
  PATH: '/auth-app-setup',
};
export const AUTHENTICATOR_LOG_IN = {
  TYPE: 'AUTHENTICATOR_LOG_IN',
  PATH: '/auth-app-login',
};
export const SMS_CODE_CONFIRM = {
  TYPE: 'SMS_CODE_CONFIRM',
  PATH: '/sms-code-confirm',
};
export const TWO_FACTOR_SET_UP_COMPLETE = {
  TYPE: 'TWO_FACTOR_SET_UP_COMPLETE',
  PATH: '/two-factor-setup-complete',
};
export const INFO = { TYPE: 'INFO', PATH: '/info' };
export const USER_ROLE = 'USER';
export const USER_PARTICIPANT_ROLE = 'USER_PARTICIPANT';
export const CHANNEL_ID = process.env.REACT_APP_CHANNEL_ID;
export const BACK_BUTTON_DIALOG = {
  HEADER: 'Exit account setup?',
  BODY: 'Note: Any details you enter on this screen will not be saved. <br/> You can <b>log back in </b>at any point to resume your account setup.',
  OK_BUTTON: 'Exit account setup',
  CANCEL_BUTTON: 'Cancel',
};

export const BACK_BUTTON_REGISTER_FIRST_PAGE = {
  HEADER: 'Exit registration?',
  BODY: 'If you choose to exit registration, any details you have entered on this screen will not be saved.',
  OK_BUTTON: 'Exit registration',
  CANCEL_BUTTON: 'Cancel',
};

export const BACK_BUTTON_ACCOUNT_SETUP_FIRST_PAGE = {
  HEADER: 'Exit account setup?',
  BODY: 'You can <b>log back in </b>at any point to resume your account setup.',
  OK_BUTTON: 'Exit account setup',
  CANCEL_BUTTON: 'Cancel',
};

export const BACK_BUTTON_ACCOUNT_SETUP = {
  HEADER: 'Leave the page?',
  BODY: 'If you leave this page, any details you enter on this screen will not be saved.',
  OK_BUTTON: 'Leave page',
  CANCEL_BUTTON: 'Cancel',
};

export const LOGO_DIALOG = {
  HEADER: 'Exit account setup?',
  BODY: 'Note: Any details you enter on this screen will not be saved. <br/> You can <b>log back in </b>at any point to resume your account setup.',
  OK_BUTTON: 'Exit account setup',
  CANCEL_BUTTON: 'Cancel',
};

export const CURRENT_PROCESS = {
  LOGGED_IN: 'LOGGED_IN',
  REGISTRATION: 'REGISTRATION',
  SIGNIN: 'SIGN_IN',
  REGISTRATION_FIRST_PAGE: 'REGISTRATION_FIRST_PAGE',
  ACCOUNT_SETUP: 'ACCOUNT_SETUP',
  ACCOUNT_SETUP_FIRST_PAGE: 'ACCOUNT_SETUP_FIRST_PAGE',
  RENEWAL_PAYMENT_FAIL: 'RENEWAL_PAYMENT_FAIL',
};

export const WRKR_SMSF_ADDRESS = 'https://wrkr.com.au/smsf-compliance/';

export const MESSAGE = {
  PASSWORD_HINT:
    'Use 8 or more characters with a mix of upper and lower case letters, numbers and symbols (excluding spaces)',
};
