const SideMenuLoader = () => {
  return (
    <div className="loader__placeholder">
      <nav>
        <div className="sidebar__smsf-name">
          <span className="placeholder"></span>
        </div>
        <ul className="sidebar__links">
          <li>
            <a>
              <span className="placeholder"></span>
            </a>
          </li>
          <li>
            <a>
              <span className="placeholder"></span>
            </a>
          </li>
          <li>
            <a>
              <span className="placeholder"></span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideMenuLoader;
