/**
 * validate TFN
 * @param {string} tfn
 * @return {boolean} is TFN valid
 *
 * See https://developer.sbr.gov.au/collaborate/x/OACQAg for more details
 * 0. TFN must be 8 or 9 digits long
 * 1. Multiply each of the digits in TFN by a "weighting factor" based on its position
 * 2. Sum the resulting 8 or 9 products
 * 3. Divide the sum total by 11, noting the remainder
 * 4. If the remainder is zero the number is valid
 */
const validateTFN = (tfn) => {
  let isValid = true;

  // Remove all spaces
  tfn = tfn.replace(/\s/g, '');

  // Rule: 0
  if (tfn.length !== 8 && tfn.length !== 9) {
    isValid = false;
  }

  // Rule: 1, 2
  if (isValid) {
    let weightedSum = 0;
    const weightFor8Digits = [10, 7, 8, 4, 6, 3, 5, 1];
    const weightFor9Digits = [1, 4, 3, 7, 5, 8, 6, 9, 10];
    let weight = tfn.length === 8 ? weightFor8Digits : weightFor9Digits;

    weight.forEach((element, index) => {
      weightedSum += parseInt(tfn[index], 10) * element;
    });

    // Rule: 3, 4
    isValid = weightedSum % 11 === 0;
  }

  return isValid;
};

export default validateTFN;
