import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const Settings = () => {
  return (
    <div className="Settings">
      <div className="banner">
        <Breadcrumbs />

        <h1>Settings</h1>
      </div>
      <div className="content">asdf</div>
    </div>
  );
};

export default Settings;
