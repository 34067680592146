import { ReactComponent as CheckCircleFill } from 'bootstrap-icons/icons/check-circle-fill.svg';
import { ReactComponent as ExclamationCircleFill } from 'bootstrap-icons/icons/exclamation-circle-fill.svg';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface InputValidationProps {
  type: 'alert' | 'success' | 'warning' | 'alert';
  children: ReactNode;
}

const InputValidation = ({
  type = 'alert',
  children,
}: InputValidationProps) => {
  return (
    <div className="input-validation">
      <div className="input-validation__icon">
        {type === 'success' && <CheckCircleFill className="is-success" />}
        {type === 'warning' && <ExclamationCircleFill className="is-pending" />}
        {type === 'alert' && <ExclamationCircleFill className="is-alert" />}
      </div>
      <div
        className={classNames({
          'input-validation__success': type === 'success',
          'p--sm': type !== 'success',
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default InputValidation;
