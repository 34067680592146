import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg';
import { SIGN_IN, TWO_FACTOR_SET_UP } from 'constants/registration';
import { mapCustomError } from 'utils/mapServiceCallErrors';
import { AWSService } from 'services/AWSService/AWSService';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { OTP } from 'components/OTP/OTP';
import { ErrorCodeMessage } from 'components/ErrorCodeMessage/ErrorCodeMessage';
import useNavigationModal from 'hooks/useNavigationModal';
import { defaultBackButtonModal } from 'constants/navigationModals';

export const VerifyEmail = () => {
  useNavigationModal(defaultBackButtonModal);
  const [otp, setOtp] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [invalidCode, setInvalidCode] = useState('');
  const [showResend, setShowResend] = useState(false);
  const { user, userStatus, setUser, tempPwd } = useRegistration();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const resendConfirmationCode = async () => {
    try {
      setIsLoading(true);
      await AWSService.resendSignUp(user.username);
      setInvalidCode('');
      setOtp('');
    } catch (err) {
      console.log('error resending code: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setInvalidCode('');
      await AWSService.verifyEmail(user.username, otp);
      if (userStatus.isUserConfirmEmailLater) {
        const refreshUser = await AWSService.signIn(user.username, tempPwd);
        setUser(refreshUser);
      }
      history.push(TWO_FACTOR_SET_UP.PATH);
    } catch (error) {
      setInvalidCode(mapCustomError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (otp) => {
    setSubmitEnabled(otp.length === 6);
    setOtp(otp);
  };

  useEffect(() => {
    if (!user) {
      history.push(SIGN_IN.PATH);
    }
    if (userStatus.isUserConfirmEmailLater)
      AWSService.resendSignUp(user.username);
  }, []);

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <LoginContainer>
          <div>
            <form>
              <h1>Check your email</h1>
              <p style={{ wordBreak: 'break-all' }}>
                Please enter the code sent to "
                <strong data-testid="onscreen-email">{user?.username}"</strong>
              </p>
              <div className="pin-group">
                <OTP
                  otp={otp}
                  handleChange={handleChange}
                  invalidCode={invalidCode}
                />
              </div>
              <ErrorCodeMessage
                invalidCode={invalidCode}
                errClassName={'error d-block pb-4'}
              />
              <button
                className="button button--lg button--primary button--full-width mb-8 mt-2"
                type="submit"
                disabled={!submitEnabled}
                onClick={onSubmit}
              >
                Continue
              </button>
            </form>
            <div className="mt-4 mb-4">
              <a
                className="collapse-group"
                onClick={() => {
                  setShowResend(!showResend);
                }}
              >
                Didn't receive the email?
                {showResend ? (
                  <CaretUpFill className="button--icon-after__icon" />
                ) : (
                  <CaretDownFill className="button--icon-after__icon" />
                )}
              </a>
            </div>
            {showResend && (
              <div>
                <p>Remember to check your spam folder.</p>
                <button
                  className="button button--outline button--xs"
                  onClick={resendConfirmationCode}
                >
                  Resend email
                </button>

                <div className="mt-4">
                  <p className="p--sm">
                    If the above email is wrong, you'll need to{' '}
                    <a
                      onClick={() => {
                        history.go(0);
                      }}
                    >
                      return and start again.
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </LoginContainer>
      </div>
    </div>
  );
};
