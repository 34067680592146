import { useAuthContext } from 'contexts/authContext';
import Spinner from './components/Spinner/Spinner';
import { TOKEN_DISABLED } from 'utils/configs';
import ProtectedHeader from 'components/Header/ProtectedHeader';
import { ParticipantProvider } from 'contexts/participantContext';
import { RegistrationProvider } from 'contexts/registrationContext';
import { ProtectedRoutesSwitch, PublicRoutesSwitch } from 'routes/index';
import Banner from 'pages/Payment/Banner';
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";

const AuthenticatedApp = () => {
  return (
    <ParticipantProvider>
      <Banner />
      <TermsAndConditions />
      <div className="page">
        <ProtectedHeader />
        <ProtectedRoutesSwitch />
      </div>
    </ParticipantProvider>
  );
};

const PublicApp = () => {
  return (
    <RegistrationProvider>
      <PublicRoutesSwitch />
    </RegistrationProvider>
  );
};

export const App = () => {
  const { authUser, isAuthorised, isLoading } = useAuthContext();
  const isAuthenticated =
    TOKEN_DISABLED || (!TOKEN_DISABLED && authUser && isAuthorised);

  if (isLoading) {
    return <Spinner />;
  }

  return <>{isAuthenticated ? <AuthenticatedApp /> : <PublicApp />}</>;
};
