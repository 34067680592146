import { TextField } from '../TextField/TextField';
import Eye from 'components/Eye/Eye';
import { useState } from 'react';

interface PasswordFieldProps {
  className?: string | undefined;
  onClick?: () => void;
  isInvalid?: boolean | false;
  label: string;
  name: string;
  props: any;
}

export const PasswordField = ({
  className,
  onClick,
  isInvalid,
  label,
  name,
  ...props
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      label={label}
      name={name}
      id={name}
      type={showPassword ? 'text' : 'password'}
      icon={<Eye show={showPassword} onClick={setShowPassword} />}
      className={className}
      isInvalid={isInvalid}
      {...props}
    />
  );
};
