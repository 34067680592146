import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';

export const FormSelect = ({ className = '', ...props }) => {
  return (
    <Select
      // menuPortalTarget={document.querySelector(".page")}
      className={classNames('form-select-container', className)}
      classNamePrefix="form-select"
      placeholder="Please select..."
      {...props}
    />
  );
};

export const AsyncFormSelect = ({ className, ...props }) => {
  return (
    <AsyncSelect
      // menuPortalTarget={document.querySelector(".page")}
      className={classNames('form-select-container', className)}
      classNamePrefix="form-select"
      placeholder="Please select..."
      {...props}
    />
  );
};
