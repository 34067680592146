import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Alert from 'components/Alert/Alert';
import BackLink from 'components/BackLink/BackLink';
import NextLink from 'components/NextLink/NextLink';
import HttpClient from 'utils/HttpClient';
import { convertToAuFllTimeFormat } from 'utils/format';
import useQuery from 'hooks/useQuery';

const TransactionDetails = () => {
  document.title = 'Transaction Details';
  const { state } = useLocation();
  const { guid, messageTransactionId } = useParams();
  const previousPath = state?.from;
  const [transaction, setTransaction] = useState(null);
  const action = useQuery().get('action');
  const [hasPaymentDetails, setHasPaymentDetails] = useState(false);

  useEffect(() => {
    const getTransactionDetails = async (messageTransactionId) => {
      try {
        const response = await HttpClient.get(
          '/smsf-hub/rollover/details/action-details',
          {
            params: {
              messageTransactionId,
              action: action,
            },
          },
        );
        const { data } = response;
        setHasPaymentDetails(data?.financialInstitutionAccount != null);
        setTransaction(data);
      } catch (error) {
        console.log(error);
      }
    };

    getTransactionDetails(messageTransactionId);
  }, [setTransaction, messageTransactionId, action]);

  return (
    <div className="TransactionDetails">
      <div className="banner">
        {previousPath != null ? <BackLink to={previousPath} /> : null}
      </div>
      <div className="content">
        <section className="mb-8">
          {/* Only display the alert when it displays after submission but not redirected from rollover details page */}
          {action === 'ROLLOVER_TRANSACTION_REQUEST' && (
            <Alert
              variation="info"
              title="Your roll out has been submitted and you must now make payment to the bank account shown below"
            >
              <p>
                Please <strong>make payment today</strong> to ensure that it is
                received by the fund within three business days. Make sure to
                also include the <strong>Payment Reference Number (PRN)</strong>{' '}
                to avoid delays in processing.
              </p>
            </Alert>
          )}
          {action === 'INITIATE_ROLLOVER_REQUEST' &&
            transaction?.transferringFundName != null && (
              <Alert
                variation="success"
                title="Your request to initiate a roll in has been submitted"
              >
                <p>
                  Expect a response from{' '}
                  <strong>{transaction?.transferringFundName}</strong> within
                  three business days.
                </p>
              </Alert>
            )}
        </section>

        {hasPaymentDetails ? (
          <section className="mb-8 text-align-center">
            <h1>Payment Details</h1>
            <div className="receipt mt-8">
              <div className="receipt__highlight">
                <p className="h3">Total amount due</p>
                <p className="h2 mb-0">{transaction?.rolloverAmount}</p>
              </div>
              <dl>
                <div>
                  <dt>BSB</dt>
                  <dd>
                    {transaction?.financialInstitutionAccount?.bankBranchNumber}
                  </dd>
                </div>
                <div>
                  <dt>Account no.</dt>
                  <dd>
                    {transaction?.financialInstitutionAccount?.accountNumber}
                  </dd>
                </div>
                <div>
                  <dt>Account name</dt>
                  <dd>
                    {transaction?.financialInstitutionAccount?.accountName}
                  </dd>
                </div>
                <div>
                  <dt>Payment Ref No (PRN)</dt>
                  <dd>{transaction?.paymentReferenceNumber}</dd>
                </div>
              </dl>
            </div>
          </section>
        ) : null}

        <section className="text-align-center">
          {hasPaymentDetails ? (
            <h2>Transaction Details</h2>
          ) : (
            <h1 className="h2">Transaction Details</h1>
          )}
          {transaction != null && (
            <div className="receipt mt-8">
              <dl>
                <div>
                  <dt>Transaction ID</dt>
                  <dd>{transaction.messageTransactionId}</dd>
                </div>
                <div>
                  <dt>Submitted</dt>
                  <dd>{convertToAuFllTimeFormat(transaction.submittedTime)}</dd>
                </div>
                <div>
                  <dt>Transferring fund</dt>
                  <dd>{transaction.transferringFundName}</dd>
                </div>
                <div>
                  <dt>Receiving fund</dt>
                  <dd>{transaction.receivingFundName}</dd>
                </div>
                <div>
                  <dt>Roll in amount</dt>
                  <dd>
                    {transaction.rolloverAmount !== '$0.00'
                      ? transaction.rolloverAmount
                      : ''}
                    {transaction.memberDetails[0].transferWholeBalanceIndicator
                      ? ` (Whole balance)`
                      : ` (Partial benefit)`}
                  </dd>
                </div>
                <div>
                  <dt>Member</dt>
                  <dd>
                    {transaction.memberDetails
                      .map((member) => member.personName)
                      .join(', ')}
                  </dd>
                </div>
              </dl>
            </div>
          )}
        </section>

        <div className="my-6 mx-auto text-align-center">
          <NextLink to={`/smsfs/${guid}/rollovers`}>
            Return to Rollovers
          </NextLink>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
