import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NextButton from 'components/NextButton/NextButton';
import {
  ACCOUNT_SETUP_PATHNAMES,
  SMSF_DETAILS,
} from 'constants/accountSetupPathnames';
import { isAccountSetupStepDone } from 'utils/isAccountSetupStepDone';
import { AccountService } from 'services';
import { useTrusteeAccount } from 'contexts/trusteeAccountContext';
import useNavigationModal from 'hooks/useNavigationModal';
import { accountSetupFirstPageBackButtonModal } from 'constants/navigationModals';

const StartStep = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { trusteeAccount, setTrusteeAccount } = useTrusteeAccount();
  const { participantDetails } = trusteeAccount;

  const onNextPageHandler = async () => {
    setIsLoading(true);
    if (
      isAccountSetupStepDone(
        trusteeAccount.participantDetails.accountSetupStep,
        SMSF_DETAILS,
      )
    ) {
      const { data } = await AccountService.updateAccountSetupStep(
        SMSF_DETAILS,
      );
      setTrusteeAccount(data);
    }

    history.push(ACCOUNT_SETUP_PATHNAMES.SMSF_DETAILS);
    setIsLoading(false);
  };
  useNavigationModal(accountSetupFirstPageBackButtonModal);
  return (
    <>
      <div className="account-setup__container">
        {!isLoading && (
          <>
            <h1>Start SMSF account setup</h1>

            <div className="account-setup__section">
              <h2>You must be an authorised trustee of this SMSF account</h2>
              <p>
                This account must be set up by a trustee with formal authority
                to act on behalf of the superannuation fund for the Services
                provided by the Wrkr SMSF Hub.
              </p>
              <div className="well">
                <p>
                  <strong>
                    Your trustee details associated with this SMSF
                  </strong>
                </p>
                <dl>
                  <div aria-labelledby="full_name">
                    <dt>Full name</dt>
                    <dd>{`${participantDetails?.participantContact?.firstName} ${participantDetails?.participantContact?.familyName}`}</dd>
                  </div>
                  <div aria-labelledby="email">
                    <dt>Email</dt>
                    <dd style={{ wordBreak: 'break-all' }}>
                      {participantDetails?.participantContact?.email}
                    </dd>
                  </div>
                  <div aria-labelledby="mobile">
                    <dt>Mobile</dt>
                    <dd>
                      {participantDetails?.participantContact?.phoneNumber}
                    </dd>
                  </div>
                </dl>

                <hr />
                <p className="p--sm">
                  If any of the above details are incorrect, please contact{' '}
                  <br />
                  <a href="mailto:support@wrkr.com.au" className="link">
                    support@wrkr.com.au
                  </a>
                </p>
              </div>
              <p className="mt-4">
                <em>
                  If you are a tax agent, accountant or financial advisor
                  authorised to manage and lodge requests of behalf of SMSFs,{' '}
                  <a href="https://wrkr.com.au/smsf-form/" className="link">
                    contact us here
                  </a>
                  .
                </em>
              </p>
            </div>
            <div className="button-group mt-6">
              <NextButton onClick={onNextPageHandler}>Next</NextButton>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StartStep;
