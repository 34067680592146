import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { Form, Formik, setNestedObjectValues } from 'formik';
import * as Yup from 'yup';
import validateABN from 'utils/validateABN';
import validateTFN from 'utils/validateTFN';
import FormGroup from 'components/FormGroup/FormGroup';
import {
  AUSTRALIAN_STATES_AND_TERRITORIES,
  COUNTRY_CODES,
  PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES,
  TRUSTEE_STRUCTURES,
  CORPORATE_TRUSTEE,
} from 'utils/configs';
import AddressFormGroup from 'components/AddressFormGroup/AddressFormGroup';
import AddNewSMSFButton from './AddNewSMSFButton';
import FormCheckBox from 'components/FormCheckBox/FormCheckbox';
import ValidationError from 'components/ValidationError/ValidationError';
import InputValidation from 'components/InputValidation/InputValidation';
import ComplyingCodeMessages from 'utils/complyingCodeMessages';
import DayInput from 'components/DayInput/DayInput';
import { convertToIsoDateFormat, formatAddress } from 'utils/format';
import { getMemberLabel } from 'utils/labels';
import { mapError } from 'utils/mapServiceCallErrors';
import { AccountService, SMSFService } from 'services';
import { useParticipationContext } from 'contexts/participantContext';

import {
  POSTCODE_REGEX,
  VALID_CHARACTERS_REGEX,
  VALID_MOBILE_NUMBER_LENGTH,
  VALID_MOBILE_NUMBER_REGEX,
  VALID_NUMBER_REGEX,
} from 'constants/validation';
import { MEMBER_AND_CONTACT, CONTACT } from 'constants/participant';
const SexCodes = PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES.map((code) => code.value);
const TrusteeStructures = TRUSTEE_STRUCTURES.map((option) => option.value);
const States = AUSTRALIAN_STATES_AND_TERRITORIES.map((state) => state.value);

const SMSFDataSchema = Yup.object().shape({
  abn: Yup.string()
    .test('is-valid-abn', (value, { createError }) => {
      let message;
      if (!value || value.length === 0) {
        message = 'ABN is required';
      } else if (!/^\d+$/.test(value)) {
        message = 'ABN must contain only numbers';
      } else if (value.length !== 11) {
        message = 'ABN length must be 11 digits';
      } else if (!validateABN(value)) {
        message = 'Invalid ABN';
      }
      return message
        ? createError({
            message,
          })
        : true;
    })
    .required('ABN is required'),
  trusteeStructure: Yup.string()
    .oneOf(
      TrusteeStructures,
      "Trustee structure must be either 'Individual trustee' or 'Corporate trustee'",
    )
    .required('Trustee structure is required'),
  trusteeAcn: Yup.string().when('trusteeStructure', {
    is: CORPORATE_TRUSTEE,
    then: Yup.string()
      .matches(VALID_NUMBER_REGEX, 'ACN must contain only numbers')
      .max(9, 'ACN must contain 9 digits')
      .min(9, 'ACN must contain 9 digits'),
    otherwise: Yup.string(),
  }),
  familyName: Yup.string()
    .max(40, 'Maximum length allowed for last name is 40 characters')
    .matches(
      VALID_CHARACTERS_REGEX,
      'Last name must only contain([0-9a-zA-Z .,?(){}:;\'|-_=\\/@#$%*=&"])',
    )
    .required('Last name is required'),
  firstName: Yup.string()
    .max(40, 'Maximum length allowed for given name is 40 characters')
    .matches(
      VALID_CHARACTERS_REGEX,
      'Given name must only contain([0-9a-zA-Z .,?(){}:;\'|-_=\\/@#$%*=&"])',
    )
    .required('Given name is required'),
  otherNames: Yup.string()
    .max(40, 'Maximum length allowed for other given name is 40 characters')
    .matches(
      VALID_CHARACTERS_REGEX,
      'Other given name must only contain([0-9a-zA-Z .,?(){}:;\'|-_=\\/@#$%*=&"])',
    ),
  email: Yup.string().email('Email is invalid').required('Email is required'),
  mobile: Yup.string()
    .min(VALID_MOBILE_NUMBER_LENGTH, 'Mobile number length must be 10 numbers')
    .max(VALID_MOBILE_NUMBER_LENGTH, 'Mobile number length must be 10 numbers')
    .matches(VALID_MOBILE_NUMBER_REGEX, 'Mobile number is invalid')
    .required('Mobile number is required'),
  isMember: Yup.boolean(),
  dateOfBirth: Yup.string().when('isMember', {
    is: true,
    then: Yup.string()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date')
      .nullable()
      .required('Date of birth is required'),
    otherwise: Yup.string(),
  }),
  gender: Yup.string().when('isMember', {
    is: true,
    then: Yup.string().oneOf(SexCodes).required('Gender is required'),
    otherwise: Yup.string(),
  }),
  taxFileNumber: Yup.string().when('isMember', {
    is: true,
    then: Yup.string().test('is-valid-tfn', (value, { createError }) => {
      let message;
      if (!value || value.length === 0) {
        message = 'Tax file number is required';
      } else if (!/^\d+$/.test(value)) {
        message = 'Tax file number must contain only numbers';
      } else if (value.length !== 8 && value.length !== 9) {
        message = 'Tax file number length must be 8 or 9 digits';
      } else if (!validateTFN(value)) {
        message = 'Invalid tax file number';
      }

      return message
        ? createError({
            message,
          })
        : true;
    }),
    otherwise: Yup.string(),
  }),
  address: Yup.mixed().when('isMember', {
    is: true,
    then: Yup.object().shape({
      line1: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Address is required'),
      suburb: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Suburb is required'),
      state: Yup.string()
        .oneOf(States, 'Please choose an option')
        .required('State / Territory is required'),
      postCode: Yup.string()
        .matches(
          POSTCODE_REGEX,
          'Please enter a valid 4-digit Australian post code',
        )
        .required('This field is required'),
      countryCode: Yup.string()
        .oneOf(COUNTRY_CODES.map((code) => code.value))
        .required('Country is required'),
    }),
    otherwise: Yup.mixed(),
  }),
});

const NewSMSF = () => {
  document.title = 'Add New SMSF';
  const [abnInfo, setAbnInfo] = useState(null);
  const history = useHistory();
  const [submissionError, setSubmissionError] = useState(null);
  const { participant, getParticipant } = useParticipationContext();
  const [isSubmit, setIsSubmit] = useState(false);

  const validateSmsfAbn = async (abn) => {
    try {
      const response = await AccountService.validateSMSFABN(abn);
      const { data } = response;
      if (data.complyingCode === 'Y' || data.complyingCode === 'R') {
        setAbnInfo({
          type: 'success',
          fundName: data.organisationName,
          content: data.organisationName,
        });
      } else {
        setAbnInfo({
          type: 'warning',
          fundName: data.organisationName,
          content: ComplyingCodeMessages[data.complyingCode],
        });
      }
    } catch (error) {
      console.error(error);
      setAbnInfo({
        type: 'alert',
        content:
          error.response?.data?.validationResponse?.validations[0]?.message ||
          'Invalid ABN',
      });
    }
  };

  // A manual validation that runs the validation on the field level and touches all fields
  // so that all errors can display without trigger a real submission
  const handleValidate = async (
    validateForm,
    setTouched,
    setNestedObjectValues,
  ) => {
    setIsSubmit(true);
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      handleShowModal();
    } else {
      setTouched(setNestedObjectValues(errors, true));
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setShowModal(false);
    const data = {
      abn: values.abn,
      fundName: abnInfo.fundName,
      trusteeStructure: values.trusteeStructure,
      termsAndConditionsType: getTermsAndConditionsType(),
      trusteeAcn:
        values.trusteeStructure === CORPORATE_TRUSTEE
          ? values.trusteeAcn
          : null,
      member: {
        gender: values.gender,
        dateOfBirth: convertToIsoDateFormat(values.dateOfBirth),
        taxFileNumber: values.taxFileNumber,
        address: values.isMember
          ? {
              ...values.address,
              addressType: values.address.addressType,
            }
          : null,
        firstName: values.firstName,
        familyName: values.familyName,
        otherNames: values.otherNames,
        email: values.email,
        type: values.isMember ? MEMBER_AND_CONTACT : CONTACT,
        phoneNumbers: [
          {
            type: 'MOBILE',
            number: values.mobile,
          },
        ],
      },
    };
    try {
      let res = await SMSFService.createSMSFFund(data);

      if (participant.participantType === 'TRUSTEE') {
        await updateStatus(res.data.guid);
      }
      await getParticipant();
      history.goBack();
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      setSubmissionError(mapError(error));
    }
  };

  const updateStatus = async (guid) => {
    try {
      await SMSFService.updateStatus(guid, 'TRIAL');
    } catch (error) {
      console.log(error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const getTermsAndConditionsType = () => {
    return participant.participantType === 'TRUSTEE'
      ? 'SMSF_REGISTRATION'
      : 'SMSF_REGISTRATION_WITH_PAYMENT';
  };

  return (
    <main className="main">
      <div className="NewSMSF main__no-sidebar">
        <div className="banner">
          <Breadcrumbs />

          <h1>Add New SMSF</h1>
        </div>
        <div className="content">
          <div className="form">
            <Formik
              initialValues={{
                abn: '',
                trusteeStructure: '',
                trusteeAcn: '',
                familyName: '',
                firstName: '',
                otherNames: '',
                email: '',
                mobile: '',
                isMember: false,
                dateOfBirth: '',
                gender: '',
                taxFileNumber: '',
                address: null,
              }}
              validationSchema={SMSFDataSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                setFieldValue,
                setTouched,
                validateForm,
                handleBlur,
                isSubmitting,
              }) => (
                <Form id="new-smsf-form" className="form">
                  <div
                    className="panel"
                    role="group"
                    aria-labelledby="smsf-details"
                  >
                    <div id="smsf-details" className="h2 mb-6">
                      SMSF details
                    </div>
                    <FormGroup
                      name="abn"
                      label="ABN"
                      onChange={(event) => {
                        setFieldValue(
                          'abn',
                          event.target.value.replace(/(\s|-)/g, ''),
                        );
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        errors.abn != null
                          ? setAbnInfo(null)
                          : validateSmsfAbn(values.abn);
                      }}
                      appendix={
                        abnInfo != null ? (
                          <InputValidation type={abnInfo.type}>
                            {abnInfo.content}
                          </InputValidation>
                        ) : null
                      }
                    />

                    <FormGroup
                      name="trusteeStructure"
                      label="Trustee structure"
                      as="select"
                    >
                      <option>Please select...</option>
                      {TRUSTEE_STRUCTURES.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </FormGroup>

                    {values.trusteeStructure === CORPORATE_TRUSTEE && (
                      <FormGroup
                        name="trusteeAcn"
                        label="ACN"
                        onChange={(event) => {
                          setFieldValue(
                            'trusteeAcn',
                            event.target.value.replace(/(\s|-)/g, ''),
                          );
                        }}
                      />
                    )}
                  </div>

                  <div
                    className="panel"
                    role="group"
                    aria-labelledby="contact-details"
                  >
                    <div>
                      <div id="contact-details" className="h2 mb-6">
                        {getMemberLabel(values.trusteeStructure)} details
                      </div>

                      <FormGroup name="firstName" label="Given name" />
                      <FormGroup
                        name="otherNames"
                        label="Other given name"
                        optional
                      />
                      <FormGroup name="familyName" label="Last name" />
                      <FormGroup name="email" type="email" label="Email" />
                      <FormGroup name="mobile" type="tel" label="Mobile" />
                    </div>

                    <hr />

                    <div className="mt-6">
                      <FormCheckBox
                        id="add-contact-as-member"
                        name="isMember"
                        label={`Add ${getMemberLabel(
                          values.trusteeStructure,
                        )} as a member to this SMSF`}
                      />
                    </div>

                    {values.isMember && (
                      <div
                        className="mt-6"
                        role="group"
                        aria-labelledby="extra-member-details"
                      >
                        <div id="extra-member-details" className="h3 mb-6">
                          Extra member details
                        </div>
                        <FormGroup
                          id="dateOfBirth"
                          name="dateOfBirth"
                          label="Date of birth"
                          placeholder="dd/mm/yyyy"
                          as={DayInput}
                          hasMaxYear={true}
                        />
                        <FormGroup name="gender" label="Gender" as="select">
                          <option>Please select...</option>
                          {PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES.map(
                            ({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ),
                          )}
                        </FormGroup>
                        <FormGroup
                          name="taxFileNumber"
                          label="Tax file number"
                          onChange={(event) => {
                            setFieldValue(
                              'taxFileNumber',
                              event.target.value.replace(/(\s|-)/g, ''),
                            );
                          }}
                        />
                        <AddressFormGroup
                          name="address"
                          label="Address"
                          initialValue={formatAddress(values.address)}
                          addressType={{
                            enable: true,
                            initialValue: 'RESIDENTIAL',
                          }}
                          isSubmit={isSubmit}
                        />
                      </div>
                    )}
                  </div>

                  {submissionError && (
                    <ValidationError
                      validation={submissionError.validationResponse}
                      exception={submissionError.exception}
                    />
                  )}

                  <div className="button-group mt-6">
                    <AddNewSMSFButton
                      showModal={showModal}
                      handleShowModal={handleShowModal}
                      handleCloseModal={handleCloseModal}
                      termsAndConditionsType={getTermsAndConditionsType()}
                      disabled={
                        isSubmitting ||
                        abnInfo == null ||
                        abnInfo.type === 'alert'
                      }
                      handleValidate={() =>
                        handleValidate(
                          validateForm,
                          setTouched,
                          setNestedObjectValues,
                        )
                      }
                      isSubmitting={isSubmitting}
                    />
                    <Link
                      to="/smsfs"
                      className="button button--transparent button--lg"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NewSMSF;
