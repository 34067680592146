import Modal from 'react-modal';
import { Form, Formik } from 'formik';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import FormGroup from '../../components/FormGroup/FormGroup';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as QuestionCircle } from 'bootstrap-icons/icons/question-circle.svg';
import { useState } from 'react';
import RolloverErrorReasonGuideModal from './RolloverErrorReasonGuideModal';
import { VALID_AMOUNT_REGEX } from '../../constants/validation';

const AddRolloverErrorModal = ({
  showModal,
  handleCloseModal,
  errorResponseReferenceData,
  addSendErrorResponseOutcomes,
}) => {
  const RolloverErrorContextIds =
    errorResponseReferenceData?.errorContexts?.map(
      (errorContextDetails) => errorContextDetails.contextId,
    );

  const RolloverErrorReasonCodes = errorResponseReferenceData?.errorCodes?.map(
    (errorCodeDetails) => errorCodeDetails.code,
  );

  const RolloverErrorDataSchema = Yup.object().shape({
    errorContextId: Yup.string()
      .oneOf(RolloverErrorContextIds, 'Error context is required')
      .required('Error context is required'),

    errorReasonCode: Yup.string()
      .oneOf(RolloverErrorReasonCodes, 'Reason is required')
      .required('Reason is required'),

    refundAmount: Yup.string().when('refundApplicable', {
      is: true,
      then: Yup.string()
        .matches(VALID_AMOUNT_REGEX, 'Invalid Refund amount')
        .nullable(),
    }),
  });

  const handleSubmit = async (values) => {
    const { errorContextId, errorReasonCode, refundAmount, comments } = values;
    addSendErrorResponseOutcomes({
      errorContextId,
      errorReasonCode,
      refundAmount,
      comments,
    });
    handleCloseModal();
  };

  const [showReasonGuideModal, setShowReasonGuideModal] = useState(false);
  const handleCloseReasonGuideModal = () => setShowReasonGuideModal(false);
  const handleShowReasonGuideModal = () => setShowReasonGuideModal(true);

  const getHelpButton = () => {
    return errorResponseReferenceData?.errorCodes?.some(
      (errorCodeDetails) => errorCodeDetails.helpInfo?.trim().length > 0,
    ) ? (
      <button
        type="button"
        className="d-inline-flex button--transparent button--help"
        aria-label="Help"
        onClick={handleShowReasonGuideModal}
      >
        <QuestionCircle />
      </button>
    ) : undefined;
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      className="modal modal--lg"
      overlayClassName="modal-overlay"
      contentLabel="Add reason"
      aria={{
        labelledby: 'add-rollover-error-modal',
      }}
      appElement={document.getElementById('root')}
    >
      <div className="modal__header">
        <div className="modal__heading h3" id="add-rollover-error-modal">
          Add reason
        </div>
        <button
          type="button"
          className="d-inline-flex button--transparent button--close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <XLg />
        </button>
      </div>
      <div className="modal__body">
        <div className="form">
          <Formik
            initialValues={{
              errorContextId: '',
              errorReasonCode: '',
              refundAmount: '',
              comments: '',
              refundApplicable: errorResponseReferenceData?.refundApplicable,
            }}
            validationSchema={RolloverErrorDataSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              setTouched,
              validateForm,
              handleBlur,
              isSubmitting,
            }) => (
              <Form id="add-rollover-error-form" className="form">
                <FormGroup
                  name="errorContextId"
                  label="This error applies to"
                  as="select"
                >
                  <option>Please select...</option>
                  {errorResponseReferenceData?.errorContexts?.map(
                    (errorContextDetails) => {
                      return (
                        <option
                          key={errorContextDetails.contextId}
                          value={errorContextDetails.contextId}
                        >
                          {errorContextDetails.contextName}
                        </option>
                      );
                    },
                  )}
                </FormGroup>

                <FormGroup
                  name="errorReasonCode"
                  label="Reason"
                  as="select"
                  helpButton={getHelpButton()}
                >
                  <option>Please select...</option>
                  {errorResponseReferenceData?.errorCodes?.map(
                    (errorCodeDetails) => {
                      return (
                        <option
                          key={errorCodeDetails.code}
                          value={errorCodeDetails.code}
                        >
                          {errorCodeDetails.shortDescription}
                        </option>
                      );
                    },
                  )}
                </FormGroup>

                <RolloverErrorReasonGuideModal
                  showModal={showReasonGuideModal}
                  handleCloseModal={handleCloseReasonGuideModal}
                  errorResponseReferenceData={errorResponseReferenceData}
                />

                {errorResponseReferenceData?.refundApplicable && (
                  <FormGroup
                    name="refundAmount"
                    label="Refund amount (optional)"
                    className="input--currency"
                    autoComplete="off"
                  />
                )}

                <FormGroup
                  name="comments"
                  label="Comments (optional)"
                  component="textarea"
                  rows="2"
                />

                <div className="button-group mt-6">
                  <button type="submit" className="button button--primary">
                    Add reason
                  </button>
                  <Link
                    onClick={handleCloseModal}
                    className="button button--transparent button--lg"
                    to="#"
                  >
                    Cancel
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AddRolloverErrorModal;
