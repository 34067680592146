import { useParticipationContext } from 'contexts/participantContext';
import { useHistory, useLocation } from 'react-router-dom';
import {
  isSubscriptionRenewalInProgress,
  isSubscriptionRenewalPending,
} from '../../utils/paymentSetupUtils';
import { SMSFStatus } from '../../services/SMSFService/types/SMSFService.types';
import Spinner from 'components/Spinner/Spinner';

const Banner = () => {
  const { participant } = useParticipationContext();
  const history = useHistory();
  const location = useLocation();

  if (!participant) {
    return <Spinner />;
  }

  const RenewalPaymentFailedText = () => {
    return (
      <>
        <p>
          You currently have access to view your message data only. Please make
          a payment and renew your subscription if you wish to gain full access
          to the hub.
        </p>
        <p>
          <button
            className="button button--black"
            onClick={() => history.push('/payment-renewal')}
          >
            Activate now
          </button>
        </p>
      </>
    );
  };
  const RenewalPaymentPendingText = () => {
    return (
      <>
        <p>
          You currently have access to view your message data only. Your direct
          debit payment for the SMSFHub renewal subscription is currently being
          processed (1-3 business days).
        </p>
      </>
    );
  };

  type NotificationBarProps = {
    smsfStatus: SMSFStatus;
  };

  const NotificationBar = ({ smsfStatus }: NotificationBarProps) => {
    return (
      <>
        <div className="top-banner">
          {isSubscriptionRenewalPending(smsfStatus) && (
            <RenewalPaymentFailedText />
          )}
          {isSubscriptionRenewalInProgress(smsfStatus) && (
            <RenewalPaymentPendingText />
          )}
        </div>
      </>
    );
  };

  if (location.pathname === '/payment-renewal') {
    return null;
  }

  return (
    <>
      {' '}
      {(isSubscriptionRenewalPending(
        Object.values(participant?.smsfStatuses)[0] as SMSFStatus,
      ) ||
        isSubscriptionRenewalInProgress(
          Object.values(participant?.smsfStatuses)[0] as SMSFStatus,
        )) && (
        <NotificationBar
          smsfStatus={Object.values(participant?.smsfStatuses)[0] as SMSFStatus}
        />
      )}
    </>
  );
};
export default Banner;
