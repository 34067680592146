import { useState } from 'react';
import StartStep from './StartStep';
import SMSFDetailStep from './SMSFDetailStep';
import MemberDetailStep from './MemberDetailStep';
import PaymentSetupStep from './PaymentSetupStep';
import { useLocation, Link } from 'react-router-dom';
import {
  ACCOUNT_SETUP_PATHNAMES,
  ACCOUNT_SETUP_ORDER,
  START,
  SMSF_DETAILS,
  MEMBER_DETAILS,
  PAYMENT_SETUP,
} from 'constants/accountSetupPathnames';
import Spinner from 'components/Spinner/Spinner';
import { TrusteeAccountProvider } from 'contexts/trusteeAccountContext';
import { useParticipationContext } from 'contexts/participantContext';
import { useAuthContext } from 'contexts/authContext';
import { CURRENT_PROCESS } from 'constants/registration';
import {isSubscriptionRenewalPending} from "../../utils/paymentSetupUtils";

const AccountSetup = () => {
  const location = useLocation();
  const [isProcessing] = useState(false);
  const [toGo, setToGo] = useState();
  const { participant } = useParticipationContext();
  const { authState } = useAuthContext();

  const [SMSFStatus] = Object.values(participant?.smsfStatuses ?? 'undefined');

  const getLinkStatus = (step) => {
    if (participant && location.pathname === ACCOUNT_SETUP_PATHNAMES[step]) {
      return 'active';
    }

    return isLinkDisabled(step) ? 'disabled' : 'complete';
  };

  const isLinkDisabled = (step) => {
    return (
      ACCOUNT_SETUP_ORDER[step] >
      ACCOUNT_SETUP_ORDER[participant?.accountSetupStep]
    );
  };

  const getLink = (step) => {
    return isLinkDisabled(step) ? '#' : ACCOUNT_SETUP_PATHNAMES[step];
  };

  const paths = {
    [ACCOUNT_SETUP_PATHNAMES.START]: <StartStep />,
    [ACCOUNT_SETUP_PATHNAMES.SMSF_DETAILS]: (
      <SMSFDetailStep toGo={toGo} setToGo={setToGo} />
    ),
    [ACCOUNT_SETUP_PATHNAMES.MEMBER_DETAILS]: (
      <MemberDetailStep toGo={toGo} setToGo={setToGo} />
    ),
    [ACCOUNT_SETUP_PATHNAMES.PAYMENT_SETUP]: <PaymentSetupStep />,
  };
  if (authState === CURRENT_PROCESS.SIGNIN) {
    return null;
  }

  if (isSubscriptionRenewalPending(SMSFStatus)) {
    return (
      <>
        <TrusteeAccountProvider>
          <main className="main">
            <div role="navigation">
              <div className="account-setup">
                {isProcessing ? (
                  <Spinner />
                ) : (
                  paths[ACCOUNT_SETUP_PATHNAMES.PAYMENT_SETUP]
                )}
              </div>
            </div>
          </main>
        </TrusteeAccountProvider>
      </>
    );
  }

  return (
    <>
      <TrusteeAccountProvider>
        <main className="main">
          <div role="navigation">
            <div className="wizard">
              <div className="wizard__group">
                <p className="subhead1 mb-0">Account setup</p>
                <ul>
                  <li className={getLinkStatus(START)}>
                    <Link
                      to={getLink(START)}
                      onClick={() => setToGo(getLink(START))}
                    >
                      Start
                    </Link>
                  </li>
                  <li className={getLinkStatus(SMSF_DETAILS)}>
                    <Link
                      to={getLink(SMSF_DETAILS)}
                      onClick={() => setToGo(getLink(SMSF_DETAILS))}
                    >
                      SMSF details
                    </Link>
                  </li>
                  <li className={getLinkStatus(MEMBER_DETAILS)}>
                    <Link
                      to={getLink(MEMBER_DETAILS)}
                      onClick={() => setToGo(getLink(MEMBER_DETAILS))}
                    >
                      Member details
                    </Link>
                  </li>
                  <li className={getLinkStatus(PAYMENT_SETUP)}>
                    <Link
                      to={getLink(PAYMENT_SETUP)}
                      onClick={() => setToGo(getLink(PAYMENT_SETUP))}
                    >
                      Payment setup
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <Breadcrumbs spliter={" > "} /> */}
            </div>
            <div className="account-setup">
              {isProcessing ? <Spinner /> : paths[location.pathname]}
            </div>
          </div>
        </main>
      </TrusteeAccountProvider>
    </>
  );
};

export default AccountSetup;
