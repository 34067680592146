import _ from 'lodash';

export const getChangedValues = (values: Object, initialValues: Object) => {
  const changedValues = Object.entries(values).reduce(
    (acc: Object, [key, value]) => {
      const hasChanged = initialValues[key as keyof Object] !== value;

      if (hasChanged) {
        acc[key as keyof Object] = value;
      }

      return acc;
    },
    {},
  );

  const hasChanged = !_.isEqual(changedValues, {});
  return { hasChanged, changedValues };
};
