import Rollovers from '../Rollovers/Rollovers';
import Contributions from '../Contributions/Contributions';
import { showAlertOutSideSMSF } from 'utils/accountSetupUtils';
import AccountCompleteInfo from 'components/AccountCompleteInfo/AccountCompleteInfo';
import { useEffect, useState } from 'react';
import { showGlobalAlerts } from 'utils/globalActionsUtils';
import HubGlobalAlerts from 'components/Alert/HubGlobalAlerts';
import { ReactComponent as Rollover } from 'assets/images/icons/icon_arrow-2way.svg';
import { ReactComponent as Contribution } from 'assets/images/icons/icon_cash-payment-coin.svg';
import { useParticipationContext } from 'contexts/participantContext';
import { CONTRIBUTION_CSV_SUMMARY_REPORT } from '../../constants/reports';
import { ReportFilterModal } from '../../components/ReportFilterModal/ReportFilterModal';
import DownloadButton from '../../components/DownloadButton/DownloadButton';

const Dashboard = ({ disableDownload, setDisableDownload }) => {
  document.title = 'Dashboard';
  const { participant } = useParticipationContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!!participant) {
      setIsLoading(false);
    }
  }, [participant]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return isLoading ? null : (
    <>
      <ReportFilterModal
        isOpen={showModal}
        reportDetails={CONTRIBUTION_CSV_SUMMARY_REPORT}
        handleCloseModal={handleCloseModal}
      />
      <main className="main">
        <div className="dashboard main__no-sidebar">
          {showGlobalAlerts(participant) && (
            <HubGlobalAlerts participant={participant} />
          )}
          {showAlertOutSideSMSF(participant) && (
            <AccountCompleteInfo
              status={
                participant?.smsfStatuses[participant?.guidOfPendingActionSmsf]
              }
              smsfGuid={participant?.guidOfPendingActionSmsf}
            />
          )}
          <article>
            <div className="banner">
              <h1>
                <Rollover /> Recent rollovers
              </h1>
            </div>
            <Rollovers isDashboard={true} />
          </article>
          <article>
            <div className="banner">
              <div className="d-flex align-items-center justify-content-between">
                <h1>
                  <Contribution /> Recent contributions
                </h1>
                <DownloadButton
                  label={CONTRIBUTION_CSV_SUMMARY_REPORT.buttonCaption}
                  setShowModal={() => setShowModal(true)}
                  disabled={disableDownload}
                />
              </div>
            </div>
            <Contributions
              isDashboard={true}
              setDisableDownload={setDisableDownload}
            />
          </article>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
