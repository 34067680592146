import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';

export const isInFuture = (date: string) : boolean => {
  return date ? isAfter(parseISO(date), new Date()) : true;
};

export const isDateFormatValid = (date: string) : boolean => {
  return isNaN(new Date(date).getTime());
};

export const convertToISO = (date: string) : string  => {
  return new Date(date).toISOString();
};