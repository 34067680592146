import { useContext, createContext, useState } from 'react';
import { useSessionStorage } from 'hooks/useSessionStorage';

const RegistrationContext = createContext();
RegistrationContext.displayName = 'RegistrationContext';

export const RegistrationProvider = (props) => {
  const [user, setUser] = useState();
  const [userStatus, setUserStatus] = useSessionStorage('userStatus', {
    isUserFirstSetupMfa: true,
    isUserConfirmEmailLater: false,
  });
  const [tempPwd, setTempPwd] = useState('default', '');

  const [info, setInfo] = useState({
    heading: '',
    body: '',
    buttonText: '',
    buttonHref: '',
  });

  const value = {
    user,
    setUser,
    userStatus,
    setUserStatus,
    info,
    setInfo,
    tempPwd,
    setTempPwd,
  };

  return <RegistrationContext.Provider value={value} {...props} />;
};

export const useRegistration = () => {
  const context = useContext(RegistrationContext);
  if (context === undefined) {
    throw new Error(
      `useRegistration must be used within a RegistrationProvider`,
    );
  }
  return context;
};
