const updateToken = (token) => {
  // const decoded = jwt_decode(token);
  // const { exp, apiHostname } = decoded;
  //
  // console.log(JSON.stringify(decoded, null, 2));

  // Save token information into localStorage
  window.localStorage.setItem('access_token', 'token');
  // window.localStorage.setItem("expires_at", exp * 1000);
  window.localStorage.setItem('api_hostname', ''); // TODO use 'apiHostname'
  window.localStorage.setItem('userName', 'stpUITestUser');
};

export { updateToken };
