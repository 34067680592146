const { REACT_APP_API_HOST } = process.env;

const {
  REACT_APP_AUTH_DISABLED,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_AUTH_USER_POOL_ID,
  REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENTID,
} = process.env;

export const TOKEN_DISABLED = REACT_APP_AUTH_DISABLED === 'true';

export const API_HOST = REACT_APP_API_HOST;

export const AUTH_CONFIG = {
  region: REACT_APP_AWS_REGION,
  userPoolId: REACT_APP_AWS_AUTH_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENTID,
};

export const SERVICE_PROVIDER = 'Wrkr';

export const DEFAULT_PAGE_SIZE = 10;
export const DASHBOARD_PAGE_SIZE = 5;

export const ALLOWED_PAGE_SIZES = [10, 20, 50, 100];

export const TRUSTEE_STRUCTURE = {
  INDIVIDUAL_TRUSTEE: 'Individual',
  CORPORATE_TRUSTEE: 'Corporate',
};

export const STATUS_COLORS = [
  '#09c3b7',
  '#2684ff',
  '#796eff',
  '#6cc40b',
  '#5e5e63',
];

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const AU_NUMERIC_DATE_FORMAT = 'dd/MM/yyyy';
export const AU_FULL_DATE_FORMAT = 'd MMM yyyy';
export const AU_FULL_TIME_FORMAT = `${AU_FULL_DATE_FORMAT}, hh:mm a`;

export const INITIAL_ADDRESS_VALUE = {
  line1: '',
  suburb: '',
  state: '',
  postCode: '',
  countryCode: 'au',
};

export const AUSTRALIAN_STATES_AND_TERRITORIES = [
  {
    value: 'ACT',
    label: 'Australian Capital Territory',
  },
  {
    value: 'NSW',
    label: 'New South Wales',
  },
  {
    value: 'NT',
    label: 'Northern Territory',
  },
  {
    value: 'QLD',
    label: 'Queensland',
  },
  {
    value: 'SA',
    label: 'South Australia',
  },
  {
    value: 'TAS',
    label: 'Tasmania',
  },
  {
    value: 'VIC',
    label: 'Victoria',
  },
  {
    value: 'WA',
    label: 'Western Australia',
  },
];

// ISO 3166-1 alpha-2
export const COUNTRY_CODES = [
  { value: 'ad', label: 'Andorra' },
  { value: 'ae', label: 'United Arab Emirates' },
  { value: 'af', label: 'Afghanistan' },
  { value: 'ag', label: 'Antigua and Barbuda' },
  { value: 'ai', label: 'Anguilla' },
  { value: 'al', label: 'Albania' },
  { value: 'am', label: 'Armenia' },
  { value: 'ao', label: 'Angola' },
  { value: 'aq', label: 'Antarctica' },
  { value: 'ar', label: 'Argentina' },
  { value: 'as', label: 'American Samoa' },
  { value: 'at', label: 'Austria' },
  { value: 'au', label: 'Australia' },
  { value: 'aw', label: 'Aruba' },
  { value: 'ax', label: 'Åland Islands' },
  { value: 'az', label: 'Azerbaijan' },
  { value: 'ba', label: 'Bosnia and Herzegovina' },
  { value: 'bb', label: 'Barbados' },
  { value: 'bd', label: 'Bangladesh' },
  { value: 'be', label: 'Belgium' },
  { value: 'bf', label: 'Burkina Faso' },
  { value: 'bg', label: 'Bulgaria' },
  { value: 'bh', label: 'Bahrain' },
  { value: 'bi', label: 'Burundi' },
  { value: 'bj', label: 'Benin' },
  { value: 'bl', label: 'Saint Barthélemy' },
  { value: 'bm', label: 'Bermuda' },
  { value: 'bn', label: 'Brunei Darussalam' },
  { value: 'bo', label: 'Bolivia, Plurinational State of' },
  { value: 'bq', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'br', label: 'Brazil' },
  { value: 'bs', label: 'Bahamas' },
  { value: 'bt', label: 'Bhutan' },
  { value: 'bv', label: 'Bouvet Island' },
  { value: 'bw', label: 'Botswana' },
  { value: 'by', label: 'Belarus' },
  { value: 'bz', label: 'Belize' },
  { value: 'ca', label: 'Canada' },
  { value: 'cc', label: 'Cocos (Keeling) Islands' },
  { value: 'cd', label: 'Congo, Democratic Republic of the' },
  { value: 'cf', label: 'Central African Republic' },
  { value: 'cg', label: 'Congo' },
  { value: 'ch', label: 'Switzerland' },
  { value: 'ci', label: "Côte d'Ivoire" },
  { value: 'ck', label: 'Cook Islands' },
  { value: 'cl', label: 'Chile' },
  { value: 'cm', label: 'Cameroon' },
  { value: 'cn', label: 'China' },
  { value: 'co', label: 'Colombia' },
  { value: 'cr', label: 'Costa Rica' },
  { value: 'cu', label: 'Cuba' },
  { value: 'cv', label: 'Cabo Verde' },
  { value: 'cw', label: 'Curaçao' },
  { value: 'cx', label: 'Christmas Island' },
  { value: 'cy', label: 'Cyprus' },
  { value: 'cz', label: 'Czechia' },
  { value: 'de', label: 'Germany' },
  { value: 'dj', label: 'Djibouti' },
  { value: 'dk', label: 'Denmark' },
  { value: 'dm', label: 'Dominica' },
  { value: 'do', label: 'Dominican Republic' },
  { value: 'dz', label: 'Algeria' },
  { value: 'ec', label: 'Ecuador' },
  { value: 'ee', label: 'Estonia' },
  { value: 'eg', label: 'Egypt' },
  { value: 'eh', label: 'Western Sahara' },
  { value: 'er', label: 'Eritrea' },
  { value: 'es', label: 'Spain' },
  { value: 'et', label: 'Ethiopia' },
  { value: 'fi', label: 'Finland' },
  { value: 'fj', label: 'Fiji' },
  { value: 'fk', label: 'Falkland Islands (Malvinas)' },
  { value: 'fm', label: 'Micronesia, Federated States of' },
  { value: 'fo', label: 'Faroe Islands' },
  { value: 'fr', label: 'France' },
  { value: 'ga', label: 'Gabon' },
  {
    value: 'gb',
    label: 'United Kingdom of Great Britain and Northern Ireland',
  },
  { value: 'gd', label: 'Grenada' },
  { value: 'ge', label: 'Georgia' },
  { value: 'gf', label: 'French Guiana' },
  { value: 'gg', label: 'Guernsey' },
  { value: 'gh', label: 'Ghana' },
  { value: 'gi', label: 'Gibraltar' },
  { value: 'gl', label: 'Greenland' },
  { value: 'gm', label: 'Gambia' },
  { value: 'gn', label: 'Guinea' },
  { value: 'gp', label: 'Guadeloupe' },
  { value: 'gq', label: 'Equatorial Guinea' },
  { value: 'gr', label: 'Greece' },
  { value: 'gs', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'gt', label: 'Guatemala' },
  { value: 'gu', label: 'Guam' },
  { value: 'gw', label: 'Guinea-Bissau' },
  { value: 'gy', label: 'Guyana' },
  { value: 'hk', label: 'Hong Kong' },
  { value: 'hm', label: 'Heard Island and McDonald Islands' },
  { value: 'hn', label: 'Honduras' },
  { value: 'hr', label: 'Croatia' },
  { value: 'ht', label: 'Haiti' },
  { value: 'hu', label: 'Hungary' },
  { value: 'id', label: 'Indonesia' },
  { value: 'ie', label: 'Ireland' },
  { value: 'il', label: 'Israel' },
  { value: 'im', label: 'Isle of Man' },
  { value: 'in', label: 'India' },
  { value: 'io', label: 'British Indian Ocean Territory' },
  { value: 'iq', label: 'Iraq' },
  { value: 'ir', label: 'Iran, Islamic Republic of' },
  { value: 'is', label: 'Iceland' },
  { value: 'it', label: 'Italy' },
  { value: 'je', label: 'Jersey' },
  { value: 'jm', label: 'Jamaica' },
  { value: 'jo', label: 'Jordan' },
  { value: 'jp', label: 'Japan' },
  { value: 'ke', label: 'Kenya' },
  { value: 'kg', label: 'Kyrgyzstan' },
  { value: 'kh', label: 'Cambodia' },
  { value: 'ki', label: 'Kiribati' },
  { value: 'km', label: 'Comoros' },
  { value: 'kn', label: 'Saint Kitts and Nevis' },
  { value: 'kp', label: "Korea, Democratic People's Republic of" },
  { value: 'kr', label: 'Korea, Republic of' },
  { value: 'kw', label: 'Kuwait' },
  { value: 'ky', label: 'Cayman Islands' },
  { value: 'kz', label: 'Kazakhstan' },
  { value: 'la', label: "Lao People's Democratic Republic" },
  { value: 'lb', label: 'Lebanon' },
  { value: 'lc', label: 'Saint Lucia' },
  { value: 'li', label: 'Liechtenstein' },
  { value: 'lk', label: 'Sri Lanka' },
  { value: 'lr', label: 'Liberia' },
  { value: 'ls', label: 'Lesotho' },
  { value: 'lt', label: 'Lithuania' },
  { value: 'lu', label: 'Luxembourg' },
  { value: 'lv', label: 'Latvia' },
  { value: 'ly', label: 'Libya' },
  { value: 'ma', label: 'Morocco' },
  { value: 'mc', label: 'Monaco' },
  { value: 'md', label: 'Moldova, Republic of' },
  { value: 'me', label: 'Montenegro' },
  { value: 'mf', label: 'Saint Martin, (French part)' },
  { value: 'mg', label: 'Madagascar' },
  { value: 'mh', label: 'Marshall Islands' },
  { value: 'mk', label: 'North Macedonia' },
  { value: 'ml', label: 'Mali' },
  { value: 'mm', label: 'Myanmar' },
  { value: 'mn', label: 'Mongolia' },
  { value: 'mo', label: 'Macao' },
  { value: 'mp', label: 'Northern Mariana Islands' },
  { value: 'mq', label: 'Martinique' },
  { value: 'mr', label: 'Mauritania' },
  { value: 'ms', label: 'Montserrat' },
  { value: 'mt', label: 'Malta' },
  { value: 'mu', label: 'Mauritius' },
  { value: 'mv', label: 'Maldives' },
  { value: 'mw', label: 'Malawi' },
  { value: 'mx', label: 'Mexico' },
  { value: 'my', label: 'Malaysia' },
  { value: 'mz', label: 'Mozambique' },
  { value: 'na', label: 'Namibia' },
  { value: 'nc', label: 'New Caledonia' },
  { value: 'ne', label: 'Niger' },
  { value: 'nf', label: 'Norfolk Island' },
  { value: 'ng', label: 'Nigeria' },
  { value: 'ni', label: 'Nicaragua' },
  { value: 'nl', label: 'Netherlands' },
  { value: 'no', label: 'Norway' },
  { value: 'np', label: 'Nepal' },
  { value: 'nr', label: 'Nauru' },
  { value: 'nu', label: 'Niue' },
  { value: 'nz', label: 'New Zealand' },
  { value: 'om', label: 'Oman' },
  { value: 'pa', label: 'Panama' },
  { value: 'pe', label: 'Peru' },
  { value: 'pf', label: 'French Polynesia' },
  { value: 'pg', label: 'Papua New Guinea' },
  { value: 'ph', label: 'Philippines' },
  { value: 'pk', label: 'Pakistan' },
  { value: 'pl', label: 'Poland' },
  { value: 'pm', label: 'Saint Pierre and Miquelon' },
  { value: 'pn', label: 'Pitcairn' },
  { value: 'pr', label: 'Puerto Rico' },
  { value: 'ps', label: 'Palestine, State of' },
  { value: 'pt', label: 'Portugal' },
  { value: 'pw', label: 'Palau' },
  { value: 'py', label: 'Paraguay' },
  { value: 'qa', label: 'Qatar' },
  { value: 're', label: 'Réunion' },
  { value: 'ro', label: 'Romania' },
  { value: 'rs', label: 'Serbia' },
  { value: 'ru', label: 'Russian Federation' },
  { value: 'rw', label: 'Rwanda' },
  { value: 'sa', label: 'Saudi Arabia' },
  { value: 'sb', label: 'Solomon Islands' },
  { value: 'sc', label: 'Seychelles' },
  { value: 'sd', label: 'Sudan' },
  { value: 'se', label: 'Sweden' },
  { value: 'sg', label: 'Singapore' },
  { value: 'sh', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'si', label: 'Slovenia' },
  { value: 'sj', label: 'Svalbard and Jan Mayen' },
  { value: 'sk', label: 'Slovakia' },
  { value: 'sl', label: 'Sierra Leone' },
  { value: 'sm', label: 'San Marino' },
  { value: 'sn', label: 'Senegal' },
  { value: 'so', label: 'Somalia' },
  { value: 'sr', label: 'Suriname' },
  { value: 'ss', label: 'South Sudan' },
  { value: 'st', label: 'Sao Tome and Principe' },
  { value: 'sv', label: 'El Salvador' },
  { value: 'sx', label: 'Sint Maarten, (Dutch part)' },
  { value: 'sy', label: 'Syrian Arab Republic' },
  { value: 'sz', label: 'Eswatini' },
  { value: 'tc', label: 'Turks and Caicos Islands' },
  { value: 'td', label: 'Chad' },
  { value: 'tf', label: 'French Southern Territories' },
  { value: 'tg', label: 'Togo' },
  { value: 'th', label: 'Thailand' },
  { value: 'tj', label: 'Tajikistan' },
  { value: 'tk', label: 'Tokelau' },
  { value: 'tl', label: 'Timor-Leste' },
  { value: 'tm', label: 'Turkmenistan' },
  { value: 'tn', label: 'Tunisia' },
  { value: 'to', label: 'Tonga' },
  { value: 'tr', label: 'Turkey' },
  { value: 'tt', label: 'Trinidad and Tobago' },
  { value: 'tv', label: 'Tuvalu' },
  { value: 'tw', label: 'Taiwan, Province of China' },
  { value: 'tz', label: 'Tanzania, United Republic of' },
  { value: 'ua', label: 'Ukraine' },
  { value: 'ug', label: 'Uganda' },
  { value: 'um', label: 'United States Minor Outlying Islands' },
  { value: 'us', label: 'United States of America' },
  { value: 'uy', label: 'Uruguay' },
  { value: 'uz', label: 'Uzbekistan' },
  { value: 'va', label: 'Holy See' },
  { value: 'vc', label: 'Saint Vincent and the Grenadines' },
  { value: 've', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'vg', label: 'Virgin Islands, British' },
  { value: 'vi', label: 'Virgin Islands, U.S.' },
  { value: 'vn', label: 'Viet Nam' },
  { value: 'vu', label: 'Vanuatu' },
  { value: 'wf', label: 'Wallis and Futuna' },
  { value: 'ws', label: 'Samoa' },
  { value: 'ye', label: 'Yemen' },
  { value: 'yt', label: 'Mayotte' },
  { value: 'za', label: 'South Africa' },
  { value: 'zm', label: 'Zambia' },
  { value: 'zw', label: 'Zimbabwe' },
];

export const INDIVIDUAL_TRUSTEE = 'INDIVIDUAL_TRUSTEE';
export const CORPORATE_TRUSTEE = 'CORPORATE_TRUSTEE';
export const TRUSTEE_STRUCTURES = [
  { value: 'INDIVIDUAL_TRUSTEE', label: 'Individual trustee' },
  { value: 'CORPORATE_TRUSTEE', label: 'Corporate trustee' },
];

export const MEMBER_OR_TRUSTEE_TYPES = [
  { value: 'MEMBER', label: 'Member' },
  { value: 'CONTACT', label: 'Trustee' },
  { value: 'MEMBER_AND_CONTACT', label: 'Member and Trustee' },
];

export const MEMBER_OR_DIRECTOR_TYPES = [
  { value: 'MEMBER', label: 'Member' },
  { value: 'CONTACT', label: 'Director' },
  { value: 'MEMBER_AND_CONTACT', label: 'Member and Director' },
];

export const getMemberTypeList = (trusteeStructure) => {
  switch (trusteeStructure) {
    case 'INDIVIDUAL_TRUSTEE':
      return MEMBER_OR_TRUSTEE_TYPES;
    case 'CORPORATE_TRUSTEE':
      return MEMBER_OR_DIRECTOR_TYPES;
    default:
      return [];
  }
};

export const PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES = [
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'I',
    label: 'Intersex or Indeterminate',
  },
  {
    value: 'N',
    label: 'I choose not to disclose',
  },
];

// May 2022 - SMSF Alias lookup table version 22 (XLSX, 15.7KB) (https://softwaredevelopers.ato.gov.au/supervalidationservices#smsf-alias-lookup-table)
export const ESA_ALIASES = [
  'AUSPOSTSMSF',
  'CLICKSUPER',
  'ESUPERFUND',
  'smsfdataflow',
  'SuperMate',
  'BGLSF360',
  'wrkrSMSF',
];

export const DEATH_BENEFIT_RECIPIENT_AGE_CATEGORY_CODE = [
  { value: 'A', label: 'Adult' },
  { value: 'C', label: 'Child' },
];

export const DEATH_BENEFIT_TAX_CODE = [
  {
    value: 'P',
    label: 'Dependant entitled to a concessionally taxed income stream',
  },
  {
    value: 'Q',
    label: 'Dependant not entitled to a concessionally taxed income stream',
  },
];
