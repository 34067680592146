import { ReactComponent as Logo } from '../../assets/images/logo_wrkr-smsf.svg';
import { useHistory, useLocation } from 'react-router-dom';
import signOut from 'utils/signOut';
import { useEffect, useState } from 'react';
import Spinner from 'components/Spinner/Spinner';
import { CURRENT_PROCESS } from 'constants/registration';
import { useAuthContext } from 'contexts/authContext';

const NotFound = () => {
  const history = useHistory();
  const location = useLocation();
  const [isWaitLogout, setIsWaitLogout] = useState(false);
  const { authState, setAuthState } = useAuthContext();

  const directToLoginRoutes = [
    'login',
    'register',
    'two-factor-setup-complete',
    'sms-code-confirm',
    'auth-app-setup',
    'two-factor-setup',
    'auth-app-login',
  ];

  const isInRegRoute = directToLoginRoutes.includes(location.pathname.slice(1));
  //@ts-ignore
  useEffect(() => {
    if (isInRegRoute) {
      setIsWaitLogout(true);
      signOut()
        .then((d) => {
          setAuthState(CURRENT_PROCESS.SIGNIN);
          history.go(0);
        })
        .catch((e) => console.log('error: ', e))
        .finally(() => {
          setIsWaitLogout(false);
        });
    }
  }, []);

  if (isWaitLogout || authState === CURRENT_PROCESS.SIGNIN) {
    return (
      <div style={{ textAlign: 'center' }}>
        {/* @ts-ignore */}
        <Spinner />
      </div>
    );
  }

  return (
    <div className="page__background" style={{ width: '100vw' }}>
      <div className="mb-8">
        <Logo />
      </div>
      <h1 className="title">404</h1>
      <h1>Page not found</h1>
      <p className="longform mb-8">
        Unfortunately, we can't find the page you are looking for.
      </p>
      <button
        className="button button--lg button--primary"
        onClick={() => history.go(-1)}
      >
        Return to Wrkr SMSF Hub
      </button>
    </div>
  );
};

export default NotFound;
