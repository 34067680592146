import { useEffect, useState } from 'react';
import {
  AUTHENTICATOR_SET_UP,
  SIGN_IN,
  SMS_CODE_CONFIRM,
} from 'constants/registration';
import { AWSService } from 'services/AWSService/AWSService';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import useNavigationModal from 'hooks/useNavigationModal';
import { defaultBackButtonModal } from 'constants/navigationModals';

const styles = {
  border: {
    borderBottom: '1px solid lightgrey',
    width: '100%',
  },

  content: {
    padding: '0 10px 0 10px',
  },
};

const Divider = ({ children }) => {
  return (
    <div className="divider">
      <div style={styles.border} />
      <span style={styles.content}>{children}</span>
      <div style={styles.border} />
    </div>
  );
};

export const TwoFactorSetup = () => {
  useNavigationModal(defaultBackButtonModal);
  const [isEnabled, setEnabled] = useState(false);
  const history = useHistory();
  const { user, setUser, tempPwd } = useRegistration();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      history.push(SIGN_IN.PATH);
    } else {
      !user.signInUserSession ? initUser() : setEnabled(true);
    }
  }, []);

  const initUser = async () => {
    try {
      setIsLoading(true);
      const refreshUser = await AWSService.signIn(user.username, tempPwd);
      refreshUser.pwd = tempPwd;
      setUser(refreshUser);
      setEnabled(true);
    } catch (error) {
      console.log('error setting SMS as MFA: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const setSMSAsPreferredMFA = async () => {
    try {
      setIsLoading(true);
      await AWSService.setPreferredMFA('SMS');
      let newUser = await AWSService.signIn(user.username, tempPwd);
      setUser(newUser);
      history.push(SMS_CODE_CONFIRM.PATH);
    } catch (error) {
      console.log('error setting SMS as MFA: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <LoginContainer>
          <div>
            <h1>Two-step verification</h1>
            <p>
              As an additional layer of security, click <b>"Send code"</b> to
              send an SMS to the following mobile number:
            </p>
            <div className="send-code__container">
              <span className="send-code__mobile">
                {' '}
                {user?.attributes?.phone_number}
              </span>
              <span>
                <button
                  className="button button--primary"
                  disabled={!isEnabled}
                  onClick={setSMSAsPreferredMFA}
                >
                  Send code
                </button>
              </span>
            </div>
            <p className="p--sm mt-4">
              If the above number is incorrect, please contact{' '}
              <a href="mailto:support@wrkr.com.au" className="link">
                support@wrkr.com.au
              </a>
            </p>
            <Divider>
              <b>OR</b>
            </Divider>

            <p>
              You can use the <strong>Google Authenticator app</strong> if you
              are unable to receive the SMS.
            </p>
            <button
              className="button button--outline button--xs"
              onClick={() => {
                history.push(AUTHENTICATOR_SET_UP.PATH);
              }}
            >
              Verify via Authenticator app
            </button>
          </div>
        </LoginContainer>
      </div>
    </div>
  );
};
