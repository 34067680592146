import OtpInput from 'react-otp-input';

interface OTPProps {
  otp: string;
  handleChange: () => void;
  invalidCode: any;
}

export const OTP = ({ otp, handleChange, invalidCode }: OTPProps) => {
  return (
    <>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        className="pin-input"
        hasErrored={invalidCode}
        errorStyle={{ borderColor: 'red' }}
        isInputNum={true}
      />
    </>
  );
};
