import { AxiosResponse } from 'axios';
import HttpClient from '../../utils/HttpClient';
import {
  TermsAndConditionsType,
  TermsAndConditionsResponse,
} from './types/TermsAndConditionsService.types';

export class TermsAndConditionsService {
  static async retrieveTermsAndConditions(
    query: TermsAndConditionsType,
  ): Promise<AxiosResponse<TermsAndConditionsResponse>> {
    return HttpClient.get(`/smsf-hub/terms-and-conditions/${query}`);
  }
}
