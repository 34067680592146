import { createContext, useContext } from 'react';
import { useLocation, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import HttpClient from 'utils/HttpClient';
import Spinner from 'components/Spinner/Spinner';
import GeneralError from '../pages/GeneralError/GeneralError';

const FundContext = createContext();
FundContext.displayName = 'FundContext';

export const FundProvider = (props) => {
  const { state } = useLocation();
  const [fund, setFund] = useState(state?.fund || null);
  const [error, setError] = useState(null);
  const { guid } = useParams();

  const getFundDetails = async (guid) => {
    try {
      const response = await HttpClient.get(`/smsf-hub/smsf/${guid}`);
      const { data } = response;
      if (data) {
        setFund(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (state?.fund != null && state?.fund?.guid === guid) {
      setFund(state.fund);
    } else if (guid != null) {
      getFundDetails(guid);
    }
  }, [state, guid]);

  if (error) {
    return <GeneralError error={error} />;
  }

  return <FundContext.Provider value={fund} {...props} />;
};

export const useFundContext = () => {
  const context = useContext(FundContext);
  if (context === undefined) {
    throw new Error(`useFundContext must be used within a FundProvider`);
  }
  return context;
};
