import { ReactComponent as LockSm } from '../../assets/images/icons/icon_lock-sm.svg';

const LockIcon = () => {
  return (
    <div className="d-flex justify-content-between align-items-end">
      <p className="p--sm d-flex">
        <LockSm />
        <span className="ml-1">Encrypted end to end</span>
      </p>
    </div>
  );
};

export default LockIcon;
