import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';

export const InfoBox = () => {
  const history = useHistory();
  const { info } = useRegistration();
  return (
    <div className="container">
      <div className="registration__container">
        <LoginContainer>
          <h1>{info.heading}</h1>

          <div dangerouslySetInnerHTML={{ __html: info.body }}></div>
          <button
            className="button button--primary button--lg button--full-width mb-4"
            type="button"
            onClick={() => history.push(info.buttonHref)}
          >
            {info.buttonText}
          </button>
        </LoginContainer>
      </div>
    </div>
  );
};
