import { Link } from 'react-router-dom';
import { convertToAuFllDateFormat } from 'utils/format';
import {SMSF_STATUS} from "../../constants/smsf";

const ContributionCellLink = ({
  to,
  children,
  focusable = false,
  disabled = false,
}) => {
  return (
    <Link
      to={disabled ? '#' : to}
      tabIndex={focusable ? undefined : '-1'}
      className={disabled ? 'disabled-link' : ''}
    >
      {children}
    </Link>
  );
};

const isContributionAllowed = (status) => {
  return SMSF_STATUS.cancelled !== status;
};

const ContributionMessageDataRow = ({ row, smsfStatus }) => {
  return (
    <tr key={row.messageTransactionId}>
      <td>
        <ContributionCellLink
          to={`/smsfs/${row.smsfGuid}/contributions/${row.messageTransactionId}`}
          focusable
          disabled={!isContributionAllowed(smsfStatus)}
          className="button button--link"
        >
          <span className="button button--link word-break">
            {row.conversationId}
          </span>
        </ContributionCellLink>
      </td>
      <td>
        <ContributionCellLink
          to={`/smsfs/${row.smsfGuid}/contributions/${row.messageTransactionId}`}
          disabled={!isContributionAllowed(smsfStatus)}
        >
          {row.smsfName}
          <span className="subtxt">ABN: {row.smsfAbn}</span>
        </ContributionCellLink>
      </td>
      <td>
        <ContributionCellLink
          to={`/smsfs/${row.smsfGuid}/contributions/${row.messageTransactionId}`}
          disabled={!isContributionAllowed(smsfStatus)}
        >
          {row.payerOrgName}
          <span className="subtxt">ABN: {row.payerAbn}</span>
        </ContributionCellLink>
      </td>
      <td>
        <ContributionCellLink
          to={`/smsfs/${row.smsfGuid}/contributions/${row.messageTransactionId}`}
          disabled={!isContributionAllowed(smsfStatus)}
        >
          <span>
            {row.displayMember}
            {row.numberOfMembers > 1 ? (
              <span className="subtxt subtxt--inline">
                +{row.numberOfMembers - 1}
              </span>
            ) : (
              ''
            )}
          </span>
        </ContributionCellLink>
      </td>

      <td>
        <ContributionCellLink
          to={`/smsfs/${row.smsfGuid}/contributions/${row.messageTransactionId}`}
          disabled={!isContributionAllowed(smsfStatus)}
        >
          {convertToAuFllDateFormat(row.transactionDate)}
        </ContributionCellLink>
      </td>
      <td className="is-alignRight">
        <ContributionCellLink
          to={`/smsfs/${row.smsfGuid}/contributions/${row.messageTransactionId}`}
          disabled={!isContributionAllowed(smsfStatus)}
        >
          {row.total}
        </ContributionCellLink>
      </td>
    </tr>
  );
};

export default ContributionMessageDataRow;
