import { ReactComponent as BsChevronLeft } from 'bootstrap-icons/icons/arrow-left.svg';
import { ReactComponent as BsChevronRight } from 'bootstrap-icons/icons/arrow-right.svg';
import { useState, useEffect } from 'react';
import { ALLOWED_PAGE_SIZES, DEFAULT_PAGE_SIZE } from '../../utils/configs';

const PaginationBar = ({
  setPaginationParams,
  data = { totalItems: 0, pageNumber: 0, pageSize: DEFAULT_PAGE_SIZE },
  resultType = 'items',
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);

  useEffect(() => {
    let totalPagesWithData = Math.ceil(data?.totalItems / data?.pageSize);
    setTotalPages(totalPagesWithData);
    setItemsPerPage(
      data?.pageNumber + 1 === totalPagesWithData
        ? data?.totalItems % data?.pageSize
        : data?.pageSize,
    );
  }, [data, setTotalPages, setItemsPerPage]);

  const showRowsChange = (row) => {
    setPaginationParams(0, row.target.value);
  };

  const prevPage = (pageNumber) => {
    const current = pageNumber - 1;
    setPaginationParams(current, data?.pageSize);
  };

  const nextPage = (pageNumber) => {
    const current = pageNumber + 1;
    setPaginationParams(current, data?.pageSize);
  };

  return (
    <div>
      <div className="pagination-table">
        <div className="pagination-table__show-rows">
          <label>Show rows:</label>
          <select value={data?.pageSize} onChange={showRowsChange}>
            {ALLOWED_PAGE_SIZES.map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <span>
            <strong>
              {Number.isInteger(data?.pageNumber) &&
              Number.isInteger(data?.totalItems) &&
              data?.totalItems > 0
                ? data?.pageNumber * data?.pageSize + 1
                : 0}
            </strong>{' '}
            -
            <strong>
              {' '}
              {parseInt(
                data?.pageNumber * data?.pageSize + itemsPerPage,
              ).toString()}
            </strong>
            <strong> of </strong>
            <strong>{data?.totalItems}</strong> {resultType}
          </span>
        </div>
        <div className="pagination-table__buttons">
          <button
            className="btn-pagination"
            disabled={data?.pageNumber === 0}
            onClick={() => prevPage(data?.pageNumber, itemsPerPage)}
          >
            <BsChevronLeft />
          </button>
          <button
            className="btn-pagination"
            disabled={
              Number.isInteger(data?.pageNumber) &&
              Number.isInteger(data?.totalItems) &&
              data?.totalItems > 0
                ? data?.pageNumber + 1 === totalPages
                : true
            }
            onClick={() => nextPage(data?.pageNumber, itemsPerPage)}
          >
            <BsChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginationBar;
