import { useState } from 'react';
import { Formik, Form } from 'formik';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg';
import { SIGN_IN } from '../../constants/registration';
import { LoginContainer } from './LoginContainer';
import { AWSService } from 'services';
import { mapCustomError } from 'utils/mapServiceCallErrors';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { OTP } from 'components/OTP/OTP';
import { ErrorCodeMessage } from 'components/ErrorCodeMessage/ErrorCodeMessage';

export const ResetPassword = () => {
  const [errorMsg, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { userStatus, tempPwd } = useRegistration();
  const [otp, setOtp] = useState();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const history = useHistory();
  const [invalidCode, setInvalidCode] = useState('');
  const [showResend, setShowResend] = useState(false);

  const handleChange = (otp) => {
    setSubmitEnabled(otp.length === 6);
    setOtp(otp);
  };

  const resendConfirmationCode = async () => {
    try {
      setIsLoading(true);
      await AWSService.sendForgotPasswordCode(userStatus.username);
      setInvalidCode('');
      setOtp('');
      setError('');
    } catch (err) {
      console.log('error resending code: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = async () => {
    setError(null);
    setIsLoading(true);
    try {
      await AWSService.submitForgotPasswordCode(
        userStatus.username,
        otp,
        tempPwd,
      );

      history.push(SIGN_IN.PATH);
    } catch (e) {
      e.code === 'CodeMismatchException'
        ? setInvalidCode('Invalidcode')
        : setInvalidCode('');
      setError(mapCustomError(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <Formik
          initialValues={{
            code: '',
          }}
          onSubmit={submitHandler}
        >
          {(formik) => (
            <LoginContainer>
              <div>
                <Form>
                  <h1>Check your email</h1>
                  <p className="p--m mb-1">
                    Please enter the code sent to{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      "{userStatus.maskedEmail}".
                    </span>
                  </p>
                  <div className="pin-group">
                    <OTP
                      otp={otp}
                      handleChange={handleChange}
                      invalidCode={invalidCode}
                    />
                  </div>

                  <ErrorCodeMessage
                    invalidCode={errorMsg}
                    errClassName="error d-block pb-4"
                  />

                  <button
                    className="button button--primary button--lg button--full-width mb-4"
                    type="submit"
                    disabled={!submitEnabled}
                  >
                    Reset password
                  </button>
                  <br />
                </Form>
                <div className="mt-4 mb-4">
                  <a
                    className="collapse-group"
                    onClick={() => {
                      setShowResend(!showResend);
                    }}
                  >
                    Didn't receive the email?
                    {showResend ? (
                      <CaretUpFill className="button--icon-after__icon" />
                    ) : (
                      <CaretDownFill className="button--icon-after__icon" />
                    )}
                  </a>
                </div>
                {showResend && (
                  <div>
                    <p>Remember to check your spam folder.</p>
                    <button
                      className="button button--outline button--xs"
                      onClick={resendConfirmationCode}
                    >
                      Resend email
                    </button>
                  </div>
                )}
              </div>
            </LoginContainer>
          )}
        </Formik>
      </div>
    </div>
  );
};
