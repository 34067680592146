import { AxiosResponse } from 'axios';
import HttpClient from '../../utils/HttpClient';
import { ContributionTransactionDetails } from './types/ContributionSearchService.types';

export class ContributionSearchService {
  static async searchByTransactionId(
    smsfGuid: string,
    transactionId: string,
  ): Promise<AxiosResponse<ContributionTransactionDetails>> {
    return HttpClient.get(
      `/smsf-hub/contribution/search/${smsfGuid}/search-by-transaction-id/${transactionId}`,
    );
  }
}
