import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { ReactComponent as ArrowIn } from '../../assets/images/icons/icon_arrow-in.svg';
import { Fragment, useEffect, useState } from 'react';
import MemberDetailsSlider from './MemberDetailsSlider';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { convertToAuFllDateFormat } from '../../utils/format';
import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import OrganisationDetail from './OrganisationDetail';
import { getType } from '../../utils/labels';
import SpinnerModal from '../../components/SpinnerModal/SpinnerModal';
import { ContributionSearchService } from '../../services/ContributionSearchService/ContributionSearchService';
import Alert from "../../components/Alert/Alert";

const InitialError = {
  status: '',
  message: '',
};

const ContributionDetail = () => {
  const { guid, messageTransactionId } = useParams();
  const [isMemberDetailsSliderOpen, setIsMemberDetailsSliderOpen] =
    useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] =
    useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(InitialError);
  const [isSendingErrorResponse, setIsSendingErrorResponse] = useState(false);

  useEffect(() => {
    let didCancel = false;

    const getResults = async () => {
      setError(InitialError);

      try {
        const res = await ContributionSearchService.searchByTransactionId(
          guid,
          messageTransactionId,
        );
        if (!didCancel) {
          setData(res?.data);
          setError(InitialError);
          setIsLoaded(true);
        }
      } catch (err) {
        if (!didCancel) {
          setIsLoaded(true);
          if (err.response) {
            setData(null);
            setError({
              status: err.response.status.toString(),
              message: err.response.data.message || err.response.data,
            });
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log('Error', err.message);
          }
        }
      }
    };

    if (!isLoaded) {
      getResults();
    }
    return () => {
      didCancel = true;
    };
  }, [messageTransactionId, isLoaded]);

  return (
    <div className="rollovers-detail">
      {isLoaded ? (
        <>
          <div className="banner banner--fixed">
            <Breadcrumbs />
            <div>
              <h1>Contribution</h1>
              {data?.dateCreated != null && (
                <div>
                  <h2>
                    <strong>Date created:</strong>{' '}
                    {convertToAuFllDateFormat(data?.dateCreated)} |{' '}
                    <strong>Conversation ID:</strong> {data?.conversationId}
                  </h2>
                </div>
              )}
            </div>
          </div>
          <div className="main__2col">
            <div className="content-area">
              {isLoaded && error.status.length > 0 && (
                <>
                  <div className="error-section">
                    <h2>{error.status}</h2>
                    <p>{error.message}</p>
                  </div>
                  <br />
                </>
              )}

              {data?.validationResponse !== null  && (
                  <div>
                    <Alert
                        variation="alert"
                        title="This contribution has been rejected."
                    >
                      {data?.validationResponse?.validations.map(
                          (validation, index) => {
                            return (
                                <div className="error--container" key={index}>
                                  <div>
                                    <span className="error__tag">Error</span>
                                  </div>
                                  <div>
                                    <p className="error__title">
                                      {validation?.message}
                                    </p>
                                    <p className="error__content">
                                      {validation?.details}
                                    </p>
                                    {validation?.refundAmount && (
                                        <p className="error__refund">
                                          <strong>Refund amount:</strong>{' '}
                                          {validation?.refundAmount}
                                          <br />
                                          <strong>Refund PRN:</strong>{' '}
                                          {validation?.refundPaymentReferenceNumber}
                                        </p>
                                    )}
                                  </div>
                                </div>
                            );
                          },
                      )}
                    </Alert>
                  </div>
              )}

              <article className="participating-funds">
                <OrganisationDetail organisation={data?.payee} isPayee={true} />
                <div className="arrow">
                  <h2>{getType(data?.contributionType)}</h2>
                  <ArrowIn />
                </div>
                <OrganisationDetail
                  organisation={data?.payer}
                  isPayee={false}
                />
              </article>
              <h2>Member details</h2>

              <div className="table-wrapper">
                <table className="table table--member">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employer</th>
                      <th className="is-alignRight">Super guarantee</th>
                      <th className="is-alignRight">Salary sacrifice</th>
                      <th className="is-alignRight">Member voluntary</th>
                      <th className="is-alignRight">Other contributions</th>
                      <th className="is-alignRight">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.memberDetailsList.map((member, index) => {
                      return (
                        <Fragment key={index}>
                          <tr>
                            <td>
                              <button
                                data-cy={index}
                                type="button"
                                className="button--link link"
                                onClick={() => {
                                  setSelectedMember({
                                    ...member,
                                  });
                                  setIsMemberDetailsSliderOpen(true);
                                }}
                              >
                                {member?.personName?.givenName}{' '}
                                {member?.personName?.familyName}
                              </button>
                            </td>
                            <td>
                              {member?.employer?.organisationName?.nameText}
                            </td>
                            <td className="is-alignRight">
                              {member?.employerContributionGuaranteeAmountValue}
                            </td>
                            <td className="is-alignRight">
                              {
                                member?.employerContributionsSalarySacrificedAmountValue
                              }
                            </td>
                            <td className="is-alignRight">
                              {
                                member?.employerContributionsVoluntaryAmountValue
                              }
                            </td>
                            <td className="is-alignRight">
                              {member?.otherContributionAmountValue}
                            </td>
                            <td className="is-alignRight">
                              {member?.totalAmountValue}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td>Grand total</td>
                      <td className="is-alignRight">
                        {data?.employerContributionGuaranteeTotalAmountValue}
                      </td>
                      <td className="is-alignRight">
                        {
                          data?.employerContributionsSalarySacrificedTotalAmountValue
                        }
                      </td>
                      <td className="is-alignRight">
                        {data?.employerContributionsVoluntaryTotalAmountValue}
                      </td>
                      <td className="is-alignRight">
                        {data?.otherContributionTotalAmountValue}
                      </td>
                      <td className="is-alignRight">{data?.totalAmount}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <MemberDetailsSlider
                isOpen={isMemberDetailsSliderOpen}
                member={selectedMember}
                onRequestClose={() => {
                  setIsMemberDetailsSliderOpen(false);
                }}
              />
            </div>
            <div className="content-sidebar">
              <>
                <div className="payment-container">
                  <div className="subtitle">Total amount</div>
                  <h2>{data?.totalAmount}</h2>
                  <p>PRN: {data?.paymentReferenceNumber}</p>
                  <button
                    type="button"
                    className="button button--outline button--sm"
                    onClick={() => {
                      setIsPaymentDetailsModalOpen(true);
                    }}
                  >
                    View payment details
                  </button>
                  <Modal
                    isOpen={isPaymentDetailsModalOpen}
                    onRequestClose={() => setIsPaymentDetailsModalOpen(false)}
                    className="modal modal--lg"
                    overlayClassName="modal-overlay"
                    contentLabel="Payment details"
                    aria={{
                      labelledby: 'payment-details-modal',
                    }}
                    appElement={document.getElementById('root')}
                  >
                    <div className="modal__header">
                      <div
                        className="modal__heading h3"
                        id="payment-details-modal"
                      >
                        Payment Details
                      </div>
                      <button
                        type="button"
                        className="d-inline-flex button--transparent button--close"
                        aria-label="Close"
                        onClick={() => setIsPaymentDetailsModalOpen(false)}
                      >
                        <XLg />
                      </button>
                    </div>
                    <div className="modal__body">
                      <div className="receipt">
                        <div className="receipt__highlight">
                          <p className="h3">Total amount</p>
                          <p className="h2 mb-0">{data?.totalAmount}</p>
                        </div>
                        <dl>
                          <div>
                            <dt>BSB</dt>
                            <dd>
                              {
                                data?.financialInstitutionAccount
                                  ?.bankBranchNumber
                              }
                            </dd>
                          </div>
                          <div>
                            <dt>Account no.</dt>
                            <dd>
                              {data?.financialInstitutionAccount?.accountNumber}
                            </dd>
                          </div>
                          <div>
                            <dt>Account name</dt>
                            <dd>
                              {data?.financialInstitutionAccount?.accountName}
                            </dd>
                          </div>
                          <div>
                            <dt>Payment Ref No (PRN)</dt>
                            <dd>{data?.paymentReferenceNumber}</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </Modal>
                </div>
              </>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
      <SpinnerModal isOpen={isSendingErrorResponse} />
    </div>
  );
};

export default ContributionDetail;
