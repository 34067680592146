import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as FileMoney } from './file-money.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as DataTransfer } from './data-transfer-square-horizontal.svg';
import { ReactComponent as Report } from './graph-up.svg';
import { getListOfMembersLabel } from '../../utils/labels';
import { useFundContext } from 'contexts/fundContext';

import SideMenuLoader from 'components/Loader/SideMenuLoader';

const Sidebar = () => {
  const fund = useFundContext();
  const { guid, fundName } = { ...fund };
  const { state } = useLocation();

  return (
    <aside className="sidebar d-print-none">
      {fund === null ? (
        <SideMenuLoader />
      ) : (
        <nav>
          <div className="sidebar__smsf-name">{fundName}</div>
          <ul className="sidebar__links">
            <li>
              <NavLink
                className="d-flex align-items-center"
                activeClassName="is-active"
                to={`/smsfs/${guid}/rollovers`}
                data-cy="rollovers"
              >
                <DataTransfer className="mr-4" />
                <span>Rollovers</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                className="d-flex align-items-center"
                activeClassName="is-active"
                to={`/smsfs/${guid}/contributions`}
                data-cy="contributions"
              >
                <FileMoney className="mr-4" />
                <span>Contributions</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                className="d-flex align-items-center"
                activeClassName="is-active"
                to={{
                  pathname: `/smsfs/${guid}/members`,
                  search: `?trusteeStructure=${fund.trusteeStructure}`,
                  state: {
                    ...state,
                  },
                }}
                data-cy="members"
              >
                <Users className="mr-4" />
                <span>
                  Members &amp; {getListOfMembersLabel(fund.trusteeStructure)}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="d-flex align-items-center"
                activeClassName="is-active"
                to={`/smsfs/${guid}/reports`}
                data-cy="reports"
              >
                <Report className="mr-4" />
                <span>Reports</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </aside>
  );
};

export default Sidebar;
