import { Link } from 'react-router-dom';

interface ViewMoreProps {
  title: string;
  pathname: string;
}

const ViewMore = ({ title, pathname }: ViewMoreProps) => {
  return (
    <Link className="link" to={pathname}>
      {title}
    </Link>
  );
};

export default ViewMore;
