import { AxiosResponse } from 'axios';
import {
  SMSFABNValidateResponse,
  TermsAndConditionsType,
  TermsAndConditionsResponse,
  TrusteeAccountParticipantRequest,
  TrusteeAccountResponse,
} from './types/AccountService.types';
import HttpClient from 'utils/HttpClient';
import {
  SMSFRequest,
  SMSFMemberRequest,
} from 'services/SMSFService/types/SMSFService.types';
import { AccountSetupStep } from 'services/ParticipantService/types/ParticipantService.types';

export class AccountService {
  static async validateSMSFABN(
    abn: string,
    smsfGuid?: string,
  ): Promise<AxiosResponse<SMSFABNValidateResponse>> {
    return HttpClient.get(
      `/smsf-hub/account-setup/abn/${abn}/validate?smsfGuid=${
        smsfGuid ?? null
      } `,
    );
  }

  static async retrieveTermsAndConditions(
    type: TermsAndConditionsType,
  ): Promise<AxiosResponse<TermsAndConditionsResponse>> {
    return HttpClient.get(
      `/smsf-hub/account-setup/terms-and-conditions/${type}`,
    );
  }

  static async retrieveTrusteeAccount(): Promise<
    AxiosResponse<TrusteeAccountResponse>
  > {
    return HttpClient.get('/smsf-hub/account-setup/trustee-account');
  }

  static async setupTrusteeSmsf(request: {
    smsfRequest: SMSFRequest;
    smsfGuid?: string;
  }): Promise<AxiosResponse<TrusteeAccountResponse>> {
    return HttpClient.post(
      `/smsf-hub/account-setup/trustee-smsf/${request.smsfGuid ?? null}`,
      request.smsfRequest,
    );
  }

  static async setupTrusteeMember(
    data: SMSFMemberRequest,
  ): Promise<AxiosResponse<TrusteeAccountResponse>> {
    return HttpClient.patch(`/smsf-hub/account-setup/trustee-member`, data);
  }

  static async updateAccountSetupStep(
    accountSetupStep: AccountSetupStep,
  ): Promise<AxiosResponse<TrusteeAccountResponse>> {
    return HttpClient.patch(
      `/smsf-hub/account-setup/trustee-step/${accountSetupStep}`,
    );
  }

  static async setupTrusteeParticipant(
    request: TrusteeAccountParticipantRequest,
  ): Promise<AxiosResponse<TrusteeAccountResponse>> {
    return HttpClient.post(
      `/smsf-hub/account-setup/trustee-participant`,
      request,
    );
  }
}
