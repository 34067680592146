export const START = 'START';
export const SMSF_DETAILS = 'SMSF_DETAILS';
export const MEMBER_DETAILS = 'MEMBER_DETAILS';
export const PAYMENT_SETUP = 'PAYMENT_SETUP';
export const SETUP_CONFIRMATION = 'SETUP_CONFIRMATION';
export const COMPLETED = 'COMPLETED';

export const ACCOUNT_SETUP_PATHNAMES = {
  START: '/account-setup/start',
  SMSF_DETAILS: '/account-setup/smsf-details',
  MEMBER_DETAILS: '/account-setup/member-details',
  PAYMENT_SETUP: '/account-setup/payment-setup',
  SETUP_CONFIRMATION: '/account-setup/setup-confirmation',
  COMPLETED: '/account-setup/complete',
};

export const ACCOUNT_SETUP_PATHNAMES_TO_STEP = {
  '/account-setup/start': START,
  '/account-setup/smsf-details': SMSF_DETAILS,
  '/account-setup/member-details': MEMBER_DETAILS,
  '/account-setup/payment-setup': PAYMENT_SETUP,
  '/account-setup/setup-confirmation': SETUP_CONFIRMATION,
  '/account-setup/complete': COMPLETED,
};

export const ACCOUNT_SETUP_ORDER = {
  START: 0,
  SMSF_DETAILS: 1,
  MEMBER_DETAILS: 2,
  PAYMENT_SETUP: 3,
  SETUP_CONFIRMATION: 4,
  COMPLETED: 5,
};
