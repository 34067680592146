import { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import {
  CURRENT_PROCESS,
  SIGN_IN,
  TWO_FACTOR_SET_UP,
  TWO_FACTOR_SET_UP_COMPLETE,
} from 'constants/registration';
import { mapCustomError } from 'utils/mapServiceCallErrors';
import { AWSService } from 'services';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';
import { RegisterService } from 'services/RegisterService/RegisterService';
import { useAuthContext } from 'contexts/authContext';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { OTP } from 'components/OTP/OTP';
import { ErrorCodeMessage } from 'components/ErrorCodeMessage/ErrorCodeMessage';
import { handleCodeSessionTimeOut } from 'utils/handleError';
import useNavigationModal from 'hooks/useNavigationModal';
import { defaultBackButtonModal } from 'constants/navigationModals';

export const AuthenticatorSetup = () => {
  useNavigationModal(defaultBackButtonModal);
  const [otp, setOtp] = useState();
  const [qrCode, setQrCode] = useState();
  const [secret, setSecret] = useState();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [invalidCode, setInvalidCode] = useState('');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { user, setUser, tempPwd } = useRegistration();
  const { setAuthState } = useAuthContext();
  const handleChange = (otp) => {
    setSubmitEnabled(otp.length === 6);
    setOtp(otp);
  };

  const issueQrCode = async () => {
    try {
      const res = await AWSService.setupTOTP();
      setSecret(res);
      const issuer = `wrkr`;
      const str =
        'otpauth://totp/' +
        user.username +
        '?secret=' +
        res +
        '&issuer=' +
        issuer;

      await AWSService.updateUserQrCodeAttributes(res);

      setQrCode(str);
    } catch (error) {
      setInvalidCode(mapCustomError(error));
    }
  };

  const resetPreferredMFA = async () => {
    setIsLoading(true);
    if (user?.challengeName === 'SMS_MFA') {
      await RegisterService.resetPreferredMFA(user.username);
      let newUser = await AWSService.signIn(user.username, tempPwd);
      setUser(newUser);
    }
    await issueQrCode();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user) {
      history.push(SIGN_IN.PATH);
    }
    resetPreferredMFA();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setInvalidCode('');
      await AWSService.verifyTotpToken(otp);
      await AWSService.setPreferredMFA('TOTP');
      const isHubUser = await AWSService.isHubUserCreated();
      if (!isHubUser) {
        history.push(TWO_FACTOR_SET_UP_COMPLETE.PATH);
      } else {
        setAuthState(CURRENT_PROCESS.LOGGED_IN);
        window.location.replace('/');
      }
    } catch (error) {
      handleCodeSessionTimeOut(error, setInvalidCode);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />

        <LoginContainer>
          <form>
            <div>
              {/* <ArrowBack /> */}
              <h1>Verification via Authenticator app</h1>

              <ol className="-mb-6">
                <li>
                  Install Google Authenticator (
                  <a
                    href="https://apps.apple.com/au/app/google-authenticator/id388497605"
                    target="_blank"
                    rel="noreferrer"
                  >
                    iOS
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_AU&gl=US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Android
                  </a>
                  )
                </li>

                <li>
                  <p>Scan the QR code below:</p>
                  {qrCode && secret ? (
                    <>
                      <p
                        data-testid="qr-code-secret"
                        className="qr-code is-hidden"
                      >
                        {secret}
                      </p>
                      <div className="qr-code">
                        <QRCode value={qrCode} />
                      </div>
                    </>
                  ) : (
                    'loading...'
                  )}
                </li>
                <li>
                  <p>Enter the code generated by your Authenticator app</p>
                  <div className="pin-group">
                    <OTP
                      otp={otp}
                      handleChange={handleChange}
                      invalidCode={invalidCode}
                    />
                  </div>
                </li>
              </ol>
              <ErrorCodeMessage
                invalidCode={invalidCode}
                errClassName={'error d-block pb-4'}
              />
              <button
                className="button button--primary button--lg button--full-width"
                disabled={!submitEnabled}
                onClick={onSubmit}
              >
                Continue
              </button>

              <p className="mt-6">
                <a
                  onClick={() => {
                    history.push(TWO_FACTOR_SET_UP.PATH);
                  }}
                  className="link"
                >
                  Can't access the app?{' '}
                  <CaretDownFill className="button--icon-after__icon" />
                </a>
              </p>
            </div>
          </form>
        </LoginContainer>
      </div>
    </div>
  );
};
