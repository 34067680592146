import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'bootstrap-icons/icons/arrow-left.svg';

const BackLink = (props: any) => {
  return (
    <Link className="d-inline-flex align-items-center" {...props}>
      <ArrowLeft className="mr-2" />
      <span>Back</span>
    </Link>
  );
};

export default BackLink;
