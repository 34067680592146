import { ReponsePaymentMessages } from './ReponsePaymentMessages';
import LockIcon from 'pages/Payment/LockIcon';
import {
  isPaymentDone,
  isPaymentProcessingInProgress,
} from 'utils/paymentSetupUtils';
import { useTrusteeAccount } from 'contexts/trusteeAccountContext';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Cross } from '../../assets/images/icons/icon_cross.svg';
import Spinner from 'components/Spinner/Spinner';

export const RenewalFailure = () => {
  const history = useHistory();
  const { trusteeAccount } = useTrusteeAccount();
  const { smsfDetails } = trusteeAccount;
  const smsfStatus = smsfDetails?.smsfStatus;
  const smsfGuid = smsfDetails?.guid;
  if (!smsfStatus) {
    return <Spinner />;
  }
  return (
    <>
      <main className="main">
        <div role="navigation">
          <div className="account-setup">
            <>
              <div className="d-flex justify-content-end mx-4 my-4">
                <button
                  className="button button--icon-lg"
                  onClick={() => {
                    history.push(`/smsfs/${smsfGuid}/rollovers`);
                  }}
                >
                  <Cross />
                </button>
              </div>
              <div className="account-setup__container">
                <h1>Payment setup</h1>
                <form>
                  <p className="longform">
                    Get access to the Wrkr SMSF Hub for{' '}
                    <strong>$39.95+GST per year</strong>
                  </p>

                  <div className="account-setup__section">
                    {!isPaymentDone(smsfStatus) &&
                      !isPaymentProcessingInProgress(smsfStatus) && (
                        <LockIcon />
                      )}
                    <ReponsePaymentMessages />
                  </div>
                  <div>
                    <button
                      className="button button--primary button--lg done"
                      disabled={!isPaymentDone(smsfStatus)}
                      onClick={() => history.push('/')}
                    >
                      Done
                    </button>
                  </div>
                </form>
              </div>
            </>
          </div>
        </div>
      </main>
    </>
  );
};
