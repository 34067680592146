import { useContext, createContext, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { ParticipantService } from 'services';
import Spinner from '../components/Spinner/Spinner';
import GeneralError from '../pages/GeneralError/GeneralError';

const ParticipantContext = createContext();
ParticipantContext.displayName = 'ParticipantContext';

export const ParticipantProvider = (props) => {
  const { state } = useLocation();
  const [participant, setParticipant] = useState(state?.participant || null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getParticipant = async () => {
    try {
      setIsLoading(true);
      const { data } = await ParticipantService.retrieveParticipant();
      setParticipant(data);
    } catch (error) {
      setError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const values = useMemo(
    () => ({
      participant,
      setParticipant,
      getParticipant,
      isLoading,
    }),
    [participant],
  );

  useEffect(() => {
    if (state?.participant !== undefined && state?.participant !== null) {
      setParticipant(state?.participant);
    } else {
      getParticipant();
    }
  }, []);

  if (error) {
    return <GeneralError error={error} />;
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <ParticipantContext.Provider value={values} {...props} />
  );
};

export const useParticipationContext = () => {
  const context = useContext(ParticipantContext);
  if (context === undefined) {
    throw new Error(
      `useParticipationContext must be used within a ParticipantProvider`,
    );
  }
  return context;
};
