import { ReactNode, useState } from 'react';
import { Dialog } from 'components/Dialog/Dialog';
import { LOGO_DIALOG, WRKR_SMSF_ADDRESS } from 'constants/registration';
interface LoginContainerProps {
  children: ReactNode;
}

export const LoginContainer = ({ children }: LoginContainerProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Dialog
        isOpen={showModal}
        header={LOGO_DIALOG.HEADER}
        body={LOGO_DIALOG.BODY}
        okButton={LOGO_DIALOG.OK_BUTTON}
        cancelButton={LOGO_DIALOG.CANCEL_BUTTON}
        handleCloseModal={handleCloseModal}
        redirectTo={WRKR_SMSF_ADDRESS}
        absRedirect={true}
      />
      <div className="login">
        <div
          className="login__logo"
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        />
        <div className="login__container">
          <div className="login__form">{children}</div>
        </div>
      </div>
    </>
  );
};
