import { Dialog } from 'components/Dialog/Dialog';
import { useNavigationModalContext } from 'contexts/navigationModalContext';

const NavigationModal = () => {
  const { modalProps, showModal, handleCloseModal } =
    useNavigationModalContext();

  return (
    <Dialog
      isOpen={showModal}
      header={modalProps.header}
      body={modalProps.body}
      okButton={modalProps.okButton}
      cancelButton={modalProps.cancelButton}
      handleCloseModal={handleCloseModal}
      redirectTo={modalProps.redirectTo}
      absRedirect={modalProps.absRedirect}
      forceSignOut={modalProps.forceSignOut}
    />
  );
};

export default NavigationModal;
