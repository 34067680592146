import { useState } from 'react';
import { ReactComponent as ChevronUp } from 'bootstrap-icons/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'bootstrap-icons/icons/chevron-down.svg';

import { RolloverMessageHistory } from '../../services/RolloverSearchService/types/RolloverSearchService.types';
import { isPaymentDetailsAvailable } from '../../utils/actionUtil';

interface MoreDetailsProp {
  message?: RolloverMessageHistory;
}

export const MoreDetails = ({ message }: MoreDetailsProp) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div className="more-details--btn" onClick={() => setIsOpen(!isOpen)}>
        More details {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isOpen && (
        <div className="more-details--container">
           <div className="conversation-id">Conversation ID: {message?.conversationId}</div>
          {isPaymentDetailsAvailable(message?.action, message?.status) ? (
            <div className="success--container">
              <p className="small mt-4">
                <em>Actioned by: {message?.actionedBy}</em>
              </p>
              <p className="mb-2">
                <b>Payment details</b>
              </p>
              <dl>
                <div>
                  <dt>Total amount</dt>
                  <dd>{message?.totalAmount}</dd>
                </div>
                <div>
                  <dt>BSB</dt>
                  <dd>
                    {message?.financialInstitutionAccount?.bankBranchNumber}
                  </dd>
                </div>
                <div>
                  <dt>Account no.</dt>
                  <dd>{message?.financialInstitutionAccount?.accountNumber}</dd>
                </div>

                <div>
                  <dt>Account name</dt>
                  <dd>{message?.financialInstitutionAccount?.accountName}</dd>
                </div>
                <div>
                  <dt>PRN</dt>
                  <dd>{message?.paymentReferenceNumber}</dd>
                </div>
              </dl>
            </div>
          ) : (
            <div className="error-more-details">
              {message?.validationResponse?.validations.map(
                (validation, index) => {
                  return (
                    <div className="error--container" key={index}>
                      <div>
                        <span className="error__tag">Error</span>
                      </div>
                      <div>
                        <p className="error__title">{validation?.message}</p>
                        <p className="error__content">{validation?.details}</p>
                        {validation?.refundAmount && (
                          <p className="error__refund">
                            <strong>Refund amount:</strong>{' '}
                            {validation?.refundAmount}
                            <br />
                            <strong>Refund PRN:</strong>{' '}
                            {validation?.refundPaymentReferenceNumber}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
