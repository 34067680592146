/**
 * validate ABN
 * @param {string} abn
 * @return {boolean} is ABN valid
 *
 * See https://abr.business.gov.au/HelpAbnFormat.aspx for more details
 * 0. ABN must be 11 digits long
 * 1. Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
 * 2. Multiply each of the digits in this new number by a "weighting factor" based on its position
 * 3. Sum the resulting 11 products
 * 4. Divide the sum total by 89, noting the remainder
 * 5. If the remainder is zero the number is valid
 */
const validateABN = (abn) => {
  let isValid = true;

  // Remove all spaces
  abn = abn.replace(/\s/g, '');

  // Rule: 0
  if (abn.length !== 11) {
    isValid = false;
  }

  if (isValid) {
    let weightedSum = 0;
    const weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

    // Rules: 1, 2, 3
    weight.forEach((element, index) => {
      weightedSum +=
        (parseInt(abn[index], 10) - (index === 0 ? 1 : 0)) * element;
    });

    // Rules: 4, 5
    isValid = weightedSum % 89 === 0;
  }

  return isValid;
};

export default validateABN;
