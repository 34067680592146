import { AxiosResponse } from 'axios';
import HttpClient from '../../utils/HttpClient';
import {
  ParticipantResponse,
  ParticipantContact,
} from './types/ParticipantService.types';

export class ParticipantService {
  static async retrieveParticipant(): Promise<
    AxiosResponse<ParticipantResponse>
  > {
    return HttpClient.get('/smsf-hub/participant');
  }

  static async retrieveContributionContactDetails(
    abn: string,
  ): Promise<AxiosResponse<ParticipantContact>> {
    return HttpClient.get(
      `/smsf-hub/participant/smsf-abn/${abn}/contact-details`,
    );
  }
}
