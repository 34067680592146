import { SMSFStatus } from 'services/SMSFService/types/SMSFService.types';
import { SMSF_STATUS } from '../constants/smsf';

export const getInfoMessage = (status: SMSFStatus) => {
  let message = '';
  if (isPaymentProcessingInProgress(status)) {
    message =
      "We are currently processing your payment. Click 'Continue' to check whether payment is successful.";
  } else {
    message =
      '<p>In order to initiate requests and ensure rollovers can be processed through the Wrkr SMSF Hub, you will need to';
    if (isPaymentPending(status)) {
      message += ' <b>make payment</b>';
    }
    if (isPaymentPending(status) && isATOIntegrationPending(status)) {
      message += ' and';
    }
    if (isATOIntegrationPending(status)) {
      message += ' update the ATO with your SMSF details';
    }
    // if (isPaymentFailed(status)) {
    //   message += '<b> [Reason: Renewal Payment Failed]</b>';
    // }
    message += '.</p>';
  }
  return message;
};

export const isPaymentPending = (status: SMSFStatus) => {
  if (!status) {
    return false;
  }
  return (
    SMSF_STATUS.initialPaymentFailed === status ||
    SMSF_STATUS.renewalPaymentFailed === status ||
    SMSF_STATUS.initialPaymentPending === status ||
    SMSF_STATUS.renewalCancelled === status
  );
};

const isATOIntegrationPending = (status: SMSFStatus) => {
  return (
    SMSF_STATUS.initialPaymentPending === status ||
    SMSF_STATUS.atoIntPending === status
  );
};

export const isATOIntDone = (status: SMSFStatus) => {
  return (
    SMSF_STATUS.active === status ||
    SMSF_STATUS.renewalPaymentProcessingInProgress === status
  );
};

export const isPaymentDone = (status: SMSFStatus) => {
  if (!status) {
    return false;
  }
  return (
    SMSF_STATUS.atoIntPending === status || SMSF_STATUS.active === status
  );
};

export const isPaymentFailed = (status: SMSFStatus) => {
  if (!status) {
    return false;
  }
  return (
    SMSF_STATUS.initialPaymentFailed === status ||
    SMSF_STATUS.renewalPaymentFailed === status ||
    SMSF_STATUS.renewalCancelled === status
  );
};

export const isPaymentProcessingInProgress = (status: SMSFStatus) => {
  if (!status) {
    return false;
  }
  return (
    SMSF_STATUS.initialPaymentProcessinginProgress === status ||
    SMSF_STATUS.renewalPaymentProcessingInProgress === status
  );
};

export const isSubscriptionRenewalPending = (status: SMSFStatus) => {
  if (!status) {
    return false;
  }
  return status  === SMSF_STATUS.renewalPaymentFailed
      || status  === SMSF_STATUS.renewalCancelled;
}

export const isSubscriptionRenewalInProgress = (status: SMSFStatus) => {
  if (!status) {
    return false;
  }
  return status  === SMSF_STATUS.renewalPaymentProcessingInProgress;
}
