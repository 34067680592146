import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import HttpClient from 'utils/HttpClient';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import PaginationBar from 'components/PaginationBar/PaginationBar';
import ViewMore from 'components/ViewMore/ViewMore';
import ContributionMessageDataRow from './ContributionMessageDataRow';
import { DASHBOARD_PAGE_SIZE, DEFAULT_PAGE_SIZE } from 'utils/configs';
import { getSmsfStatus, showAlertInSideSMSF } from 'utils/accountSetupUtils';
import AccountCompleteInfo from 'components/AccountCompleteInfo/AccountCompleteInfo';

import { showGlobalAlerts } from 'utils/globalActionsUtils';
import HubGlobalAlerts from 'components/Alert/HubGlobalAlerts';
import TableLoader from 'components/Loader/TableLoader';
import { useParticipationContext } from 'contexts/participantContext';
import DownloadButton from 'components/DownloadButton/DownloadButton';
import { CONTRIBUTION_CSV_SUMMARY_REPORT } from '../../constants/reports';
import { ReportFilterModal } from '../../components/ReportFilterModal/ReportFilterModal';

const Contributions = ({ isDashboard, setDisableDownload }) => {
  document.title = 'Contributions';
  const { participant } = useParticipationContext();
  const { guid } = useParams();
  const [showModal, setShowModal] = useState(false);

  const InitialData = useMemo(() => {
    return {
      totalPages: 0,
      totalElements: 0,
      number: 0,
    };
  }, []);

  const InitialError = useMemo(() => {
    return {
      status: '',
      message: '',
    };
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(InitialData);
  const [error, setError] = useState(InitialError);
  const [paginationData, setPaginationData] = useState({
    totalItems: 0,
    pageNumber: 0,
    pageSize: isDashboard ? DASHBOARD_PAGE_SIZE : DEFAULT_PAGE_SIZE,
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const setPaginationParams = (pageNumber, pageSize) => {
    setPaginationData({
      ...paginationData,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    setIsLoaded(false);
  };

  useEffect(() => {
    const getResults = async () => {
      setError(InitialError);
      setIsLoaded(false);
      try {
        const res = await HttpClient.post(`/smsf-hub/contribution/search`, {
          pageNumber: paginationData.pageNumber,
          pageSize: paginationData.pageSize,
          smsfGuid: guid,
        });
        setIsLoaded(true);
        const { validationResponse } = res?.data;
        if (validationResponse?.hasErrors) {
          setData(InitialData);
          setError({
            status: validationResponse.validations[0].errorCode,
            message: validationResponse.validations[0].message,
          });
          setDisableDownload(true);
        } else {
          setData(res?.data?.contributionMessages);
          setPaginationData(res?.data?.paginationData);
          setError(InitialError);
          setDisableDownload(res?.data?.contributionMessages?.length === 0);
        }
      } catch (err) {
        setDisableDownload(true);
        setIsLoaded(true);
        if (err.response) {
          setData(InitialData);
          if (err.response.data?.validationResponse?.hasErrors) {
            setData(InitialData);
            setError({
              status:
                err.response.data?.validationResponse.validations[0].errorCode,
              message:
                err.response.data?.validationResponse.validations[0].message,
            });
          } else {
            setError({
              status: err.response.status.toString(),
              message:
                err.response.data?.message ||
                err.response.data?.errorCode ||
                err.response.data,
            });
          }
        } else if (err.request) {
          alert(err.request);
        } else {
          alert('Error', err.message);
        }
      }
    };

    if (!isLoaded) {
      getResults();
    }
  }, [paginationData, isLoaded, InitialData, InitialError, guid]);

  const isSuccessfullyLoaded = () => isLoaded && error.status.length === 0;

  return (
    <>
      <ReportFilterModal
        isOpen={showModal}
        reportDetails={CONTRIBUTION_CSV_SUMMARY_REPORT}
        handleCloseModal={handleCloseModal}
        smsfGuid={guid}
      />
      <main className="main">
        <div className="dashboard">
          <div className="Contributions">
            {isSuccessfullyLoaded() && guid && (
              <div className="banner">
                <Breadcrumbs />
                {showGlobalAlerts(participant) && (
                  <HubGlobalAlerts participant={participant} />
                )}
                {showAlertInSideSMSF(participant, guid) && (
                  <AccountCompleteInfo
                    status={participant?.smsfStatuses[guid]}
                    smsfGuid={guid}
                  />
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <h1>Contributions</h1>
                  <DownloadButton
                    label={CONTRIBUTION_CSV_SUMMARY_REPORT.buttonCaption}
                    setShowModal={() => setShowModal(true)}
                    disabled={data?.length === 0}
                  />
                </div>
              </div>
            )}

            {!isDashboard && !guid && (
              <div className="banner">
                <div className="d-flex align-items-center justify-content-between">
                  <h1>All contributions</h1>
                  <DownloadButton
                      label={CONTRIBUTION_CSV_SUMMARY_REPORT.buttonCaption}
                      setShowModal={() => setShowModal(true)}
                      disabled={data?.length === 0}
                  />
                </div>
              </div>
            )}

            {!isLoaded ? (
              <TableLoader />
            ) : (
              <div className="content">
                {isLoaded && error.status.length > 0 && (
                  <div className="error-section">
                    <h2>{error.status}</h2>
                    <p>{error.message}</p>
                  </div>
                )}

                {data?.length === 0 && (
                  <div className="error-section">
                    <h2>No messages found</h2>
                    <button
                      type="button"
                      className="button button--primary button--icon-before"
                      onClick={() => {
                        setPaginationParams(0, DEFAULT_PAGE_SIZE);
                      }}
                    >
                      Reload
                    </button>
                  </div>
                )}
                {data?.length > 0 && (
                  <div className="table-wrapper">
                    <table className="table table-rollovers">
                      <thead>
                        <tr>
                          <th>Conversation ID</th>
                          <th>Fund</th>
                          <th className="col-payer">Payer</th>
                          <th>Member</th>
                          <th className="col-date">Transaction date</th>
                          <th className="is-alignRight">Total</th>
                          {/* <th className="col-status">Status</th> */}
                          {/* <th className="col-arrow">&nbsp;</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((row, i) => {
                          return (
                            <ContributionMessageDataRow
                              key={row.messageTransactionId}
                              row={row}
                              smsfStatus={getSmsfStatus(
                                participant,
                                guid || row.smsfGuid,
                              )}
                            />
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={6}>
                            {!isDashboard ? (
                              <PaginationBar
                                data={paginationData}
                                setPaginationParams={setPaginationParams}
                                resultType={'contributions'}
                              />
                            ) : (
                              <ViewMore
                                title={'View all contributions'}
                                pathname={'/smsfs/contributions'}
                              />
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Contributions;
