import { useEffect } from 'react';
import { useNavigationModalContext } from 'contexts/navigationModalContext';

const useNavigationModal = (
  modalProps,
  backwardNavigationDisplay = true,
  forwardNavigationDisplay = false,
) => {
  const { setModalProps, setInitialNavigationDisplayOptions } =
    useNavigationModalContext();

  useEffect(() => {
    setInitialNavigationDisplayOptions({
      backwardNavigationDisplay,
      forwardNavigationDisplay,
    });
    setModalProps(modalProps);
  }, [backwardNavigationDisplay, forwardNavigationDisplay]);
};

export default useNavigationModal;
