const Spinner = ({ header = '', message = '' }) => {
  return (
    <div className="mt-4">
      <h3 className="mt-5 text-align-center">
        {header ? header : 'Loading...'}
      </h3>
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
      <p
        className="my-4 text-align-center"
        dangerouslySetInnerHTML={{
          __html: message ? message : 'Please wait whilst we load the page',
        }}
      ></p>
    </div>
  );
};
export default Spinner;
