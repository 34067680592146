import { useEffect, useState } from 'react';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg';
import {
  TWO_FACTOR_SET_UP_COMPLETE,
  SMS_CODE_CONFIRM,
  AUTHENTICATOR_SET_UP,
  CURRENT_PROCESS,
  SIGN_IN,
} from 'constants/registration';
import { AWSService } from 'services';
import { mapCustomError } from 'utils/mapServiceCallErrors';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'contexts/authContext';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { OTP } from 'components/OTP/OTP';
import { ErrorCodeMessage } from 'components/ErrorCodeMessage/ErrorCodeMessage';
import { handleCodeSessionTimeOut } from 'utils/handleError';
import useNavigationModal from 'hooks/useNavigationModal';
import { defaultBackButtonModal } from 'constants/navigationModals';

export const SmsCodeConfirm = () => {
  useNavigationModal(defaultBackButtonModal);
  const [invalidCode, setInvalidCode] = useState('');
  const [otp, setOtp] = useState();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const history = useHistory();
  const { setUser, user, tempPwd } = useRegistration();
  const { setAuthState, redirectTo } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (otp) => {
    setSubmitEnabled(otp.length === 6);
    setOtp(otp);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setInvalidCode('');
      const loggedInUser = await AWSService.confirmSignIn(user, otp, 'SMS_MFA');
      setUser(loggedInUser);
      const isHubUser = await AWSService.isHubUserCreated();
      if (!isHubUser) {
        history.push(TWO_FACTOR_SET_UP_COMPLETE.PATH);
      } else {
        setAuthState(CURRENT_PROCESS.LOGGED_IN);
        window.location.replace(redirectTo ? redirectTo : '/');
      }
    } catch (error) {
      handleCodeSessionTimeOut(error, setInvalidCode);
    } finally {
      setIsLoading(false);
    }
  };

  const resendCode = async () => {
    try {
      setIsLoading(true);
      let newUser = await AWSService.signIn(user.username, tempPwd);
      setUser(newUser);
      history.push(SMS_CODE_CONFIRM.PATH);
    } catch (error) {
      setInvalidCode(mapCustomError(error));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!user) {
      history.push(SIGN_IN.PATH);
    }
  }, []);

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <LoginContainer>
          <form>
            <div>
              <div>
                <h1>Check your mobile phone</h1>
                <p>
                  Please enter the code sent to
                  <strong>
                    &nbsp;
                    {user?.challengeParam?.CODE_DELIVERY_DESTINATION.slice(2)}
                  </strong>
                  .
                </p>
                <div className="pin-group">
                  <OTP
                    otp={otp}
                    handleChange={handleChange}
                    invalidCode={invalidCode}
                  />
                </div>
                <ErrorCodeMessage
                  invalidCode={invalidCode}
                  errClassName={'error d-block pb-4'}
                />
                <button
                  className="button button--lg button--primary button--full-width mb-8 mt-2"
                  type="submit"
                  disabled={!submitEnabled}
                  onClick={onSubmit}
                >
                  Verify
                </button>
                <div className="mt-4 mb-4">
                  <a
                    onClick={() => {
                      setShowResend(!showResend);
                    }}
                    className="collapse-group"
                    data-testid="accordion-resend"
                  >
                    Didn't receive the code?
                    {showResend ? (
                      <CaretUpFill className="button--icon-after__icon" />
                    ) : (
                      <CaretDownFill className="button--icon-after__icon" />
                    )}
                  </a>
                </div>
              </div>
            </div>
          </form>
          {showResend && (
            <div>
              <button
                className="button button--outline button--xs"
                onClick={resendCode}
                data-testid="resend-button"
              >
                Resend code
              </button>

              <div className="mt-4">
                <p className="p--sm">
                  If you are still not able to receive the code, you can verify
                  using the{' '}
                  <a
                    onClick={() => {
                      history.push(AUTHENTICATOR_SET_UP.PATH);
                    }}
                    className="link"
                  >
                    Google Authenticator app
                  </a>
                  .
                </p>
              </div>
            </div>
          )}
        </LoginContainer>
      </div>
    </div>
  );
};
