import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';

interface InfoDialogProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  header: string;
  body: string;
  okButton: string;
}

export const InfoDialog = ({
  isOpen,
  header,
  body,
  okButton,
  handleCloseModal,
}: InfoDialogProps) => {
  return (
    <Modal
      overlayClassName="modal-overlay"
      isOpen={isOpen}
      contentLabel="Loading"
      className="modal info"
      //@ts-ignore
      appElement={document.getElementById('root')}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal__header">
        <div className="modal__heading h3">{header}</div>
        <button
          type="button"
          className="d-inline-flex button--transparent button--close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <XLg />
        </button>
      </div>
      <div className="modal__body">
        <p dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <div className="modal__footer button-group">
        <button
          type="button"
          className="button button--primary"
          onClick={handleCloseModal}
        >
          {okButton}
        </button>
      </div>
    </Modal>
  );
};
