import classNames from 'classnames';
import { PASSWORD_VALIDATION_REGEX_MESSAGE } from 'constants/validation';
import { getEnabledRules } from 'utils/customValidation';
import cloneDeep from 'lodash/cloneDeep';

interface PasswordRequirementsProps {
  requirements: typeof PASSWORD_VALIDATION_REGEX_MESSAGE;
  clickContinue: boolean;
}

const PasswordRequirements = ({
  requirements,
  clickContinue = false,
}: PasswordRequirementsProps) => {
  let reqObj = {};
  Object.keys(requirements ?? {}).length === 0
    ? (reqObj = cloneDeep(getEnabledRules()))
    : (reqObj = cloneDeep(requirements));

  const keys = Object.keys(reqObj);
  let results: Array<Array<string | any>> = [];
  for (let i = 0; i < keys.length; i++) {
    //@ts-ignore
    results.push([keys[i], reqObj[keys[i]]]); // [rule,rule attributes]
  }

  return (
    <>
      <ul className="password-requirements-list">
        {results
          .map((result) => result)
          .map((rule, i) => {
            const [ruleName, ruleAttr] = rule;
            return ruleName === 'noSpace' &&
              ruleAttr.valid === true ? null : ruleName === 'noSpace' &&
              ruleAttr.valid === false &&
              !clickContinue ? (
              <li
                key={i}
                className={classNames({
                  'is-error': !ruleAttr.valid,
                })}
                data-testid={
                  !ruleAttr.valid && clickContinue ? `data-error${i}` : ``
                }
              >
                {ruleAttr.message}
              </li>
            ) : (
              <li
                key={i}
                className={classNames({
                  'is-ok': ruleAttr.valid,
                  'is-error': !ruleAttr.valid && clickContinue,
                })}
                data-testid={
                  !ruleAttr.valid && clickContinue ? `data-error${i}` : ``
                }
              >
                {ruleAttr.message}
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default PasswordRequirements;
