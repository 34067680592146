import { ReactComponent as CircleComplete } from '../../assets/images/icons/icon_circle-complete.svg';
import { ReactComponent as CircleError } from '../../assets/images/icons/icon_circle-error.svg';
import { ReactComponent as CirclePending } from '../../assets/images/icons/icon_circle-pending.svg';

import {
  isPaymentPending,
  isPaymentProcessingInProgress,
  isPaymentDone,
  isPaymentFailed,
  isSubscriptionRenewalPending,
} from '../../utils/paymentSetupUtils';

import { useTrusteeAccount } from 'contexts/trusteeAccountContext';
import { PAYMENT_METHOD } from 'constants/subscription';
import { SMSFStatus } from '../../services/SMSFService/types/SMSFService.types';
import { Elements } from '@stripe/react-stripe-js';
import CardPayment from '../AccountSetup/CardPayment';
import DirectDebitPayment from '../AccountSetup/DirectDebitPayment';
import { loadStripe } from '@stripe/stripe-js';
import Spinner from 'components/Spinner/Spinner';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '',
);

const getAlternateErrorMessage = (
  paymentErrorMessage: string,
  smsfStatus: SMSFStatus,
) => {
  if (!paymentErrorMessage) {
    if (isSubscriptionRenewalPending(smsfStatus)) {
      return 'Your recent subscription renewal payment for the Wrkr SMSF Hub has failed/ cancelled.';
    } else {
      return 'ERROR!!';
    }
  }
};

export const ReponsePaymentMessages = () => {
  const { trusteeAccount } = useTrusteeAccount();
  const { smsfDetails } = trusteeAccount;
  const smsfStatus = smsfDetails?.smsfStatus;
  const smsfGuid = smsfDetails?.guid;
  const paymentMethod = smsfDetails?.paymentMethod;
  const paymentErrorMessage = smsfDetails?.paymentError?.message;

  const altPaymentErrorMessage = getAlternateErrorMessage(
    paymentErrorMessage,
    smsfStatus,
  );

  if (!smsfStatus) {
    return <Spinner />;
  }

  return (
    <>
      {!smsfStatus && (
        <div className="well d-flex">
          <CircleError />
          <strong className="ml-2">Please enter your SMSF details first</strong>
        </div>
      )}

      {isPaymentFailed(smsfStatus) && (
        <div className="well d-flex mb-8">
          <CircleError style={{ flexShrink: '0' }} />
          <strong className="ml-2">{`${
            paymentErrorMessage ? paymentErrorMessage : altPaymentErrorMessage
          } Please make a payment again.`}</strong>
        </div>
      )}

      {isPaymentDone(smsfStatus) && (
        <div className="well d-flex">
          <CircleComplete />
          <strong className="ml-2">
            Your payment details have been entered successfully
          </strong>
        </div>
      )}

      {isPaymentProcessingInProgress(smsfStatus) &&
        (paymentMethod === PAYMENT_METHOD.CARD ? (
          <div className="well d-flex">
            <p>
              We are currently processing your payment. Please refresh this page
              again in a few minutes to check that payment is successful,
              otherwise contact support@wrkr.com.au.
            </p>
          </div>
        ) : (
          <div className="well d-flex">
            <div>
              <CirclePending />
            </div>
            <div className="ml-2">
              <p>
                <strong>Your payment is currently being processed</strong>
              </p>
              <p>
                <strong>Note:</strong> It may take{' '}
                <strong>up to 3 business days</strong> to process your direct
                debit payment. Once processing is successful,{' '}
                <strong>you will be notified via email</strong> immediately with
                access to the SMSF Hub.
              </p>
            </div>
          </div>
        ))}

      {isPaymentPending(smsfStatus) && (
        <Elements stripe={stripePromise}>
          <CardPayment smsfGuid={smsfGuid} />
          <DirectDebitPayment smsfGuid={smsfGuid} />
        </Elements>
      )}
    </>
  );
};
