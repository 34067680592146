import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import { useEffect, useState } from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import ArticleLoader from '../../components/Loader/ArticleLoader';
import { TermsAndConditionsService } from '../../services/TermsAndConditionsService/TermsAndConditionsService';

const AddNewSMSFButton = ({
  showModal,
  handleCloseModal,
  disabled,
  handleValidate,
  isSubmitting,
  termsAndConditionsType,
}) => {
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getTermsAndConditions = async () => {
      setIsLoading(true);
      try {
        const { data } =
          await TermsAndConditionsService.retrieveTermsAndConditions(
            termsAndConditionsType,
          );
        setContent(data?.content);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Load T&Cs the first time the modal is open
    if (showModal && content == null) {
      getTermsAndConditions();
    }
  }, [showModal, content]);

  const [checked, setChecked] = useState(false);

  return (
    <>
      <button
        type="button"
        className="button button--primary button--lg"
        onClick={handleValidate}
        disabled={disabled}
      >
        Add SMSF
      </button>
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className="modal modal--lg modal--action"
        overlayClassName="modal-overlay"
        contentLabel="Terms and Conditions"
        aria={{
          labelledby: 'terms-and-conditions-modal',
        }}
        parentSelector={() => document.querySelector('#new-smsf-form')}
        appElement={document.getElementById('root')}
      >
        <div className="modal__header">
          <div className="modal__heading h3" id="terms-and-conditions-modal">
            Terms and Conditions
          </div>
          <button
            type="button"
            className="d-inline-flex button--transparent button--close"
            aria-label="Close"
            onClick={handleCloseModal}
          >
            <XLg />
          </button>
        </div>
        <div className="modal__body">
          {isLoading ? (
            <ArticleLoader />
          ) : (
            <div>
              <div
                className="content-container terms-and-conditions-content"
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
              <div className="mt-6">
                <Checkbox
                  id="acknowledge"
                  checked={checked}
                  setChecked={setChecked}
                  label="I acknowledge that I have read and accept the Terms and Conditions."
                />
              </div>
            </div>
          )}
        </div>
        <div className="modal__footer">
          <div className="button-group">
            <button
              type="submit"
              className="button button--primary"
              disabled={isSubmitting || !checked}
            >
              Accept and Add SMSF
            </button>
            <button
              type="button"
              className="button button--transparent"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddNewSMSFButton;
