import { useEffect } from 'react';
import { Switch, useLocation, useHistory, Redirect } from 'react-router-dom';
import ProtectedRoutes from './protected';
import PublicRoutes from './public';
import RouteWithSubRoutes from '../RouteWithSubRoutes';

import { useParticipationContext } from 'contexts/participantContext';
import { useAuthContext } from 'contexts/authContext';

import Spinner from 'components/Spinner/Spinner';

import { getParameterByName } from 'utils/string';
import { updateToken } from 'utils/token';

import { CURRENT_PROCESS } from 'constants/registration';
import { PARTICIPANT_TYPE, PARTICIPANT_STATUS } from 'constants/participant';
import { isSubscriptionRenewalPending } from '../utils/paymentSetupUtils';

const customRoutesMerge = (routes, overrides) => {
  return routes.map((route) => {
    const matchingOverride = overrides.find(
      (override) => override.path === route.path,
    );
    if (matchingOverride) {
      return matchingOverride;
    }
    return route;
  });
};

export const ProtectedRoutesSwitch = () => {
  const { participant } = useParticipationContext();

  const { authState } = useAuthContext();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let token = getParameterByName(location.hash, 'access_token');
    updateToken(token);
  }, [history, location.hash]);

  if (authState === CURRENT_PROCESS.SIGNIN) {
    return <Spinner />;
  }

  if (!participant) {
    return <Spinner />;
  }

  let participantRoutes = [];
  if (participant?.participantType === PARTICIPANT_TYPE.trustee) {
    if (participant?.status === PARTICIPANT_STATUS.pendingAccountSetup) {
      participantRoutes = [
        {
          path: '/',
          breadcrumb: null,
          component: () => <Redirect to="/account-setup/start" />,
          exact: true,
        },
      ];
    } else if (
      isSubscriptionRenewalPending(Object.values(participant?.smsfStatuses)[0])
    ) {
      participantRoutes = [
        {
          path: '/',
          breadcrumb: null,
          component: () => <Redirect to="/payment-renewal" />,
          exact: true,
        },
      ];
    } else {
      participantRoutes = [
        {
          path: '/',
          breadcrumb: null,
          component: () => (
            <Redirect
              to={`/smsfs/${
                Object.keys(participant?.smsfStatuses)[0]
              }/rollovers`}
            />
          ),
          exact: true,
        },
      ];
    }
  } else if (participant?.participantType === PARTICIPANT_TYPE.intermediary) {
    participantRoutes = [
      {
        path: '/',
        breadcrumb: 'SMSFs',
        component: () => <Redirect to={`/smsfs`} />,
        exact: true,
      },
    ];
  }

  // const appRoutes = [...ProtectedRoutes, ...participantRoutes];
  const appRoutes = customRoutesMerge(ProtectedRoutes, participantRoutes);

  return (
    <>
      <Switch location={location}>
        {appRoutes.map((route, i) => {
          return <RouteWithSubRoutes key={i} {...route} />;
        })}
      </Switch>
    </>
  );
};

export const PublicRoutesSwitch = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      {PublicRoutes.map((route, i) => {
        return <RouteWithSubRoutes key={i} {...route} />;
      })}
    </Switch>
  );
};
