import { ReactComponent as CompleteCheck } from 'assets/images/icons/icon_check-large.svg';
import { useEffect, useState } from 'react';
import { AccountService, AWSService } from 'services';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { SIGN_IN, USER_ROLE } from 'constants/registration';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { useAuthContext } from 'contexts/authContext';
import { CURRENT_PROCESS } from 'constants/registration';
import { useHistory } from 'react-router-dom';
import GeneralError from 'pages/GeneralError/GeneralError';
import useNavigationModal from 'hooks/useNavigationModal';
import { defaultBackButtonModal } from 'constants/navigationModals';

export const TwoFactorSetupComplete = () => {
  useNavigationModal(defaultBackButtonModal);
  const { user } = useRegistration();
  const [isLoading, setIsLoading] = useState(false);
  const { redirectTo, setAuthState } = useAuthContext();
  const history = useHistory();
  const [error, setError] = useState();

  const onSubmit = async () => {
    setAuthState(CURRENT_PROCESS.LOGGED_IN);
    window.location.replace(redirectTo ? redirectTo : '/');
  };

  useEffect(() => {
    if (!user || !user.username) {
      history.push(SIGN_IN.PATH);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const role = await AWSService.getCurrentUserRole();
        if (role === USER_ROLE) {
          await AccountService.setupTrusteeParticipant({
            username: user.username,
          });
          await AWSService.updateUserRoleAttributes();
        }
      } catch (error) {
        console.log('error setting up participant: ', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [user]);

  if (error) {
    return <GeneralError error={error} />;
  } else {
    return (
      <div className="container">
        <div className="registration__container">
          <SpinnerModal isOpen={isLoading} />
          <LoginContainer>
            <div className="text-center">
              <h1 className="mb-8">Two-step verification successful</h1>
              <div className="mb-8">
                <CompleteCheck />
              </div>
              <p className="mb-8">
                Click <b>'Continue'</b> to proceed with setting up <br />
                your SMSF account
              </p>
              <button
                className="button button--primary button--lg button--full-width"
                type="submit"
                onClick={onSubmit}
              >
                Continue
              </button>
            </div>
          </LoginContainer>
        </div>
      </div>
    );
  }
};
