import { AxiosResponse } from 'axios';
import HttpClient from 'utils/HttpClient';
import {
  SMSFSearchResponse,
  RolloverMessageSearchRequest,
  RolloverTransactionDetails,
  RolloverMessageDetailsResponse,
} from './types/RolloverSearchService.types';

export class RolloverSearchService {
  static async search(
    data: RolloverMessageSearchRequest,
  ): Promise<AxiosResponse<SMSFSearchResponse>> {
    return HttpClient.post(`/smsf-hub/rollover/search`, data);
  }

  static async searchByTransactionId(
    smsfGuid: string,
    transactionId: string,
  ): Promise<AxiosResponse<RolloverTransactionDetails>> {
    return HttpClient.get(
      `/smsf-hub/rollover/search/${smsfGuid}/search-by-transaction-id/${transactionId}`,
    );
  }

  static async searchByConversationId(
    conversationId: string,
  ): Promise<AxiosResponse<RolloverMessageDetailsResponse>> {
    return HttpClient.get(
      `/smsf-hub/rollover/search/search-by-conversation-id?conversationId=${conversationId}`,
    );
  }
}
