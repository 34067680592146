import SlidingPane from 'react-sliding-pane';
import { ReactComponent as X } from 'bootstrap-icons/icons/x.svg';
import { convertToAuNumericDateFormat } from 'utils/format';
import { getHistoryLabel } from 'utils/labels';
import { MoreDetails } from './MoreDetails';
import { Fragment } from 'react';
import { ReactComponent as CircleComplete } from '../../assets/images/icons/icon_circle-complete.svg';
import { ReactComponent as CircleError } from '../../assets/images/icons/icon_circle-error.svg';
import {
  showErrorIcon,
  showSuccessIcon,
} from '../../utils/actionUtil';

const HistorySlider = ({
  isOpen,
  onRequestClose,
  messageHistory,
  messageTransactionId,
}) => {
  return (
    <SlidingPane
      isOpen={isOpen}
      title="History"
      onRequestClose={onRequestClose}
      closeIcon={
        <button type="button" className="button--link">
          <X />
        </button>
      }
      className="slide-pane--custom slide-pane--member-details"
    >
      <div>
        <p className="subheading">
          <b>Transaction ID: {messageTransactionId}</b>
        </p>

        <div className="history--container">
          {messageHistory?.map((message, i) => {
            return (
              <Fragment key={i}>
                <ul>
                  <li>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="rollover-type">
                          {getHistoryLabel(message?.action)}
                          <span className="status--icon">
                            {showSuccessIcon(
                              message?.action,
                              message?.status,
                            ) && <CircleComplete />}
                            {showErrorIcon(
                              message?.action,
                              message?.status,
                            ) && <CircleError />}
                          </span>
                        </p>
                      </div>
                      <div className="history--date">
                        {convertToAuNumericDateFormat(message?.dateCreated)}
                      </div>
                    </div>
                    {/*<div className="grid-history-item">XML</div>*/}
                    <MoreDetails message={message} />
                  </li>
                </ul>
              </Fragment>
            );
          })}
        </div>
      </div>
    </SlidingPane>
  );
};

export default HistorySlider;
