import { SMSF_STATUS } from '../constants/smsf';

export const showAlertOutSideSMSF = (participant) => {
  return participant?.hasOneSMSFAndItHasActionsPending;
};

export const showAlertInSideSMSF = (participant, guid) => {
  if (!!guid) {
    let selectedFundStatus = participant?.smsfStatuses[guid];
    return (
      SMSF_STATUS.initialPaymentPending === selectedFundStatus ||
      SMSF_STATUS.atoIntPending === selectedFundStatus
    );
  }
  return false;
};

export const isRolloverLinksAllowed = (participant, guid) => {
  if (!!guid) {
    let selectedFundStatus = participant?.smsfStatuses[guid];
    return (
      SMSF_STATUS.active === selectedFundStatus ||
      SMSF_STATUS.trial === selectedFundStatus
    );
  }
  return false;
};

export const isRolloverAllowed = (status) => {
  return SMSF_STATUS.active === status || SMSF_STATUS.trial === status;
};

export const getSmsfStatus = (participant, guid) => {
  return participant?.smsfStatuses[guid];
};

export const isGlobalActionActive = (globalActionType, participant) => {
  return participant?.currentHubGlobalActionDetails?.hubGlobalActionTypes?.some(
    (actionType) => actionType === globalActionType,
  );
};

export const isRollOutAllowed = (participant, guid) => {
  return (
    !isGlobalActionActive('DISABLE_ROLL_OUT', participant) &&
    isRolloverLinksAllowed(participant, guid)
  );
};

export const isReleaseAuthorityStatementOutAllowed = (participant, guid) => {
  return (
    !isGlobalActionActive('DISABLE_RAS_OUT', participant) &&
    isRolloverLinksAllowed(participant, guid)
  );
};
