import { AWSService } from 'services';
import { AUTH_CONFIG } from 'utils/configs';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { AuthProvider } from 'contexts/authContext';
import { App } from 'App';
import { ErrorBoundary } from 'react-error-boundary';
import GeneralError from 'pages/GeneralError/GeneralError';
import NavigationModal from 'components/Modal/NavigationModal';
import { NavigationModalProvider } from 'contexts/navigationModalContext';

AWSService.configure(AUTH_CONFIG);

const AppWithAuth = () => {
  return (
    <ErrorBoundary FallbackComponent={GeneralError}>
      <AuthProvider>
        <Router>
          <NavigationModalProvider>
            <ScrollToTop />
            <App />
            <NavigationModal />
          </NavigationModalProvider>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default AppWithAuth;
