import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const autoCorrectedDatePipe = (hasMaxYear) =>
  createAutoCorrectedDatePipe(
    'dd/mm/yyyy',
    hasMaxYear
      ? {
          minYear: 1900,
          maxYear: new Date().getFullYear(),
        }
      : {
          minYear: 1900,
        },
  );

const DayInput = ({ className, hasMaxYear = false, ...props }) => {
  return (
    <MaskedInput
      className={className}
      type="text"
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      keepCharPositions={true}
      pipe={autoCorrectedDatePipe(hasMaxYear)}
      {...props}
    />
  );
};

export default DayInput;
