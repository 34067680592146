import { useEffect, useState } from 'react';
import { ReactComponent as Plus } from 'bootstrap-icons/icons/plus.svg';
import HttpClient from '../../utils/HttpClient';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Spinner from '../../components/Spinner/Spinner';
import MemberDataRow from './MemberDataRow';
import { getMemberLabel, getListOfMembersLabel } from '../../utils/labels';
import useQuery from '../../hooks/useQuery';
import classNames from 'classnames';
import { SMSF_STATUS } from '../../constants/smsf';
import { useParticipationContext } from 'contexts/participantContext';

const InitialError = {
  status: '',
  message: '',
};

const Members = () => {
  const { state } = useLocation();
  const query = useQuery();
  const trusteeStructure = query.get('trusteeStructure');
  document.title = getListOfMembersLabel(trusteeStructure);
  const { guid } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(InitialError);
  const { participant } = useParticipationContext();
  const smsfStatus = participant?.smsfStatuses[guid];

  useEffect(() => {
    let didCancel = false;

    const getResults = async () => {
      setError(InitialError);

      try {
        const res = await HttpClient.get(`/smsf-hub/smsf/${guid}/members`);

        if (!didCancel) {
          setIsLoaded(true);
          setData(res?.data);
          setError(InitialError);
          setIsProcessing(false);
        }
      } catch (err) {
        if (!didCancel) {
          setIsLoaded(true);
          setIsProcessing(false);
          if (err.response) {
            setData(null);
            setError({
              status: err.response.status.toString(),
              message: err.response.data.message || err.response.data,
            });
          } else if (err.request) {
            alert(err.request);
          } else {
            alert('Error', err.message);
          }
        }
      }
    };

    if (!isLoaded) {
      setIsProcessing(true);
      getResults();
    }
    return () => {
      didCancel = true;
    };
  }, [guid, isLoaded]);

  const isSuccessfullyLoaded = () => isLoaded && error.status.length === 0;

  return (
    <div className="members">
      <div className="banner">
        <Breadcrumbs />
        <div className="d-flex align-items-center justify-content-between">
          <h1>Members &amp; {getListOfMembersLabel(trusteeStructure)}</h1>
          {isSuccessfullyLoaded() && data?.length > 0 && (
            <Link
              to={
                smsfStatus !== SMSF_STATUS.active
                  ? '#'
                  : {
                      pathname: `/smsfs/${guid}/members/new`,
                      state: { ...state },
                    }
              }
              className={classNames(
                'button button--outline button--icon-before',
                {
                  'disabled-link': smsfStatus !== SMSF_STATUS.active,
                },
              )}
            >
              <Plus className="button--icon-before__icon" />
              New Member or {getMemberLabel(trusteeStructure)}
            </Link>
          )}
        </div>
      </div>

      <div className="content">
        {isProcessing ? (
          <Spinner />
        ) : (
          <div>
            {isLoaded && error.status.length > 0 && (
              <div className="error-section">
                <h2>{error.status}</h2>
                <p>{error.message}</p>
              </div>
            )}
            {isSuccessfullyLoaded() && data?.length === 0 && (
              <section className="error-section">
                <h2>No Members or {getListOfMembersLabel(trusteeStructure)}</h2>
                <p>
                  You currently have no members or{' '}
                  {getListOfMembersLabel(trusteeStructure).toLowerCase()}{' '}
                  registered.
                </p>
                <Link
                  to={
                    smsfStatus !== SMSF_STATUS.active
                      ? '#'
                      : {
                          pathname: `/smsfs/${guid}/members/new`,
                          state: { ...state },
                        }
                  }
                  className={classNames(
                    'button button--primary button--icon-before',
                    {
                      'disabled-link': smsfStatus !== SMSF_STATUS.active,
                    },
                  )}
                >
                  <Plus className="button--icon-before__icon" />
                  New Member or {getMemberLabel(trusteeStructure)}
                </Link>
              </section>
            )}
            <section>
              {isSuccessfullyLoaded() && (
                <>
                  {data?.length > 0 && (
                    <div>
                      <div className="table-wrapper">
                        <table className="table table-stp">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>DOB</th>
                              <th>TFN</th>
                              <th>Role</th>
                              <th>Last updated</th>
                              {/*<th className="col-xs">&nbsp;</th>*/}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((row) => {
                              return (
                                <MemberDataRow key={row.memberGuid} row={row} />
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </>
              )}
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

export default Members;
