export const VALID_CHARACTERS_REGEX =
  /^([0-9a-zA-Z .,?(){}:;'|\-_=\\/@#$%*=&"])*$/;
export const VALID_PASSWORD_REGEX =
  /^(?!.* )(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?{}<>[\]=/'";|\\:()~`]).{8,}$/;
export const VALID_AMOUNT_REGEX = /^(\d+\.?\d*|\.\d+)$/;
export const VALID_NUMBER_REGEX = /^\d+$/;
export const VALID_MOBILE_NUMBER_REGEX = /^\d+$/;
export const VALID_MOBILE_NUMBER_LENGTH = 10;
export const POSTCODE_REGEX = /^[0-9]{4}$/;
export const VALID_BSB_NUMBER = /^\d{3}-?\d{3}$/;
export const VALID_ACCOUNT_NAME = /^([0-9a-zA-Z .(){}[\]'|\-\\/#*&])*$/;
export const VALID_ACCOUNT_NUMBER = /^\d{1,9}$/;
export const VALID_ACOUNT_NAME_LENGTH = 200;

export const PASSWORD_VALIDATION_REGEX_MESSAGE = {
  lowerCase: {
    regex: /[a-z]/,
    message: 'Lowercase character',
    valid: false,
    enable: true,
  },
  numeric: {
    regex: /\d/,
    message: 'Numeric character',
    valid: false,
    enable: true,
  },
  upperCase: {
    regex: /([A-Z])/,
    message: 'Uppercase character',
    valid: false,
    enable: true,
  },
  specialChar: {
    regex: /[-+_!@#$%^&*.,?{}<>[\]=/'";|\\:()~`]/,
    message: 'Special character',
    valid: false,
    enable: true,
  },
  minLength: {
    regex: /(?=.*).{8,}$/,
    message: 'Minimum 8 character',
    valid: false,
    enable: true,
  },
  noSpace: {
    regex: /([ ])/,
    message: 'Remove spaces',
    valid: true,
    enable: true,
  },
};
