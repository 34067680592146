import { ErrorMessage, Field } from 'formik';

export const FormRadioButton = ({ id, name, label, value, ...props }) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <div className="radio-button">
          <input
            type="radio"
            id={id}
            name={name}
            value={value}
            checked={value === field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            {...props}
          />
          <label htmlFor={id}>{label}</label>
        </div>
      )}
    </Field>
  );
};

export const FormRadioGroup = ({
  id,
  name,
  label,
  inline = false,
  children,
}) => {
  return (
    <div className="form-group" role="radiogroup" aria-labelledby={id}>
      <p id={id} className="form-label mb-3">
        {label}
      </p>
      <div
      // className={classNames({
      //   "d-flex gap-8": inline,
      // })}
      >
        {children}
      </div>
      <ErrorMessage component="div" className="error" name={name} />
    </div>
  );
};
