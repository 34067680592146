const hasDecimalPlaces = (number) => {
  return number.toString().indexOf('.') >= 0;
};

export const calculateTotal = (values) => {
  let benefitPreservedAmount =
    values.superannuationRollover.benefitPreservedAmount === '0'
      ? 0
      : Number.isNaN(
          parseFloat(values.superannuationRollover.benefitPreservedAmount),
        )
      ? 0
      : parseFloat(values.superannuationRollover.benefitPreservedAmount);
  let benefitUnrestrictedAmount =
    values.superannuationRollover.benefitUnrestrictedAmount === '0'
      ? 0
      : Number.isNaN(
          parseFloat(values.superannuationRollover.benefitUnrestrictedAmount),
        )
      ? 0
      : parseFloat(values.superannuationRollover.benefitUnrestrictedAmount);
  let benefitRestrictedAmount =
    values.superannuationRollover.benefitRestrictedAmount === '0'
      ? 0
      : Number.isNaN(
          parseFloat(values.superannuationRollover.benefitRestrictedAmount),
        )
      ? 0
      : parseFloat(values.superannuationRollover.benefitRestrictedAmount);
  const sumOfBenefits =
    benefitPreservedAmount +
    benefitUnrestrictedAmount +
    benefitRestrictedAmount;
  return hasDecimalPlaces(sumOfBenefits)
    ? sumOfBenefits.toFixed(2)
    : sumOfBenefits;
};
