import { formatAddress } from '../../utils/format';
import { PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES } from '../../utils/configs';
import Alert from '../Alert/Alert';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Plus } from 'bootstrap-icons/icons/plus.svg';
import React from 'react';

const MemberDetails = ({ guid, member, members }) => {
  const { state } = useLocation();

  return (
    <>
      {!!member && (
        <Alert variation="details" title="Initiating request details">
          <dl>
            {!!member?.givenName && (
              <div>
                <dt>Given name</dt>
                <dd> {member?.givenName}</dd>
              </div>
            )}
            {!!member?.familyName && (
              <div>
                <dt>Last name</dt>
                <dd> {member?.familyName}</dd>
              </div>
            )}
            {!!member?.otherGivenName && (
              <div>
                <dt>Other given name</dt>
                <dd> {member?.otherGivenName}</dd>
              </div>
            )}
            {!!member?.dateOfBirth && (
              <div>
                <dt>Date of birth</dt>
                <dd> {member?.dateOfBirth}</dd>
              </div>
            )}
            {!!member?.address && (
              <div className="fund-name">
                <dt>Address</dt> <dd>{formatAddress(member?.address)}</dd>
              </div>
            )}
            {!!member?.gender && (
              <div>
                <dt>Gender</dt>
                <dd>
                  {
                    PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES.find(
                      (code) => code.value === member?.gender,
                    ).label
                  }
                </dd>
              </div>
            )}
            {!!member?.taxFileNumber && (
              <div>
                <dt>Tax file number</dt>
                <dd> {member?.taxFileNumber}</dd>
              </div>
            )}
          </dl>
        </Alert>
      )}
      {member == null && members?.length === 0 && (
        <section className="error-section">
          <p>You currently have no members registered.</p>
          <Link
            to={{
              pathname: `/smsfs/${guid}/members/new`,
              state: { ...state },
            }}
            className="button button--primary button--icon-before"
          >
            <Plus className="button--icon-before__icon" /> New Member
          </Link>
        </section>
      )}
    </>
  );
};

export default MemberDetails;
