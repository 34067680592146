import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import validateTFN from 'utils/validateTFN';
import FormGroup from 'components/FormGroup/FormGroup';
import {
  AUSTRALIAN_STATES_AND_TERRITORIES,
  COUNTRY_CODES,
  getMemberTypeList,
  MEMBER_OR_TRUSTEE_TYPES,
  PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES,
} from 'utils/configs';
import DayInput from 'components/DayInput/DayInput';
import AddressFormGroup from 'components/AddressFormGroup/AddressFormGroup';
import {
  convertToAuNumericDateFormat,
  convertToIsoDateFormat,
  formatAddress,
} from 'utils/format';
import { useEffect, useState } from 'react';
import InputValidation from 'components/InputValidation/InputValidation';
import Spinner from 'components/Spinner/Spinner';
import ValidationError from 'components/ValidationError/ValidationError';
import { getMemberLabel } from 'utils/labels';
import { mapError } from 'utils/mapServiceCallErrors';
import Alert from 'components/Alert/Alert';
import {
  POSTCODE_REGEX,
  VALID_CHARACTERS_REGEX,
  VALID_MOBILE_NUMBER_LENGTH,
  VALID_MOBILE_NUMBER_REGEX,
} from 'constants/validation';
import { MEMBER_AND_CONTACT, MEMBER } from 'constants/participant';
import { SMSFService } from 'services';
import { useFundContext } from 'contexts/fundContext';
import { useParticipationContext } from 'contexts/participantContext';

import DisabledFields from 'components/DisabledFields/DisabledFields';
import { SMSF_STATUS } from "../../constants/smsf";

const SexCodes = PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES.map((code) => code.value);
const Roles = MEMBER_OR_TRUSTEE_TYPES.map((type) => type.value);
const States = AUSTRALIAN_STATES_AND_TERRITORIES.map((state) => state.value);

const MemberOrTrusteeDataSchema = Yup.object().shape({
  type: Yup.string().oneOf(Roles).required('Role is required'),
  familyName: Yup.string()
    .max(40, 'Maximum length allowed for last name is 40 characters')
    .matches(
      VALID_CHARACTERS_REGEX,
      'Last name must only contain([0-9a-zA-Z .,?(){}:;\'|-_=\\/@#$%*=&"])',
    )
    .required('Last name is required'),
  firstName: Yup.string()
    .max(40, 'Maximum length allowed for given name is 40 characters')
    .matches(
      VALID_CHARACTERS_REGEX,
      'Given name must only contain([0-9a-zA-Z .,?(){}:;\'|-_=\\/@#$%*=&"])',
    )
    .required('Given name is required'),
  otherNames: Yup.string()
    .max(40, 'Maximum length allowed for other given name is 40 characters')
    .matches(
      VALID_CHARACTERS_REGEX,
      'Other given name must only contain([0-9a-zA-Z .,?(){}:;\'|-_=\\/@#$%*=&"])',
    ),
  email: Yup.string().email('Email is invalid').required('Email is required'),
  mobile: Yup.string()
    .min(VALID_MOBILE_NUMBER_LENGTH, 'Mobile number length must be 10 numbers')
    .max(VALID_MOBILE_NUMBER_LENGTH, 'Mobile number length must be 10 numbers')
    .matches(VALID_MOBILE_NUMBER_REGEX, 'Mobile number is invalid')
    .required('Mobile number is required'),
  dateOfBirth: Yup.string().when('type', {
    is: (value) => value === MEMBER || value === MEMBER_AND_CONTACT,
    then: Yup.string()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date')
      .nullable()
      .required('Date of birth is required'),
    otherwise: Yup.string(),
  }),
  gender: Yup.string().when('type', {
    is: (value) => value === MEMBER || value === MEMBER_AND_CONTACT,
    then: Yup.string().oneOf(SexCodes).required('Gender is required'),
    otherwise: Yup.string(),
  }),
  taxFileNumber: Yup.string().when('type', {
    is: (value) => value === MEMBER || value === MEMBER_AND_CONTACT,
    then: Yup.string().test('is-valid-tfn', (value, { createError }) => {
      let message;
      if (!value || value.length === 0) {
        message = 'Tax file number is required';
      } else if (!/^\d+$/.test(value)) {
        message = 'Tax file number must only contain numbers';
      } else if (value.length !== 8 && value.length !== 9) {
        message = 'Tax file number must be 8 or 9 digits';
      } else if (!validateTFN(value)) {
        message = 'Invalid tax file number';
      }

      return message
        ? createError({
          message,
        })
        : true;
    }),
    otherwise: Yup.string(),
  }),
  address: Yup.mixed().when('type', {
    is: (value) => value === MEMBER || value === MEMBER_AND_CONTACT,
    then: Yup.object().shape({
      line1: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Address is required'),
      suburb: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Suburb is required'),
      state: Yup.string()
        .oneOf(States, 'Please choose an option')
        .required('State / Territory is required'),
      postCode: Yup.string()
        .matches(
          POSTCODE_REGEX,
          'Please enter a valid 4-digit Australian post code',
        )
        .required('This field is required'),
      countryCode: Yup.string()
        .oneOf(COUNTRY_CODES.map((code) => code.value))
        .required('Country is required'),
    }),
    otherwise: Yup.mixed(),
  }),
});

const MemberDetails = () => {
  const history = useHistory();
  const fund = useFundContext();
  const { guid, memberGuid } = useParams();
  const { participant } = useParticipationContext();
  const { state } = useLocation();
  const [member, setMember] = useState(null);
  const [emailInfo] = useState(null);
  const [taxFileNumberInfo, setTaxFileNumberInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const smsfStatus = participant?.smsfStatuses[guid];

  const isActive = () => {
    return smsfStatus === SMSF_STATUS.active;
  };

  const validateTaxFileNumber = async (tfn) => {
    try {
      member == null
        ? await SMSFService.validateSMSFMemberTFN(guid, tfn)
        : await SMSFService.validateRegisteredSMSFMemberTFN(
          guid,
          memberGuid,
          tfn,
        );
      setTaxFileNumberInfo({
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      setTaxFileNumberInfo({
        type: 'alert',
        content:
          error.response?.data?.validations[0]?.message ||
          'Invalid Tax File Number',
      });
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = {
        ...values,
        taxFileNumber: values.type !== 'CONTACT' ? values.taxFileNumber : null,
        dateOfBirth:
          values.type !== 'CONTACT'
            ? convertToIsoDateFormat(values.dateOfBirth)
            : null,
        address:
          values.type !== 'CONTACT'
            ? {
              ...values.address,
              addressType: values.address.addressType,
            }
            : null,
        phoneNumbers: [
          {
            type: 'MOBILE',
            number: values.mobile,
          },
        ],
      };
      member == null
        ? await SMSFService.addSMSFMember(guid, data)
        : await SMSFService.updateSMSFMember(guid, memberGuid, data);
      history.goBack();
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      setSubmissionError(mapError(error));
    }
  };

  useEffect(() => {
    const getMember = async () => {
      try {
        const { data } = await SMSFService.getSMSFMember(guid, memberGuid);
        setMember(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!!memberGuid && member == null) {
      getMember();
    } else {
      setIsLoading(false);
    }
  }, [memberGuid, member, isLoading, guid]);

  useEffect(() => {
    if (!!member) {
      document.title = `${member.smsfMember.firstName} ${member.smsfMember.familyName}`;
    } else {
      document.title = `Add New Member or ${getMemberLabel(
        fund?.trusteeStructure,
      )}`;
    }
  }, [member, fund?.trusteeStructure]);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="NewMember">
      <div className="banner">
        <Breadcrumbs />
        {member == null ? (
          <h1>
            Add new member or{' '}
            {getMemberLabel(fund?.trusteeStructure).toLowerCase()}
          </h1>
        ) : (
          <h1>
            {member.smsfMember.firstName} {member.smsfMember.familyName}
          </h1>
        )}
      </div>
      {!!state?.member &&
        (state?.action === 'INITIATE_ROLLOVER_REQUEST' ||
          state?.action === 'ELECTRONIC_PORTABILITY_FORM') && (
          <div className="banner">
            <Alert variation="info" title="Verify member details">
              <p>
                The member details below have been pre-filled using the request
                received. If there are any issues with the details, you must
                contact the transferring fund or member directly.
              </p>
            </Alert>
          </div>
        )}
      <div className="content">
        <div className="form">
          <Formik
            initialValues={{
              type: member?.smsfMember?.type || (state?.member ? MEMBER : ''),
              familyName:
                member?.smsfMember?.familyName ||
                state?.member?.familyName ||
                '',
              firstName:
                member?.smsfMember?.firstName || state?.member?.givenName || '',
              otherNames:
                member?.smsfMember?.otherNames ||
                state?.member?.otherGivenName ||
                '',
              email: member?.smsfMember?.email || '',
              mobile:
                member?.smsfMember?.phoneNumbers?.find(
                  (number) => number.type === 'MOBILE',
                ).number || '',
              dateOfBirth:
                convertToAuNumericDateFormat(member?.smsfMember?.dateOfBirth) ||
                '',
              gender: member?.smsfMember?.gender || state?.member?.gender || '',
              taxFileNumber: member?.smsfMember?.taxFileNumber || '',
              address: member?.smsfMember?.address ||
                state?.member?.address || {
                line1: '',
                suburb: '',
                state: '',
                postCode: '',
                countryCode: '',
                addressType: 'RESIDENTIAL',
              },
            }}
            validationSchema={MemberOrTrusteeDataSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting, errors, handleBlur }) => (
              <>
                <Form id="new-member-form" className="form">
                  <DisabledFields disabled={!isActive()}>
                    <div
                      className="panel"
                      role="group"
                      aria-labelledby="member-role"
                    >
                      <div id="member-role" className="h2 mb-6">
                        Role
                      </div>
                      <FormGroup name="type" label="Role" as="select">
                        <option>Please select...</option>
                        {getMemberTypeList(fund?.trusteeStructure).map(
                          ({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ),
                        )}
                      </FormGroup>
                    </div>
                    <div
                      className="panel"
                      role="group"
                      aria-labelledby="member-details"
                    >
                      <div id="member-details" className="h2 mb-6">
                        Member details
                      </div>

                      <FormGroup name="firstName" label="Given name" />
                      <FormGroup
                        name="otherNames"
                        label="Other given name"
                        optional
                      />
                      <FormGroup name="familyName" label="Last name" />
                      <FormGroup name="email" type="email" label="Email" />
                      <FormGroup name="mobile" type="tel" label="Mobile" />
                      {(values.type === MEMBER ||
                        values.type === MEMBER_AND_CONTACT) && (
                          <>
                            <FormGroup
                              id="dateOfBirth"
                              name="dateOfBirth"
                              label="Date of birth"
                              placeholder="dd/mm/yyyy"
                              as={DayInput}
                              hasMaxYear={true}
                            />
                            <FormGroup name="gender" label="Gender" as="select">
                              <option>Please select...</option>
                              {PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES.map(
                                ({ value, label }) => (
                                  <option key={value} value={value}>
                                    {label}
                                  </option>
                                ),
                              )}
                            </FormGroup>
                            <FormGroup
                              name="taxFileNumber"
                              label="Tax file number"
                              onChange={(event) => {
                                setFieldValue(
                                  'taxFileNumber',
                                  event.target.value.replace(/(\s|-)/g, ''),
                                );
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                errors.taxFileNumber != null
                                  ? setTaxFileNumberInfo(null)
                                  : validateTaxFileNumber(values.taxFileNumber);
                              }}
                              appendix={
                                taxFileNumberInfo != null &&
                                  taxFileNumberInfo.type !== 'success' ? (
                                  <InputValidation type={taxFileNumberInfo.type}>
                                    {taxFileNumberInfo.content}
                                  </InputValidation>
                                ) : null
                              }
                            />
                            <AddressFormGroup
                              initialValue={formatAddress(values.address)}
                              name="address"
                              label="Address"
                              addressType={{
                                enable: true,
                                initialValue: 'RESIDENTIAL',
                              }}
                              isSubmit={isSubmit}
                            />
                          </>
                        )}
                    </div>
                    {submissionError && (
                      <ValidationError
                        validation={submissionError.validationResponse}
                        exception={submissionError.exception}
                      />
                    )}
                    <div className="button-group mt-6">
                      {!!member && (
                        <button
                          type="submit"
                          className="button button--primary"
                          disabled={
                            isSubmitting ||
                            emailInfo?.type === 'alert' ||
                            taxFileNumberInfo?.type === 'alert'
                          }
                        >
                          Save
                        </button>
                      )}
                      {member == null && (
                        <button
                          type="submit"
                          className="button button--primary"
                          disabled={
                            isSubmitting ||
                            emailInfo?.type === 'alert' ||
                            taxFileNumberInfo?.type === 'alert'
                          }
                          onClick={() => setIsSubmit(true)}
                        >
                          Add member
                        </button>
                      )}
                      <button
                        type="button"
                        className="button button--transparent"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </button>
                    </div>
                  </DisabledFields>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MemberDetails;
