import Alert from '../Alert/Alert';
import { useState } from 'react';
import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';

const ValidationError = ({ validation, exception }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [detailDescription, setDetailDescription] = useState(null);

  return (
    <div className="mt-4">
      <Alert variation="alert">
        {!!validation?.validations && (
          <div>
            <ul>
              {validation?.validations?.map((validation, index) => (
                <li key={index}>
                  <strong>{validation.errorCode}</strong>: {validation.message}{' '}
                  {validation.details != null && (
                    <>
                      <span
                        className="button button--link"
                        onClick={() => {
                          setIsOpen(true);
                          setDetailDescription(validation.details);
                        }}
                      >
                        More info
                      </span>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <Modal
              isOpen={isOpen}
              onRequestClose={() => {
                setIsOpen(false);
                setDetailDescription(null);
              }}
              className="modal"
              overlayClassName="modal-overlay"
              contentLabel="Error details"
              aria={{
                labelledby: 'error-details-modal',
              }}
              appElement={document.getElementById('root')}
            >
              <div className="modal__header">
                <div className="modal__heading h3">Error Details</div>
                <button
                  type="button"
                  className="d-inline-flex button--transparent button--close"
                  aria-label="Close"
                  onClick={() => {
                    setIsOpen(false);
                    setDetailDescription(null);
                  }}
                >
                  <XLg />
                </button>
              </div>
              <div className="modal__body">
                <p dangerouslySetInnerHTML={{ __html: detailDescription }} />
              </div>

              <div className="modal__footer button-group">
                <button
                  type="button"
                  className="button button--primary"
                  onClick={() => {
                    setIsOpen(false);
                    setDetailDescription(null);
                  }}
                >
                  Close
                </button>
              </div>
            </Modal>
          </div>
        )}
        {!!exception && (
          <span>
            {!!exception.errorCode ? (
              <strong>{exception.errorCode}</strong>
            ) : (
              <strong>{exception.status}</strong>
            )}
            : {exception.message}{' '}
          </span>
        )}
      </Alert>
    </div>
  );
};
export default ValidationError;
