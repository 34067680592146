import { useEffect, useState } from 'react';
import { ReactComponent as Plus } from 'bootstrap-icons/icons/plus.svg';
import { DEFAULT_PAGE_SIZE } from 'utils/configs';
import HttpClient from 'utils/HttpClient';
import { Link } from 'react-router-dom';
import PaginationBar from 'components/PaginationBar/PaginationBar';
import Spinner from 'components/Spinner/Spinner';
import SMSFDataRow from './SMSFDataRow';
import Searchbar from 'components/Searchbar/Searchbar';
import { showAlertOutSideSMSF } from 'utils/accountSetupUtils';
import AccountCompleteInfo from 'components/AccountCompleteInfo/AccountCompleteInfo';
import { useParticipationContext } from 'contexts/participantContext';

const InitialData = {
  totalPages: 0,
  totalElements: 0,
  number: 0,
};

const InitialError = {
  status: '',
  message: '',
};

const SMSFs = () => {
  document.title = 'SMSFs';
  const { participant } = useParticipationContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState(InitialData);
  const [error, setError] = useState(InitialError);
  const [query, setQuery] = useState('');
  const [paginationData, setPaginationData] = useState({
    totalItems: 0,
    pageNumber: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const setPaginationParams = (pageNumber, pageSize) => {
    setPaginationData({
      ...paginationData,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    setIsLoaded(false);
  };

  useEffect(() => {
    const getResults = async () => {
      setError(InitialError);

      try {
        const res = await HttpClient.post(`/smsf-hub/smsf/search`, {
          pageNumber: paginationData.pageNumber,
          pageSize: paginationData.pageSize,
          query: query,
        });

        setIsLoaded(true);
        setData(res?.data?.rows);
        setPaginationData(res?.data?.paginationData);
        setError(InitialError);
        setIsProcessing(false);
      } catch (err) {
        setIsLoaded(true);
        setIsProcessing(false);
        if (err.response) {
          setData(InitialData);
          setError({
            status: err.response.status.toString(),
            message: err.response.data.message || err.response.data,
          });
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
        }
      }
    };

    if (!isLoaded) {
      setIsProcessing(true);
      getResults();
    }
  }, [query, paginationData, isLoaded]);

  const isSuccessfullyLoaded = () => isLoaded && error.status.length === 0;

  return (
    <main className="main">
      <div className="SMSFs main__no-sidebar">
        {showAlertOutSideSMSF(participant) && (
          <AccountCompleteInfo
            status={
              participant?.smsfStatuses[participant?.guidOfPendingActionSmsf]
            }
            smsfGuid={participant?.guidOfPendingActionSmsf}
          />
        )}
        <div className="banner">
          {/* <Breadcrumbs /> */}
          <div className="d-flex align-items-center justify-content-between">
            <h1>SMSFs</h1>
            {isSuccessfullyLoaded() &&
              data?.length > 0 &&
              participant.participantType === 'INTERMEDIARY' && (
                <Link
                  to="/smsfs/new"
                  className="button button--outline button--icon-before"
                >
                  <Plus className="button--icon-before__icon" /> New SMSF
                </Link>
              )}
          </div>
        </div>

        {isProcessing ? (
          <Spinner />
        ) : (
          <div className="content">
            {isLoaded && error.status.length > 0 && (
              <div className="error-section">
                <h2>{error.status}</h2>
                <p>{error.message}</p>
              </div>
            )}
            {isSuccessfullyLoaded() && data?.length === 0 && query === '' && (
              <section className="error-section">
                <h2>No SMSFs</h2>
                <p>You currently have no SMSFs registered</p>
                <Link
                  to="/smsfs/new"
                  className="button button--primary button--icon-before"
                >
                  <Plus className="button--icon-before__icon" /> New SMSF
                </Link>
              </section>
            )}
            <section>
              {isSuccessfullyLoaded() && (
                <>
                  <div className="table-wrapper">
                    {(data?.length === 0 && query !== '') ||
                      (data?.length > 0 && (
                        <div>
                          <Searchbar
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                console.log(
                                  'event.target.value=',
                                  event.target.value,
                                );
                                setQuery(event.target.value);
                                setIsLoaded(false);
                              }
                            }}
                          />
                        </div>
                      ))}
                    {data?.length === 0 && query !== '' && (
                      <div className="error-section">
                        <h2>No matchings results found</h2>
                        <button
                          type="button"
                          className="button button--primary button--icon-before"
                          onClick={() => {
                            setQuery('');
                            setIsLoaded(false);
                          }}
                        >
                          Reset search
                        </button>
                      </div>
                    )}
                    {data?.length > 0 && (
                      <div>
                        <div>
                          <table className="table table-stp">
                            <thead>
                              <tr>
                                <th>SMSF name</th>
                                <th>SMSF ABN</th>
                                <th>Trustee structure</th>
                                <th>Last updated</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((row) => {
                                return <SMSFDataRow key={row.guid} row={row} />;
                              })}
                            </tbody>
                          </table>
                          <PaginationBar
                            data={paginationData}
                            setPaginationParams={setPaginationParams}
                            resultType={'SMSFs'}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </section>
          </div>
        )}
      </div>
    </main>
  );
};

export default SMSFs;
