import { isReleaseAuthorityStatementOutAllowed } from 'utils/accountSetupUtils';
import { useParticipationContext } from 'contexts/participantContext';
import { RolloverMemberDetailsList } from 'services/RolloverSearchService/types/RolloverSearchService.types';

interface SendRASButtonProps {
  guid: string;
  sendingStatus: string;
  actionedMemberContextId: string;
  member: RolloverMemberDetailsList;
  isActive: boolean;
  withActionLabel: boolean;
  sendRASResponse: (member: RolloverMemberDetailsList) => void;
}

export const SendRASButton = ({
  guid,
  sendingStatus,
  actionedMemberContextId,
  member,
  isActive,
  withActionLabel = false,
  sendRASResponse,
}: SendRASButtonProps) => {
  const { participant } = useParticipationContext();

  return (
    <button
      className={`button button--xs ${
        withActionLabel
          ? 'button--outline pill--request grey'
          : 'button--primary'
      }`}
      disabled={
        !isReleaseAuthorityStatementOutAllowed(participant, guid) ||
        (sendingStatus === 'SENDING' &&
          actionedMemberContextId !== member?.memberContextId) ||
        !isActive
      }
      onClick={() => {
        sendRASResponse(member);
      }}
    >
      Send RAS
    </button>
  );
};
