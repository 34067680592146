import { ReactComponent as ExclamationCircle } from 'bootstrap-icons/icons/exclamation-circle.svg';
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle.svg';
import { ReactComponent as CheckCircleFill } from 'bootstrap-icons/icons/check-circle-fill.svg';
import { ReactComponent as CardChecklist } from 'bootstrap-icons/icons/card-checklist.svg';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface AlertProps {
  variation: '' | 'success' | 'info' | 'alert' | 'details' | 'notice';
  title: string;
  children: ReactNode;
}

const Alert = ({ variation = '', title, children }: AlertProps) => {
  return (
    <div
      className={classNames('alert', {
        'alert--success': variation === 'success',
        'alert--info': variation === 'info',
        'alert--alert': variation === 'alert',
        'alert--details': variation === 'details',
        'alert--notice': variation === 'notice',
      })}
    >
      <div className="alert__container">
        <div className="alert__icon">
          {variation === 'success' && (
            <CheckCircleFill className="is-success" />
          )}
          {variation === 'info' && <InfoCircle />}
          {variation === 'alert' && <ExclamationCircle className="is-alert" />}
          {variation === 'details' && <CardChecklist />}
          {variation === 'notice' && <InfoCircle />}
        </div>
        <div className="alert__content">
          <p className="alert__title">{title}</p>
          <div className="alert__message">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
