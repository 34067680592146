import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import React from 'react';

const RolloverErrorReasonGuideModal = ({
  showModal,
  handleCloseModal,
  errorResponseReferenceData,
}) => {
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      className="modal modal--lg"
      overlayClassName="modal-overlay"
      contentLabel="Reason Guide"
      aria={{
        labelledby: 'rollover-error-reason-guide-modal',
      }}
    >
      <div className="modal__header">
        <div
          className="modal__heading h3"
          id="rollover-error-reason-guide-modal"
        >
          Reason Guide
        </div>
        <button
          type="button"
          className="d-inline-flex button--transparent button--close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <XLg />
        </button>
      </div>
      <div className="modal__body">
        <ul className="definition-list">
          {errorResponseReferenceData?.errorCodes?.map((errorCodeDetails) => {
            return (
              <li>
                <h4>{errorCodeDetails.shortDescription}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: errorCodeDetails.helpInfo,
                  }}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="modal__footer">
        <button
          type="button"
          className="button button--primary"
          onClick={handleCloseModal}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default RolloverErrorReasonGuideModal;
