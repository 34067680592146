export const SMSF_STATUS = {
  active: 'ACTIVE',
  trial: 'TRIAL',
  initialPaymentPending: 'INITIAL_PAYMENT_PENDING',
  initialPaymentFailed: 'INITIAL_PAYMENT_FAILED',
  renewalPaymentProcessingInProgress: 'RENEWAL_PAYMENT_PROCESSING_IN_PROGRESS',
  renewalPaymentFailed: 'RENEWAL_PAYMENT_FAILED',
  renewalCancelled: 'RENEWAL_CANCELLED',
  initialPaymentProcessinginProgress:
    'INITIAL_PAYMENT_PROCESSING_IN_PROGRESS',
  atoIntPending: 'ATO_INT_PENDING',
  cancelled: 'CANCELLED',
};